import React, { useEffect, useRef, useState } from 'react';
import SearchField from '@components/SearchField';
import Filter from '@components/Filter';
import AccordionItem from '@components/Accordion';
import Fooditem from '@components/FoodItem';
import DataNotFound from '@pages/DataNotFound';
import BottonTabs from '@components/BottomTabs';
import LandingHeader from '@/components/LandingHeader';
import FoodCategoryMenu from '@/components/FoodCategoryMenu';
import { useGetClientProducts } from '@/hooks/useGetClientProducts';
import { useLocation } from 'react-router';
import { getQueryParam } from '@/utils/routerUtils';
import _ from 'lodash';
import Loader from '@/atomicComponents/Loader';
import useGetKitchenTiming from '@/hooks/useGetKitchenTiming';
import {
  defaultClientId,
  defaultSource,
  defaultCloseTime,
  defaultOpenTime,
} from '@/utils/constants';
import ReadOnlyModal from '@/components/ReadOnlyModal';
import BottomFooter from '@/components/BottomFooter/BottomFooter';

enum FilterValue {
  none,
  veg,
  nonVeg,
  recommended,
}

export const RestaurantLandingPage = () => {
  const location = useLocation();
  const localClientId =
    window.localStorage.getItem('clientId') || defaultClientId;
  const localSource = window.localStorage.getItem('source') || defaultSource;
  const clientId =
    getQueryParam(location.search, 'clientid') ||
    getQueryParam(location.search, 'clientId') ||
    localClientId;
  const source = getQueryParam(location.search, 'source') || localSource;
  const [onlyReadMenu, setOnlyReadMenu] = useState(false);
  const { data = [], isLoading } = useGetClientProducts(clientId);

  const { kitchenSetup, openTime } = useGetKitchenTiming({
    open_Time: data.client?.open_time || defaultOpenTime,
    close_Time: data.client?.close_time || defaultCloseTime,
  });

  const [filteredData, setFilteredData] = useState(data.category_map);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState<FilterValue | string>(FilterValue.none);
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    window.localStorage.setItem('clientId', clientId);
    window.localStorage.setItem('source', source);
  }, [clientId, source]);

  const [{ hasNonveg, hasReccommendedItems }, setHasOptions] = useState({
    hasNonveg: false,
    hasReccommendedItems: false,
  })

useEffect(() => {
  if (data && data.client?.readonlymenu) {
    setOnlyReadMenu(data.client?.readonlymenu);
  }
  if(data && data.category_map){
    setHasOptions({
      hasNonveg: data.category_map.some((category: any) =>
        category.products.some((product: any) => product.non_veg)
      ),
      hasReccommendedItems: data.category_map.some((category: any) =>
        category.products.some((product: any) => product.recommended)
      ),
    });
  }
}, [data]);

  useEffect(() => {
    const testFilter = (product: any): boolean => {
      if (filter === FilterValue.veg) {
        return !product.non_veg;
      }
      if (filter === FilterValue.nonVeg) {
        return product.non_veg;
      }
      if (filter === FilterValue.recommended) {
        return product.recommended;
      }
      return true;
    };

    const updatedFilteredData =
      data?.category_map
        ?.map((category: any) => ({
          ...category,
          products: category.products.filter(
            (item: any) =>
              new RegExp(`${searchQuery}`, 'i').test(item.product_name) &&
              testFilter(item),
          ),
        }))
        .filter((category: any) => category.products.length > 0) || [];

    setFilteredData(updatedFilteredData);
  }, [searchQuery, data, filter, setFilteredData]);

  const toggleFilter = (value: FilterValue) => {
    if (filter !== FilterValue.none) setFilter(FilterValue.none);
    else setFilter(value);
  };

  const handleCategoryClick = (index: number) => {
    const element = itemsRef.current[index];
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  if (isLoading)
    return (
      <div className="flex flex-1 items-center justify-center h-screen">
        <Loader />
      </div>
    );

  return (
    <div className="flex flex-col max-h-screen">
      {
        <ReadOnlyModal
          isVisible={onlyReadMenu}
        />
      }
      <FoodCategoryMenu
        data={data.category_map}
        onClick={handleCategoryClick}
      />
      <div className="flex flex-col gap-3 p-2">
        <LandingHeader
          clientName={data.client?.client_title ?? data.client?.client_name}
          onlyReadMenu={onlyReadMenu}
        />
        <div>
          <SearchField
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        <div className="flex flex-row gap-2 justify-start">
          <Filter
            onSelect={() => toggleFilter(FilterValue.veg)}
            title="Veg"
            selected={filter === FilterValue.veg}
            selectedColor="bg-green-600"
          />
          {hasNonveg && (
            <Filter
              onSelect={() => toggleFilter(FilterValue.nonVeg)}
              title="Non Veg"
              selected={filter === FilterValue.nonVeg}
              selectedColor="bg-orange-700"
            />
          )}
          {hasReccommendedItems && (
            <Filter
              onSelect={() => toggleFilter(FilterValue.recommended)}
              title="Recommended"
              selected={filter === FilterValue.recommended}
              selectedColor="bg-blue-700"
            />
          )}
        </div>
        {kitchenSetup && (
          <p className=" text-[11px] font-medium text-red-dark">
            Kitchen Closed : Our kitchen opens at {openTime} everyday
          </p>
        )}
      </div>
      <div className="flex flex-col overflow-auto max-h-full mb-12">
        {filteredData && filteredData[0] ? (
          filteredData.map?.((category: any, index: number) => (
            <AccordionItem
              key={category.id}
              defaultState={true}
              title={
                <h2
                  ref={elem => (itemsRef.current[index] = elem)}
                  className="text-sm text-green font-semibold">
                  {category.category_name}
                </h2>
              }
              color="green">
              {category.products.map((item: any) => (
                <Fooditem
                  key={item.id}
                  data={item}
                  kitchenSetup={kitchenSetup}
                  onlyReadMenu={onlyReadMenu}
                />
              ))}
            </AccordionItem>
          ))
        ) : (
          <DataNotFound search={searchQuery} />
        )}
        <BottomFooter/>
      </div>
      <BottonTabs
        onlyReadMenu={onlyReadMenu}
        kitchenSetup={kitchenSetup}
        contactNumber={data?.client?.owner_login_number ?? 0}
      />
    </div>
  );
};
