import { useState } from 'react';

const readOnly = require('@assets/images/ReadOnly.jpeg');

interface ReadOnlyModalProps {
  isVisible: boolean;
}
export const ReadOnlyModal = ({ isVisible }: ReadOnlyModalProps) => {
  const [closeModa, setCloseModal] = useState(false);
  if (!isVisible || closeModa) return null;

  return (
    <>
      <div className="fixed top-0 left-0 h-screen w-screen bg-grey bg-opacity-50 z-30 flex justify-center items-center place-items-center">
        <div className="flex flex-col gap-2 justify-center items-center bg-white  p-4 mx-2 rounded-lg relative z-20">
          <h1 className="text-green font-bold">
            Discover Our Delicious Selections!
          </h1>
          <div className="flex flex-col text-xs text-grey-dark justify-center items-center">
            <p>This is a read-only version of our menu.</p>
            <p>A fully functional menu will be available soon.</p>
            <p>
              <b>Stay tuned!</b>
            </p>
          </div>
          <img className="w-80 " src={readOnly} alt="" />
          <button
            onClick={() => {
              setCloseModal(true);
            }}
            className="bg-green px-4 py-2 rounded-full text-white shadow-lg">
            Ok, Got it!
          </button>
        </div>
      </div>
    </>
  );
};
