
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { usePhoneAuth } from '../../service/firebase/phoneAuth';
import alpine from '../../service/alpine';
import OTPInput from '@/atomicComponents/OTPInput';
import LabelledTextField from '@/atomicComponents/LabelledTextField';
import { Cross } from '@/assets/icons/Cross';
import { LeftArrow } from '@/assets/icons/LeftArrow';
import { useDispatch } from 'react-redux';
import { signInUser } from '@/service/Slice/userSlice';
import { AlertType, showAlert } from '@/service/Slice/alertSlice';

interface ILoginModal {
  closeModal?: (action: string) => void;
  phone?: string;
}

let resendTimer = 60;

export const LoginModal = ({ closeModal = () => {}, phone }: ILoginModal) => {
  const storedLoginCredentials = localStorage.getItem('loginCredentials');

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [isIncorrectOTP, setIsIncorrectOTP] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [errorType, setErrorType] = useState<string>('');
  const { sendOTP, confirmOTP } = usePhoneAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (storedLoginCredentials) {
      let loginCredentials;
      loginCredentials = JSON.parse(storedLoginCredentials);
      if (JSON.parse(storedLoginCredentials).PhoneNumber) {
        setPhoneNumber(loginCredentials.PhoneNumber);
        setShowOtp(true);
      }
    }
  }, [storedLoginCredentials]);

  useEffect(() => {
    if (phone?.length && phone?.length >= 10 && !phoneNumber) {
      setPhoneNumberError(false);
      setPhoneNumber(phone);
      handleLogin(phone);
    }
  }, [phone]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        resendTimer--;
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const handleLogin = (phoneNumber: string) => {
    setIsLoadingOtp(true);
    resendTimer = 60;
    sendOTP('+91' + phoneNumber)
      .then(() => {
        setShowOtp(true);
      })
      .catch(error => {
        dispatch(
          showAlert({
            message: error.message || 'Failed to send OTP.',
            type: AlertType.error,
          }),
        );
      })
      .finally(() => {
        window.localStorage.setItem(
          'loginCredentials',
          JSON.stringify({ PhoneNumber: phoneNumber }),
        );
        setIsLoadingOtp(false);
      });
  };

  const handleConfirmOtp = async (e: any) => {
    // e.preventDefault();
    setIsLoadingLogin(true);
    try {
      const response = await confirmOTP(otp);
      const token = await response!.user.getIdToken();
      await alpine.userLogin(phoneNumber, token);
      window.localStorage.setItem('firebaseToken', token);
      dispatch(signInUser({ phone: phoneNumber, firebaseToken: token }));
      dispatch(
        showAlert({
          message: 'Congratulations! You have successfully logged In',
          type: AlertType.success,
        }),
      );
      closeModal('otp');
    } catch (error: any) {
      if (error.code === 'auth/invalid-verification-code') {
        setIsIncorrectOTP(true);
      } else {
        dispatch(
          showAlert({
            message: error.message,
            type: AlertType.error,
          }),
        );
      }
      return;
    } finally {
      setIsLoadingLogin(false);
    }
  };

  const phoneInput = (
    <div className="flex-1 flex relative flex-col gap-6 place-items-center h-full w-full z-10 justify-center items-start px-7">
      <div className="self-center">
        <img src="/logo192.png" style={{ width: '64px', height: '64px' }} />
      </div>

      <h4 className="text-green font-bold">Login</h4>
      <LabelledTextField
        label="Phone Number"
        labelClassName="montserrat-label"
        placeholder="Enter your phone number"
        value={phoneNumber}
        onChange={e => {
          setPhoneNumber(e.target.value);
          setPhoneNumberError(false);
        }}
        error={
          phoneNumberError === true
            ? errorType === 'empty'
              ? 'Phone number cannot be empty'
              : errorType === 'length'
                ? 'Phone number should be of 10 digits'
                : errorType === 'Invalid'
                  ? 'Enter a valid phone number'
                  : undefined
            : undefined
        }
        errorClassName="text-red-500"
        inputClassName={phoneNumberError ? 'border-red-500' : ''}
        disabled={isLoadingOtp}
      />
      <button
        disabled={isLoadingOtp}
        onClick={() => {
          let errorType = '';
          if (phoneNumber === '') {
            errorType = 'empty';
          } else if (phoneNumber.length !== 10) {
            errorType = 'length';
          } else if (!/^[0-9]*$/.test(phoneNumber)) {
            errorType = 'invalid';
          }
          if (errorType) {
            setPhoneNumberError(true);
            setErrorType(errorType);
            return;
          }
          handleLogin(phoneNumber);
        }}
        className="uppercase bg-green w-full rounded-full py-3 text-white text-xs font-black font-[NotoSans]">
        {isLoadingOtp ? (
          <svg
            className="animate-spin h-4 w-5  mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.007 8.007 0 014 12H0c0 6.627 5.373 12 12 12v-4c-3.314 0-6.292-1.346-8.485-3.515l-1.415 1.415z"></path>
          </svg>
        ) : (
          'Send OTP'
        )}
      </button>
    </div>
  );
  const otpVerification = (
    <div className="flex-1 flex relative flex-col gap-6 place-items-center h-full w-full z-10 justify-center items-start px-7">
      <div className="flex gap-2 items-center">
        <LeftArrow onClick={() => setShowOtp(false)} className="fill-green" />
        <h4 className="text-green font-bold">Verify</h4>
      </div>
      <h6 className="text-grey-dark text-xs font-normal">
        We have sent OTP on given mobile number.
      </h6>
      <LabelledTextField label="Phone Number" value={phoneNumber} disabled />

      <div className="flex flex-col gap-2 items-start">
        <p className="text-xs font-medium text-green">OTP</p>
        <OTPInput
          wrongOtp={isIncorrectOTP}
          onChange={(otp: string) => setOtp(otp)}
        />
        {isIncorrectOTP && (
          <p className="text-red-warm text-xs">Wrong OTP Entered</p>
        )}
        <div className="flex gap-1 item-center mt-1">
          <p className="text-grey-dark text-xs">Didn’t Received OTP?</p>

          {resendTimer === 0 ? (
            <button
              onClick={() => handleLogin(phoneNumber)}
              className="text-xs text-blue-600 font-semibold">
              RESEND
            </button>
          ) : (
            <p className="text-xs">{`Resend in ${resendTimer} seconds`}</p>
          )}
        </div>
      </div>
      <button
        onClick={handleConfirmOtp}
        disabled={!otp || otp.length < 6 || isLoadingLogin}
        className="uppercase bg-green w-full rounded-full py-3 text-white text-xs font-black font-[NotoSans]">
        {isLoadingLogin ? (
          <svg
            className="animate-spin h-4 w-5  mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.007 8.007 0 014 12H0c0 6.627 5.373 12 12 12v-4c-3.314 0-6.292-1.346-8.485-3.515l-1.415 1.415z"></path>
          </svg>
        ) : (
          'Login'
        )}
      </button>
    </div>
  );

  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-grey bg-opacity-50 z-30 flex place-items-center">
      <div
        className={`${showOtp ? 'h-[420px]' : 'h-[380px]'} w-full mx-2 rounded-lg relative z-0 flex flex-col !bg-no-repeat !bg-cover`}
        style={{
          background: "url('/assets/loginBg.png')",
        }}>
        <div className="absolute right-5 top-2 w-fit z-20">
          <button onClick={() => closeModal('close')}>
            <Cross className="fill-green" />
          </button>
        </div>
        <div className="flex-1">{showOtp ? otpVerification : phoneInput}</div>
      </div>
    </div>
  );
};
