import React from 'react'

export const OrderConfirmation = () => {
  return (
      <svg width="313" height="313" viewBox="0 0 313 313" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M214.161 26.7304C214.161 26.7304 165.959 9.5968 118.658 36.8779C71.3578 64.159 47.4821 118.314 26.9806 161.709C6.47913 205.103 18.2792 249.223 54.3932 269.343C90.5071 289.463 115.065 259.79 165.727 258.357C216.389 256.923 230.011 264.172 272.053 235.539C314.096 206.906 321.689 139.329 288.53 89.3179C255.37 39.3068 214.161 26.7304 214.161 26.7304Z" fill="#92E3A9"/>
<path opacity="0.7" d="M214.161 26.7304C214.161 26.7304 165.959 9.5968 118.658 36.8779C71.3578 64.159 47.4821 118.314 26.9806 161.709C6.47913 205.103 18.2792 249.223 54.3932 269.343C90.5071 289.463 115.065 259.79 165.727 258.357C216.389 256.923 230.011 264.172 272.053 235.539C314.096 206.906 321.689 139.329 288.53 89.3179C255.37 39.3068 214.161 26.7304 214.161 26.7304Z" fill="white"/>
<path d="M86.3882 19.4058V108.298H241.01V19.4058H86.3882ZM235.564 103.29H91.8344V24.4138H235.564V103.29Z" fill="#B2B2B2" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M91.7217 59.4698C91.7217 59.4698 139.129 34.9932 142.628 41.2094C146.127 47.4256 122.033 63.3573 132.136 69.1853C142.24 75.0134 196.251 25.6658 205.954 37.3219C215.657 48.978 188.47 80.0652 197.015 83.5645C205.56 87.0639 223.826 69.9616 227.689 74.2371C231.551 78.5127 221.473 95.6088 225.748 97.9375C230.024 100.266 235.069 95.9969 235.069 95.9969" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M173.426 40.1361C174.766 34.4989 171.284 28.8421 165.646 27.5012C160.009 26.1603 154.353 29.6432 153.012 35.2803C151.671 40.9175 155.154 46.5743 160.791 47.9152C166.428 49.2561 172.085 45.7732 173.426 40.1361Z" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M113.326 34.6241C114.087 31.3865 112.08 28.1448 108.842 27.3836C105.604 26.6225 102.363 28.63 101.601 31.8677C100.84 35.1053 102.848 38.347 106.085 39.1082C109.323 39.8694 112.565 37.8618 113.326 34.6241Z" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M126.696 76.5659C126.701 77.1044 126.546 77.6323 126.251 78.0828C125.956 78.5334 125.534 78.8863 125.038 79.0969C124.543 79.3076 123.996 79.3665 123.467 79.2663C122.937 79.1661 122.45 78.9112 122.066 78.5339C121.681 78.1566 121.417 77.6738 121.308 77.1466C121.198 76.6194 121.247 76.0714 121.448 75.5719C121.65 75.0725 121.995 74.644 122.44 74.3407C122.885 74.0373 123.41 73.8728 123.948 73.8678C124.306 73.8645 124.661 73.9317 124.992 74.0655C125.324 74.1993 125.626 74.3971 125.881 74.6476C126.136 74.8982 126.34 75.1965 126.48 75.5257C126.619 75.8548 126.693 76.2082 126.696 76.5659Z" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M223.482 43.732C223.482 44.2273 223.335 44.7114 223.06 45.1232C222.785 45.535 222.394 45.8559 221.936 46.0454C221.479 46.2349 220.975 46.2845 220.49 46.1879C220.004 46.0913 219.558 45.8528 219.208 45.5026C218.857 45.1524 218.619 44.7063 218.522 44.2205C218.426 43.7348 218.475 43.2313 218.665 42.7738C218.854 42.3162 219.175 41.9252 219.587 41.65C219.999 41.3749 220.483 41.228 220.978 41.228C221.642 41.228 222.279 41.4918 222.749 41.9614C223.218 42.431 223.482 43.0679 223.482 43.732Z" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M184.944 98.7662C188.413 97.0684 189.849 92.8803 188.151 89.4117C186.453 85.9431 182.265 84.5075 178.796 86.2052C175.328 87.903 173.892 92.0912 175.59 95.5598C177.288 99.0284 181.476 100.464 184.944 98.7662Z" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.0352 155.248H297.231" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28.9902 163.617H297.231" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.0352 163.617H23.5565" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M292.78 178.116H205.691C205.647 179.528 205.044 180.866 204.015 181.834C202.985 182.802 201.614 183.322 200.201 183.28V275.659C201.613 275.617 202.983 276.136 204.013 277.103C205.042 278.07 205.645 279.406 205.691 280.817C205.691 281.114 205.661 281.409 205.604 281.7H291.654" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.1182 178.116H49.2225C49.2331 178.805 49.3793 179.485 49.6528 180.117C49.9263 180.75 50.3216 181.322 50.8163 181.801C51.311 182.281 51.8954 182.658 52.536 182.912C53.1765 183.166 53.8608 183.291 54.5498 183.28V275.659C53.1595 275.637 51.8174 276.168 50.8185 277.136C49.8196 278.103 49.2456 279.427 49.2225 280.817C49.2242 281.113 49.2514 281.409 49.3039 281.7H24.001" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M184.808 178.116H67.796C67.7502 179.53 67.145 180.868 66.1133 181.837C65.0816 182.805 63.7077 183.324 62.2935 183.28V275.659C63.7067 275.615 65.0796 276.134 66.1112 277.101C67.1427 278.068 67.7486 279.404 67.796 280.817C67.7954 281.114 67.766 281.409 67.7084 281.7H184.896C184.841 281.409 184.812 281.113 184.808 280.817C184.854 279.406 185.457 278.07 186.487 277.103C187.516 276.136 188.886 275.617 190.298 275.659V183.28C188.885 183.322 187.514 182.802 186.484 181.834C185.455 180.866 184.852 179.528 184.808 178.116Z" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M287.841 69.7427C295.333 69.7427 301.406 63.6692 301.406 56.1772C301.406 48.6853 295.333 42.6118 287.841 42.6118C280.349 42.6118 274.276 48.6853 274.276 56.1772C274.276 63.6692 280.349 69.7427 287.841 69.7427Z" fill="white" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M275.44 50.7059H300.255C299.194 48.2995 297.456 46.2534 295.253 44.817C293.05 43.3805 290.477 42.6157 287.847 42.6157C285.217 42.6157 282.644 43.3805 280.441 44.817C278.238 46.2534 276.501 48.2995 275.44 50.7059Z" fill="#636363" stroke="#636363" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M287.966 40.0765V13.772" stroke="#636363" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M289.268 38.812H286.414V43.5696H289.268V38.812Z" fill="#636363" stroke="#636363" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M32.4331 69.7427C39.9251 69.7427 45.9985 63.6692 45.9985 56.1772C45.9985 48.6853 39.9251 42.6118 32.4331 42.6118C24.9411 42.6118 18.8677 48.6853 18.8677 56.1772C18.8677 63.6692 24.9411 69.7427 32.4331 69.7427Z" fill="white" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.032 50.7062H44.8404C43.7788 48.3015 42.0411 46.2573 39.8389 44.8222C37.6366 43.3871 35.0647 42.623 32.4362 42.623C29.8076 42.623 27.2357 43.3871 25.0335 44.8222C22.8312 46.2573 21.0936 48.3015 20.032 50.7062Z" fill="#636363" stroke="#636363" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M32.5583 40.0765V13.772" stroke="#636363" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M33.8604 38.812H31.0059V43.5696H33.8604V38.812Z" fill="#636363" stroke="#636363" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M261.937 145.858C261.937 145.858 262.263 186.898 262.263 197.735C262.263 208.571 261.937 273.481 261.937 273.481" stroke="#263238" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M286.564 156.362L276.385 147.824L304.95 151.104L279.665 142.897L305.607 138.302H276.385L299.04 121.225L273.43 134.033L288.204 108.749L268.504 131.404L275.396 98.2443L262.594 129.106L261.28 94.6323L258.657 129.763L244.684 98.7264L254.388 132.718L233.485 109.919L251.758 137.97L225.892 119.516L250.118 142.897L222.693 127.91L247.821 144.869L225.091 141.908L247.163 148.149L229.491 151.104L247.821 150.446L236.985 157.671L262.594 150.778L286.564 156.362Z" fill="#92E3A9"/>
<path d="M286.564 245.874L276.385 237.342L304.95 240.622L279.665 232.415L305.607 227.82H276.385L299.04 210.743L273.43 223.551L288.204 198.267L268.504 220.922L275.396 187.762L262.594 218.624L261.28 184.15L258.657 219.282L244.684 188.244L254.388 222.236L233.485 199.444L251.758 227.488L225.892 209.034L250.118 232.415L222.693 217.429L247.821 234.387L225.091 231.426L247.163 237.667L229.491 240.622L247.821 239.965L236.985 247.189L262.594 240.296L286.564 245.874Z" fill="#92E3A9"/>
<path d="M252.084 187.224C252.084 187.224 251.101 195.757 251.758 199.043C252.455 202.834 252.784 206.683 252.741 210.536C252.741 214.799 253.073 232.941 253.073 235.896C253.073 238.85 252.741 263.959 252.741 266.257V274.138" stroke="#263238" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M253.073 232.615C253.073 232.615 254.388 207.575 255.696 204.295C257.004 201.015 258 201.998 258.325 198.035C258.651 194.072 258.651 192.457 259.308 191.149C259.832 189.978 260.271 188.771 260.623 187.537L261.28 184.914" stroke="#263238" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M276.385 195.756L266.207 187.224L294.771 190.504L269.487 182.297L295.428 177.702H266.207L288.861 160.625L263.252 173.433L278.025 148.149L258.325 170.804L265.224 137.645L252.416 168.507L251.101 134.033L248.478 169.164L238.299 137.313L244.209 172.119L226.149 146.177L241.58 177.371L217.892 161.896L239.94 182.297L215.494 172.294L237.642 184.269L214.699 182.285L236.985 187.549L221.491 191.08L237.642 189.847L226.806 197.071L252.416 190.179L276.385 195.756Z" fill="#92E3A9"/>
<path d="M243.095 264.829L236.065 276.548C235.531 277.437 235.182 278.425 235.038 279.452C234.894 280.479 234.958 281.525 235.226 282.526L238.356 294.164C238.577 294.99 239.065 295.72 239.745 296.24C240.424 296.76 241.256 297.04 242.112 297.037H274.125C274.981 297.04 275.813 296.76 276.493 296.24C277.172 295.72 277.66 294.99 277.881 294.164L281.011 282.526C281.282 281.525 281.347 280.479 281.202 279.452C281.058 278.424 280.708 277.437 280.173 276.548L273.149 264.829C272.911 264.438 272.577 264.114 272.178 263.889C271.779 263.664 271.328 263.546 270.87 263.546H245.392C244.931 263.541 244.476 263.657 244.073 263.882C243.67 264.107 243.333 264.434 243.095 264.829Z" fill="#263238" stroke="#263238" strokeLinejoin="round"/>
<path d="M221.804 98.5947C221.804 98.5947 221.954 91.6712 222.43 90.5882C222.906 89.5052 224.12 87.8213 225.047 87.5145C226.464 87.0905 227.852 86.5762 229.204 85.9746C230.548 84.8618 231.833 83.6791 233.053 82.4314C233.053 82.4314 235.514 81.8054 235.67 82.5879C235.827 83.3704 232.127 86.7446 232.127 86.7446L233.667 87.3706L241.805 87.5271L246.575 82.7507C246.575 82.7507 248.265 81.6739 248.115 82.7507C247.965 83.8274 243.339 89.8307 242.882 89.8307C242.425 89.8307 229.022 94.7573 229.022 94.7573L227.482 100.761L221.804 98.5947Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M236.428 91.6711C236.428 91.6711 239.658 90.7446 240.121 89.9746C240.584 89.2046 243.971 82.4626 244.278 81.9681C244.584 81.4735 242.588 81.8178 241.818 81.9681C241.048 82.1183 238.888 88.7414 238.888 88.7414L235.501 89.8181L236.428 91.6711Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M237.592 90.4945C237.592 90.4945 240.947 90.288 241.561 89.6369C242.174 88.9859 246.951 83.1015 247.351 82.7196C247.752 82.3378 245.73 82.2 244.948 82.1875C244.165 82.175 240.628 88.1658 240.628 88.1658L237.085 88.4913L237.592 90.4945Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M224.571 94.4383C224.571 94.4383 224.421 91.3584 226.418 89.8185C228.415 88.2785 234.731 88.1283 234.731 88.1283C234.731 88.1283 237.811 82.1249 238.581 82.1249C239.351 82.1249 241.198 81.3549 241.047 82.1249C240.897 82.8949 239.044 85.9748 238.274 87.5148C237.504 89.0547 237.198 91.0517 236.428 91.6714C235.658 92.2912 231.194 93.3616 231.194 93.3616" fill="white"/>
<path d="M224.571 94.4383C224.571 94.4383 224.421 91.3584 226.418 89.8185C228.415 88.2785 234.731 88.1283 234.731 88.1283C234.731 88.1283 237.811 82.1249 238.581 82.1249C239.351 82.1249 241.198 81.3549 241.047 82.1249C240.897 82.8949 239.044 85.9748 238.274 87.5148C237.504 89.0547 237.198 91.0517 236.428 91.6714C235.658 92.2912 231.194 93.3616 231.194 93.3616" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M219.338 103.678L221.034 97.9814L228.728 99.2147L226.881 106.295L219.338 103.678Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M188.426 97.5181C188.426 97.5181 195.199 99.3648 196.433 101.675C197.666 103.985 207.976 120.611 208.596 121.707C209.215 122.802 209.672 123.71 209.672 123.71C209.672 123.71 217.216 105.387 217.523 104.304C217.829 103.221 220.446 102.92 221.216 102.92C221.986 102.92 229.066 103.384 229.354 105.23C229.642 107.077 220.421 142.164 219.501 142.947C218.58 143.729 216.114 144.024 214.574 143.573C213.034 143.122 192.864 132.931 192.864 132.931L188.426 97.5181Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M209.641 123.691C208.608 126.206 207.793 128.805 207.206 131.46" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M204.571 130.79C204.42 131.197 204.276 131.629 204.151 132.08" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M207.344 126.001C206.507 126.972 205.804 128.049 205.253 129.206" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M150.772 157.752L145.269 222.286L141.883 278.357C141.883 278.357 140.005 280.686 140.193 281.744C140.38 282.802 141.445 285.763 141.445 285.763C141.445 285.763 138.271 294.865 139.122 295.497C139.934 296.046 140.781 296.542 141.657 296.981H150.334C150.334 296.981 152.024 295.284 151.817 294.013C151.611 292.743 150.334 285.13 150.334 285.13C151.124 283.764 151.83 282.351 152.45 280.899C152.662 280.054 151.392 275.609 151.392 275.609L171.705 197.747C171.705 197.747 180.376 246.406 182.491 257.624C184.607 268.842 188.751 296.68 188.751 296.68H193.196L193.822 294.802C193.822 294.802 194.035 294.383 196.151 295.647C197.352 296.399 198.754 296.768 200.17 296.705H211.175C211.175 296.705 212.02 295.647 211.388 295.453C210.755 295.259 202.079 289.381 200.595 288.11C199.112 286.839 196.576 283.459 196.576 283.459C196.576 283.459 198.054 279.647 198.054 279.014C198.054 278.382 196.176 274.783 196.176 274.783L194.792 218.092L195.418 159.248L150.772 157.752Z" fill="#090C0B" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M157.858 95.1519L144.493 101.562L150.966 143.166L150.34 159.68C150.34 159.68 161.433 165.752 173.978 165.358C181.57 165.104 189.049 163.446 196.038 160.469L196.695 124.411C196.695 124.411 195.374 106.846 193.791 103.941C192.207 101.036 191.412 99.7155 189.171 98.0002C186.93 96.285 180.056 94.3006 180.056 94.3006C180.056 94.3006 167.611 93.2739 167.211 93.2739C166.81 93.2739 157.858 95.1519 157.858 95.1519Z" fill="#090C0B" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M172.432 110.345L180.288 124.549L182.135 107.778L176.588 99.7217L172.494 108.968L172.432 110.345Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M177.158 102.545L181.878 108.911L183.111 95.1582L180.238 91.6714L177.158 102.545Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M159.912 80.9917L160.938 92.2847L177.158 102.545L184.138 83.4581L159.912 80.9917Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M160.525 87.6899C162.919 88.7224 165.513 89.2079 168.118 89.1109C173.752 88.8668 180.288 86.3565 183.599 84.9167L184.138 83.4581L159.912 80.9917L160.525 87.6899Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M177.158 102.545L172.432 110.345L157.858 95.1582L160.118 91.4648L177.158 102.545Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M195.631 147.861C195.631 147.861 201.634 142.778 202.404 142.778C203.174 142.778 207.024 145.701 207.024 145.701C207.024 145.701 210.868 145.244 211.024 146.164C211.181 147.085 210.398 149.708 210.398 149.708L209.472 150.628C209.472 150.628 206.855 154.634 206.855 155.248C206.855 155.861 203.469 155.711 201.929 156.018C200.389 156.324 196.082 155.248 196.082 155.248L195.631 147.861Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M189.778 147.398L196.864 147.554L197.015 154.941L191.168 155.098L189.778 147.398Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M157.126 116.129C157.118 114.3 156.694 112.496 155.888 110.855C155.081 109.213 153.912 107.776 152.468 106.652C150.002 104.73 146.935 102.42 145.883 101.982C144.036 101.212 143.116 101.518 140.499 103.371C137.883 105.224 136.956 109.062 137.113 115.378C137.269 121.694 141.726 154.941 142.19 156.325C142.653 157.708 144.068 158.021 145.269 158.171C146.471 158.322 188.939 156.876 189.759 156.788C192.689 156.481 193.165 152.494 192.996 150.628C192.689 147.241 189.759 146.165 185.296 145.701C180.833 145.238 159.004 142.628 158.203 142.471C157.678 142.357 157.163 142.202 156.663 142.008C156.663 142.008 157.126 123.998 157.126 119.071C157.126 117.97 157.126 116.999 157.126 116.129Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M148.255 147.473C147.764 148.007 147.343 148.603 147.003 149.244" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M156.682 142.008C154.364 142.996 152.172 144.257 150.152 145.764" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M146.672 145.394C146.672 145.394 150.215 140.624 155.755 141.857" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M225.279 65.6421H223.851V79.0823H225.279V65.6421Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M229.961 79.0825H219.169V79.696H229.961V79.0825Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M224.565 66.8693C223.109 66.8593 221.716 66.2726 220.692 65.2377C219.667 64.2028 219.095 62.8041 219.1 61.3479L220.164 51.3945H228.966L230.061 61.3479C230.064 62.0716 229.923 62.7886 229.648 63.458C229.373 64.1273 228.969 64.7359 228.458 65.2487C227.948 65.7616 227.341 66.1687 226.673 66.4468C226.005 66.7249 225.289 66.8684 224.565 66.8693Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M242.606 44.1703L242.099 32.5518H237.774L237.254 44.1703C237.212 45.2871 237.001 46.391 236.628 47.4443L234.124 54.3303C233.635 55.6639 233.385 57.073 233.385 58.4932V80.9603H246.531V58.4807C246.531 57.0605 246.281 55.6513 245.793 54.3178L243.289 47.4318C242.897 46.3855 242.667 45.2858 242.606 44.1703Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M245.843 60.9907H233.949V77.0414H245.843V60.9907Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M242.55 42.7806C242.547 42.7473 242.547 42.7138 242.55 42.6805L242.143 33.4845C242.133 33.2327 242.026 32.9945 241.844 32.8204C241.661 32.6463 241.419 32.55 241.166 32.5518H238.706C238.455 32.5531 238.214 32.6504 238.032 32.8239C237.851 32.9973 237.743 33.2337 237.73 33.4845L237.329 42.6805C237.329 42.6805 237.329 42.7493 237.329 42.7806H242.55Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M265.987 82.1246H207.638L204.558 79.6582H269.681L265.987 82.1246Z" fill="#8C8C8C" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M165.37 142.966L165.577 179.568H196.257L196.864 147.554L165.37 142.966Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M174.654 56.3397C175.724 56.6436 176.717 57.1714 177.567 57.888C178.417 58.6045 179.106 59.4936 179.587 60.4964C181.465 64.0458 186.523 78.8382 186.679 80.8414C186.836 82.8446 184.676 83.4643 182.21 85.0043C179.743 86.5442 171.73 87.7774 167.261 86.2563C162.791 84.7351 161.865 82.2499 161.865 82.2499C161.865 82.2499 160.168 84.2531 156.932 82.0934C153.695 79.9337 153.082 77.0103 154.315 75.9273C155.548 74.8443 157.551 75.777 157.551 75.777C157.551 75.777 154.159 67.4512 154.465 64.509C154.772 61.5668 154.929 58.6559 159.861 56.6527C164.794 54.6495 174.654 56.3397 174.654 56.3397Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M174.792 62.9878C174.792 62.9878 175.418 64.991 176.175 65.304C176.933 65.617 180.958 64.3775 181.265 66.074C181.571 67.7704 180.013 70.5436 180.013 70.5436" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M181.264 73.3169C181.264 73.3169 177.408 76.7036 173.558 73.6236" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M167.242 67.9208L171.555 66.3809" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M170.165 61.7546C170.165 61.7546 166.779 59.8766 165.082 63.3008" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M174.654 61.4481C174.654 61.4481 176.194 58.6749 178.973 60.9848" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M170.059 67.4136C170.134 67.6327 169.846 67.9207 169.433 68.0396C169.02 68.1586 168.607 68.1148 168.538 67.8957C168.469 67.6766 168.744 67.3886 169.164 67.2697C169.583 67.1507 169.984 67.1945 170.059 67.4136Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M156.3 73.3168C156.3 73.3168 157.22 76.5532 157.84 76.7034C158.459 76.8537 161.076 77.3294 160.92 75.7832C160.763 74.237 157.996 68.8471 158.303 67.0192C158.61 65.1913 162.153 62.5496 161.383 61.4728C160.613 60.3961 159.38 60.5464 159.843 59.7764C160.306 59.0064 167.699 56.54 170.485 56.54C173.27 56.54 174.491 56.3897 175.724 55.4633C176.958 54.5368 178.804 50.8371 173.101 50.6869C167.399 50.5366 158.002 54.3865 154.766 58.6997C151.53 63.0128 153.069 66.4057 153.99 68.0897C154.91 69.7736 156.3 73.3168 156.3 73.3168Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M177.652 105.656L171.455 104.335L172.732 110.839L177.884 106.902L183.625 109.512L182.567 102.87L177.652 105.656Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M109.318 138.214L111.34 148.481C111.34 148.481 112.43 150.033 116.004 151.611C119.578 153.188 121.294 151.917 121.294 149.432C121.294 146.947 120.042 133.569 120.042 133.569L109.318 138.214Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M109.957 141.939L120.455 139.072L120.048 133.551L109.318 138.214L109.957 141.939Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M53.8361 129.2L48.8281 143.354L52.5841 146.152C52.5841 146.152 55.7141 146.465 57.7173 145.063C59.7205 143.661 64.716 132.161 64.716 132.161L53.8361 129.2Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M52.7031 132.261L63.2011 135.391L64.691 132.155L53.6546 128.712L52.7031 132.261Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M92.3164 210.993C92.3164 210.993 93.7687 229.235 93.7687 233.179C93.7687 237.122 92.1098 245.824 93.1427 250.594C94.1756 255.364 97.7063 270.081 97.7063 274.851C97.7063 279.621 95.0145 288.123 96.4543 289.781C97.8941 291.44 100.605 291.854 101.431 293.537C102.257 295.221 102.683 295.61 104.129 296.236C105.575 296.862 111.384 297.268 112.63 296.862C113.876 296.455 113.256 295.409 111.178 292.48C109.099 289.55 104.336 283.978 103.509 281.493C102.683 279.008 107.86 259.101 108.067 250.819C108.273 242.537 107.86 231.952 107.86 231.952C107.86 231.952 110.558 213.291 110.558 209.56L92.3164 210.993Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M92.8422 217.848C98.6826 217.512 104.469 216.545 110.101 214.962C110.377 212.539 110.564 210.53 110.564 209.553L92.3164 211.006C92.3164 211.006 92.5543 213.998 92.8422 217.848Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M107.453 227.789C107.453 227.789 107.246 233.178 105.381 236.077" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M112.636 296.83C113.888 296.417 113.262 295.378 111.184 292.448C110.827 291.948 110.383 291.365 109.901 290.733C107.44 292.292 103.866 291.322 102.476 289.757C101.224 288.329 98.0129 284.855 96.5669 283.29C96.0598 286.289 95.7218 288.924 96.4604 289.757C97.9128 291.416 100.611 291.829 101.437 293.513C102.263 295.197 102.689 295.585 104.135 296.211C105.581 296.837 111.391 297.244 112.636 296.83Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M72.6161 211.406C72.6161 211.406 75.7461 230.894 76.1404 231.52C76.5348 232.146 76.1404 249.348 78.2125 255.157C80.2846 260.967 85.6744 279.208 85.881 282.075C86.0876 284.942 79.2517 290.814 76.3721 292.68C73.4925 294.545 72.6161 295.81 74.4941 296.436C76.3721 297.062 82.9951 296.436 85.6869 296.229C88.3787 296.023 91.997 297.018 92.6168 296.192C93.2365 295.365 92.3225 283.578 92.3225 280.053C92.3225 276.529 93.1489 251.439 91.9094 243.151C90.6699 234.862 89.4054 227.595 89.4054 227.595L89.8248 210.599L71.5769 209.973L72.6161 211.406Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M72.6161 211.406C72.6161 211.406 72.9229 213.334 73.3548 215.989C78.662 217.398 84.1408 218.056 89.6308 217.942L89.8124 210.58L71.5645 209.954L72.6161 211.406Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M76.7664 227.369C76.7664 227.369 77.1795 233.592 79.671 236.496" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M92.6105 296.173C92.8484 295.854 92.8422 294.026 92.7483 291.685C89.5181 292.148 83.8152 291.854 83.8152 291.854C82.3629 288.949 80.0592 290.195 80.0592 290.195H79.6335C78.5831 291.095 77.4793 291.931 76.3283 292.699C73.4236 294.577 72.5723 295.829 74.4503 296.455C76.3283 297.081 82.8324 297.037 85.5242 296.83C88.216 296.624 92.0221 296.999 92.6105 296.173Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M62.669 194.817L62.043 211.613C62.043 211.613 76.1468 215.55 88.1722 215.763C100.198 215.976 115.954 210.993 115.954 210.993L115.122 192.539L63.0821 191.712L62.669 194.817Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M73.0856 146.302C73.0856 146.302 61.4232 175.95 58.5186 198.342C58.5186 198.342 75.5207 203.112 92.3225 202.285C109.124 201.459 119.072 196.896 119.072 196.896C119.072 196.896 113.888 162.466 105.3 148.168L73.0856 146.302Z" fill="#090C0B" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M95.4275 56.1082C95.4275 56.1082 94.5949 50.7184 89.2051 51.7575C83.8152 52.7967 84.0218 54.6622 83.1955 56.5277C82.3691 58.3931 70.544 60.6718 67.8522 67.7205C65.1604 74.7693 71.7897 77.0542 71.7897 80.7852C71.7897 84.5161 63.4953 87.4208 65.3607 91.7715C67.2262 96.1222 74.0684 96.9547 73.4486 98.4071C72.8289 99.8594 67.8522 102.163 71.5706 105.456C75.2891 108.749 92.0972 112.091 100.993 110.433C109.888 108.774 112.192 101.725 110.533 99.4462C108.874 97.1676 107.628 96.1284 108.874 93.8122C110.12 91.496 116.542 89.2549 114.47 83.0325C112.398 76.8101 106.827 75.8085 107.046 72.2841C107.265 68.7597 113.469 66.4811 108.298 61.5044C103.127 56.5277 95.4275 56.1082 95.4275 56.1082Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M80.5474 99.6528L63.7581 104.473L73.0855 146.302C73.0855 146.302 77.4675 149.101 86.9263 149.727C93.0847 150.038 99.2568 149.514 105.274 148.168L114.452 106.026L98.4322 100.899L80.5474 99.6528Z" fill="white"/>
<path d="M77.2923 100.585L76.6663 100.761L83.2706 149.351L83.8966 149.438L77.2923 100.585Z" fill="#92E3A9"/>
<path d="M80.9669 99.6841L80.5475 99.6528L80.3472 99.7092L85.6619 149.614L86.2879 149.664L80.9669 99.6841Z" fill="#92E3A9"/>
<path d="M85.3614 99.9847L84.7354 99.9409L88.604 149.808H89.23L85.3614 99.9847Z" fill="#92E3A9"/>
<path d="M73.4737 101.681L72.8477 101.856L81.1609 148.988L81.7869 149.107L73.4737 101.681Z" fill="#92E3A9"/>
<path d="M69.4861 102.827L78.4567 148.362L79.1265 148.531L70.1121 102.664L69.4861 102.827Z" fill="#92E3A9"/>
<path d="M94.044 100.592L93.418 100.548L94.238 149.714L94.864 149.676L94.044 100.592Z" fill="#92E3A9"/>
<path d="M75.3204 147.36C75.5332 147.448 75.7649 147.536 76.009 147.623L66.6941 103.628L66.0681 103.803L75.3204 147.36Z" fill="#92E3A9"/>
<path d="M109.062 104.298L103.046 148.631L103.672 148.512L109.644 104.492L109.062 104.298Z" fill="#92E3A9"/>
<path d="M112.442 105.387L111.816 105.193L104.154 148.387L104.78 148.243L112.442 105.387Z" fill="#92E3A9"/>
<path d="M106.026 103.327L105.4 103.133L100.392 149.069L101.018 148.975L106.026 103.327Z" fill="#92E3A9"/>
<path d="M98.5011 100.917H98.4322L97.8813 100.874L95.5776 149.614L96.2036 149.564L98.5011 100.917Z" fill="#92E3A9"/>
<path d="M91.0642 149.833H91.6902L89.9812 100.323L89.3552 100.279L91.0642 149.833Z" fill="#92E3A9"/>
<path d="M102.126 102.082L101.5 101.881L98.1882 149.363L98.8142 149.288L102.126 102.082Z" fill="#92E3A9"/>
<path d="M104.719 114.297L98.5806 113.97C97.5967 113.918 96.7566 114.673 96.7041 115.657L96.6752 116.201C96.6227 117.185 97.3779 118.025 98.3618 118.077L104.5 118.404C105.484 118.457 106.324 117.702 106.377 116.718L106.406 116.174C106.458 115.19 105.703 114.35 104.719 114.297Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M80.5474 99.6528L63.7581 104.473L73.0855 146.302C73.0855 146.302 77.4675 149.101 86.9263 149.727C93.0847 150.038 99.2568 149.514 105.274 148.168L114.452 106.026L98.4322 100.899L80.5474 99.6528Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M82.2939 87.9966L82.6508 96.8294L88.892 101.662L95.1144 96.7856L97.6122 88.585L89.3615 91.0577L82.2939 87.9966Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M97.6122 88.585L89.3615 91.089L82.2939 88.0278L82.4755 92.5288C83.9591 94.532 85.474 96.1345 86.4881 96.5727C88.9921 97.6307 90.3756 97.1612 91.2019 96.3349C91.8279 95.7089 94.9892 93.7683 97.343 89.4927L97.6122 88.585Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M86.0688 103.903L87.8342 106.495L86.6573 128.993L90.069 129.112L88.8921 106.614L91.7216 103.077L88.6104 100.128L86.0688 103.903Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M81.9747 94.3569L88.4601 100.129L84.4537 106.846L78.9136 99.6592L81.9747 94.3569Z" fill="white"/>
<path d="M79.1765 99.202L84.8919 106.113L85.2299 105.543L79.5083 98.6323L79.1765 99.202Z" fill="#92E3A9"/>
<path d="M80.4411 97.0176L80.103 97.606L85.568 104.974L85.9123 104.392L80.4411 97.0176Z" fill="#92E3A9"/>
<path d="M82.1439 94.5072L81.9748 94.3569L81.6618 94.9016L87.4649 101.362L86.5885 102.426L81.2424 95.6277L80.9106 96.2099L86.5321 103.359L87.9531 100.974L82.1439 94.5072Z" fill="#92E3A9"/>
<path d="M81.9747 94.3569L88.4601 100.129L84.4537 106.846L78.9136 99.6592L81.9747 94.3569Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M95.8469 94.3569L88.6104 100.129L93.0862 106.846L99.2711 99.6592L95.8469 94.3569Z" fill="white"/>
<path d="M98.9769 99.202L92.5979 106.113L92.2161 105.543L98.6075 98.6323L98.9769 99.202Z" fill="#92E3A9"/>
<path d="M97.5684 97.0176L97.944 97.606L91.8343 104.974L91.4524 104.392L97.5684 97.0176Z" fill="#92E3A9"/>
<path d="M95.6654 94.5072L95.8469 94.3569L96.2037 94.9016L89.7184 101.362L90.6949 102.426L96.667 95.6277L97.0426 96.2099L90.7575 103.359L89.1675 100.974L95.6654 94.5072Z" fill="#92E3A9"/>
<path d="M95.8469 94.3569L88.6104 100.129L93.0862 106.846L99.2711 99.6592L95.8469 94.3569Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M78.6319 74.9946C78.6319 74.9946 76.6412 73.2668 75.8149 75.27C74.9886 77.2732 78.1749 82.4565 79.1139 82.2249C80.0529 81.9933 79.4707 75.27 79.4707 75.27L78.6319 74.9946Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M102.276 75.2261C102.276 75.2261 104.473 73.7675 105.037 75.8521C105.6 77.9367 101.775 82.6755 100.874 82.3187C99.9722 81.9619 101.412 75.3826 101.412 75.3826L102.276 75.2261Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M85.712 59.0127C85.712 59.0127 80.1782 60.7843 79.3519 65.7297C78.6007 70.2181 78.2877 78.4563 79.114 82.2248C79.9403 85.9933 85.374 94.0061 87.8342 95.064C90.2944 96.122 91.7216 95.6525 92.548 94.8262C93.3743 93.9998 98.5513 90.9387 100.555 83.2827C102.558 75.6267 102.326 69.974 101.03 64.196C100.229 60.634 91.7216 56.3021 85.712 59.0127Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M101.03 64.196C100.229 60.634 91.7215 56.3021 85.7119 59.0127C85.7119 59.0127 80.1781 60.7843 79.3517 65.7297C78.8931 68.7971 78.6526 71.8931 78.6318 74.9945C78.9073 74.043 79.3017 72.4905 79.7023 70.4435C80.4097 66.7876 81.9434 63.8454 84.535 63.6075C87.1267 63.3697 88.1846 64.5528 92.4289 64.3149C96.6732 64.077 98.0629 63.7265 99.847 65.7297C101.631 67.7329 101.381 74.3309 101.85 76.2152C102.242 72.191 101.965 68.1297 101.03 64.196Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M91.8406 78.5693L91.6027 81.2799C91.6027 81.2799 92.8986 82.1062 92.548 83.0515C92.1974 83.9968 90.7764 84.46 90.188 83.8716" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M86.4194 86.8197C86.4194 86.8197 89.249 88.3472 91.1332 88.1155C92.4632 87.8605 93.7138 87.2942 94.7828 86.4629" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M86.5383 77.4546C86.5383 78.1996 86.194 78.8068 85.7684 78.8068C85.3427 78.8068 85.0046 78.1808 85.0046 77.4546C85.0046 76.7285 85.3489 76.0962 85.7684 76.0962C86.1878 76.0962 86.5383 76.7034 86.5383 77.4546Z" fill="#263238"/>
<path d="M96.3164 77.8619C96.3164 78.6131 95.9721 79.2203 95.5527 79.2203C95.1333 79.2203 94.7827 78.5943 94.7827 77.8619C94.7827 77.1295 95.127 76.5098 95.5527 76.5098C95.9784 76.5098 96.3164 77.117 96.3164 77.8619Z" fill="#263238"/>
<path d="M83.3582 74.0932C83.3582 74.0932 85.2362 71.5892 88.5414 73.1542" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M93.3679 73.6237C93.3679 73.6237 96.548 71.5016 99.6279 74.6816" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M63.7581 104.473L51.4697 129.976L66.7128 133.864L68.5783 126.709L63.7581 104.473Z" fill="white"/>
<path d="M64.09 106.025L53.3604 130.458L53.9738 130.615L64.3154 107.065L64.09 106.025Z" fill="#92E3A9"/>
<path d="M64.9788 110.132L64.7472 109.049C61.7674 116.442 57.8612 125.951 55.689 131.053L56.315 131.203C58.7501 125.525 62.9506 115.184 64.9788 110.132Z" fill="#92E3A9"/>
<path d="M65.8239 114.001L65.586 112.93L57.9551 131.629L58.5623 131.785L65.8239 114.001Z" fill="#92E3A9"/>
<path d="M67.9335 129.162L68.5783 126.708L68.5408 126.533L65.1228 133.457L65.7425 133.613L67.9335 129.162Z" fill="#92E3A9"/>
<path d="M67.7896 123.071L67.5517 121.995L63.1008 132.943L63.708 133.094L67.7896 123.071Z" fill="#92E3A9"/>
<path d="M66.7379 118.22L66.5 117.137L60.4153 132.255L61.0225 132.412L66.7379 118.22Z" fill="#92E3A9"/>
<path d="M63.7581 104.473L51.4697 129.976L66.7128 133.864L68.5783 126.709L63.7581 104.473Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M114.452 106.025L124.555 135.103L108.849 140.862L107.766 136.818L114.452 106.025Z" fill="white"/>
<path d="M110.896 122.402L110.652 123.522L116.123 138.196L116.705 137.983L110.896 122.402Z" fill="#92E3A9"/>
<path d="M110.013 126.458L109.769 127.579L114.045 138.959L114.633 138.74L110.013 126.458Z" fill="#92E3A9"/>
<path d="M109.293 129.782L109.043 130.934L112.142 139.654L112.73 139.441L109.293 129.782Z" fill="#92E3A9"/>
<path d="M111.052 140.055L108.505 133.413L108.26 134.521L110.47 140.268L111.052 140.055Z" fill="#92E3A9"/>
<path d="M113.3 111.328L113.049 112.492L121.344 136.286L121.932 136.067L113.3 111.328Z" fill="#92E3A9"/>
<path d="M114.145 107.44L113.882 108.636L122.871 135.723L123.46 135.51L114.145 107.44Z" fill="#92E3A9"/>
<path d="M112.624 114.426L112.373 115.591L119.754 136.868L120.336 136.649L112.624 114.426Z" fill="#92E3A9"/>
<path d="M111.629 119.002L111.391 120.104L118.176 137.444L118.765 137.232L111.629 119.002Z" fill="#92E3A9"/>
<path d="M114.452 106.025L124.555 135.103L108.849 140.862L107.766 136.818L114.452 106.025Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M69.1981 118.777L66.8631 120.818L63.9084 127.197L65.6237 132.017C65.6237 132.017 49.9173 141.038 48.9846 141.971C48.0518 142.903 47.7326 149.276 49.7608 152.387C51.7891 155.498 60.334 146.165 60.334 146.165L74.3313 127.354C74.3313 127.354 77.7493 123.31 76.8353 122.22C75.9214 121.131 75.1263 120.342 74.6568 120.342C74.1873 120.342 73.1044 120.499 73.1044 120.499L69.1981 118.777Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M69.5173 114.345L73.0855 121.732L74.4878 122.201L70.5252 113.882C70.4916 113.809 70.4426 113.744 70.3815 113.692C70.3205 113.639 70.2489 113.601 70.1715 113.579C70.0942 113.557 70.0131 113.552 69.9336 113.564C69.8542 113.577 69.7784 113.606 69.7114 113.65C69.6029 113.725 69.524 113.836 69.4885 113.963C69.4531 114.09 69.4633 114.225 69.5173 114.345Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M64.6909 124.224L67.4891 132.155L76.0403 133.707L78.682 132.155L75.7273 123.134L64.6909 124.224Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M73.5552 125.776L62.356 124.843C62.356 124.843 60.8035 123.754 62.8255 122.508C63.9284 121.77 65.2762 121.491 66.5815 121.732L74.5129 122.202L73.5552 125.776Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M78.6821 130.44L76.1781 122.508C76.1781 122.508 73.6741 121.732 72.9103 123.134C72.7723 123.347 72.6789 123.586 72.6356 123.836C72.5924 124.086 72.6003 124.342 72.6588 124.589C72.7173 124.836 72.8252 125.068 72.976 125.272C73.1269 125.476 73.3175 125.648 73.5363 125.776C73.9996 126.089 75.5521 125 75.5521 125L77.2673 131.372L78.6821 130.44Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M120.668 145.526C121.029 147.053 121.187 148.621 121.137 150.19C121.137 153.32 120.361 155.636 118.339 155.636C116.317 155.636 107.766 153.132 103.879 151.123C99.9911 149.113 84.2847 141.326 84.2847 141.326C84.2847 141.326 78.682 140.7 77.1295 140.393C75.0828 139.893 73.1369 139.047 71.3766 137.889C69.8179 136.8 69.0416 133.688 69.0416 133.688C69.0416 133.688 66.2434 131.98 66.2434 131.184C66.2434 130.389 73.8618 131.961 75.2641 131.961C76.6663 131.961 78.682 130.402 78.682 130.402L77.9058 127.447C77.9058 127.447 80.704 128.537 81.4803 129.469C82.2565 130.402 85.061 135.692 85.5242 136.311C85.9874 136.931 98.4323 142.102 104.811 142.728C111.19 143.354 117.406 144.28 117.406 144.28" fill="white"/>
<path d="M120.668 145.526C121.029 147.053 121.187 148.621 121.137 150.19C121.137 153.32 120.361 155.636 118.339 155.636C116.317 155.636 107.766 153.132 103.879 151.123C99.9911 149.113 84.2847 141.326 84.2847 141.326C84.2847 141.326 78.682 140.7 77.1295 140.393C75.0828 139.893 73.1369 139.047 71.3766 137.889C69.8179 136.8 69.0416 133.688 69.0416 133.688C69.0416 133.688 66.2434 131.98 66.2434 131.184C66.2434 130.389 73.8618 131.961 75.2641 131.961C76.6663 131.961 78.682 130.402 78.682 130.402L77.9058 127.447C77.9058 127.447 80.704 128.537 81.4803 129.469C82.2565 130.402 85.061 135.692 85.5242 136.311C85.9874 136.931 98.4323 142.102 104.811 142.728C111.19 143.354 117.406 144.28 117.406 144.28" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.4058 297.037H10.354" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M306.54 297.037H21.1775" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

