import React, { ChangeEvent, useRef, useState } from 'react';
import { Plus } from '@/assets/icons/Plus';

interface UploadImageProps {
    setImage: React.Dispatch<React.SetStateAction<File | null>>;
}

export const UploadImage: React.FC<UploadImageProps> = ({ setImage }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [sizeIssue, setSizeIssue] = useState(false);
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setIsLoading(true);
        if (files && files.length > 0) {
            const file = files[0];
            const fileSizeInMB = file.size / (1024 * 1024); // Size in MB
            console.log("File Size:", fileSizeInMB, "MB");
            if (fileSizeInMB >= 6) {
                setSizeIssue(true);
            } else {
                setImage(file);
            }
        }
        setIsLoading(false);
    };

    return (
        isLoading
            ?
            <>
                <div className="flex justify-center items-center h-screen">
                    <div className="border-t-4 border-b-4 border-green rounded-full w-12 h-12 animate-spin"></div>
                </div>
            </>

            :
            <div className='flex flex-col'>
                <div className='w-[86px] h-[79px] border rounded border-green-willam flex flex-col items-center mt-3 justify-center'>
                    <input
                        id="image-upload-input"
                        type="file"
                        onChange={handleImageChange}
                        ref={hiddenFileInput}
                        style={{ display: "none" }} // Hide the input visually
                    />
                    <label htmlFor="image-upload-input" className="cursor-pointer w-full h-full flex flex-col items-center justify-center">
                        <Plus className="stroke-green" />
                        <h5 className='text-[11px] text-green-willam'>Add Media</h5>
                    </label>
                </div>
                {sizeIssue && <h2 className='mt-2 font-medium text-red-dark'>Upload a Image of size less then 6mb.</h2>}
            </div>
    );
};

