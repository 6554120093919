import React from 'react';

const TermsOfService = () => {
  return (
    <div className=" md:py-52 md:px-32 p-10">
      <h1 className="md:text-6xl text-4xl font-bold ">Terms of Service</h1>
      <p className='text-grey md:px-2'>Last edited: 10 August, 2024</p>
      <div className="text-grey-dark">
        <h3 className="text-grey text-3xl my-10 py-2 ">Introduction</h3>
        <p>
          Welcome to Quiro, a product by Witsome Tech Private Limited. These
          Terms of Service (“Terms”) govern your access and use of our platform,
          services, and applications (collectively referred to as “Services”).
          By accessing or using our Services, you agree to comply with and be
          bound by these Terms.
        </p>
        <p>
          Quiro is a B2B2C SaaS platform designed to empower hotels in managing
          their properties efficiently while offering end-customers the ability
          to avail themselves of various hotel services seamlessly. These Terms
          form a legally binding agreement between you (“User,” “Client,” or
          “Customer”) and Witsome Tech Private Limited (“Quiro,” “we,” “us,” or
          “our”).
        </p>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Acceptance of Terms
        </h3>
        <p>
          By accessing or using our Services, you signify that you have read,
          understood, and agree to be bound by these Terms, our Privacy Policy,
          and any additional terms applicable to specific services you may
          access or use. If you do not agree to these Terms, you are not
          permitted to access or use our Services.
        </p>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Scope of Services
        </h3>
        <p>
          Quiro offers a comprehensive suite of tools and services designed to
          assist hotels and hospitality businesses in managing their operations,
          reservations, customer interactions, and more. Our platform enables
          seamless integration of hotel services and provides end-users with an
          enhanced customer experience.
        </p>
        <h3 className="text-grey text-xl font-semibold py-2 ">
          For Clients (Hotels and Hospitality Businesses)
        </h3>
        <ul className=" list-decimal p-5">
          <li>
            Property Management: Efficiently manage reservations, room
            allocations, and inventory.
          </li>
          <li>
            Service Integration: Seamlessly integrate various hotel services,
            such as dining, spa, housekeeping, and more.
          </li>
          <li>
            Customer Relationship Management (CRM): Maintain and analyze
            customer data for personalized service and improved customer
            relationships.
          </li>
          <li>
            Analytics and Reporting: Access comprehensive reports and insights
            to optimize business performance.
          </li>
        </ul>
        <h3 className="text-grey text-xl font-semibold py-2 ">
          For Customers (End-Users)
        </h3>
        <ul className=" list-decimal p-5">
          <li>
            Service Access: Easily avail services offered by partner hotels,
            including reservations, dining, spa treatments, and more.
          </li>
          <li>
            Seamless Experience: Enjoy a user-friendly platform for booking and
            managing hotel services.
          </li>
          <li>
            Personalized Offers: Receive tailored promotions and offers based on
            preferences and history.
          </li>
        </ul>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          User Responsibilities
        </h3>
        <p>
          By accessing or using our Services, you agree to comply with the
          following responsibilities:
        </p>
        <ul className=" list-decimal p-5">
          <li>
            Account Security: You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            conducted under your account. You must promptly notify Quiro of any
            unauthorized access or use of your account.
          </li>
          <li>
            Accurate Information: You agree to provide accurate, current, and
            complete information when creating an account or using our Services.
            You must promptly update your information if there are any changes.
          </li>
          <li>
            Compliance with Laws: You agree to comply with all applicable laws
            and regulations while using our Services. This includes adhering to
            local regulations governing hotel operations and customer
            interactions.
          </li>
          <li>
            Prohibited Activities: You shall not engage in any activities that
            may disrupt or interfere with the functioning of our Services,
            including but not limited to hacking, distributing malware, or
            violating the privacy rights of other users.
          </li>
          <li>
            User Conduct: You shall not use the Services for any unlawful or
            unauthorized purposes, including but not limited to engaging in
            fraudulent activities, infringing on intellectual property rights,
            or violating the privacy rights of others.
          </li>
          <li>
            Content Submission: You retain ownership of the content you submit
            or create using our Services. However, you grant Quiro a
            non-exclusive, royalty-free, worldwide license to use, reproduce,
            modify, and distribute such content solely for the purpose of
            providing our Services.
          </li>
          <li>
            Prohibited Content: You shall not upload, post, or share content
            that is illegal, harmful, obscene, defamatory, infringing, or
            otherwise objectionable. Quiro reserves the right to remove or
            disable access to any content that violates these Terms or
            applicable laws.
          </li>
        </ul>
        <h3 className="text-grey text-3xl my-10 py-2 ">
          Privacy and Data Protection
        </h3>
        <p>
          Quiro is committed to safeguarding your privacy and protecting your
          personal data. Our Privacy Policy outlines how we collect, use, and
          store your information. By using our Services, you consent to the
          collection and use of your information in accordance with our Privacy
          Policy.
        </p>
        <h3 className="text-grey text-3xl my-10 py-2 ">Payment Terms</h3>
        <h3 className="text-grey text-xl font-semibold py-2 ">
          For Clients (Hotels and Hospitality Businesses)
        </h3>
        <ul className=" list-decimal p-5">
          <li>
            Subscription Fees: Access to Quiro’s Services requires the payment
            of subscription fees, as detailed in the pricing plan chosen by the
            Client. Subscription fees are billed on a monthly or annual basis
            and are subject to the terms specified at the time of purchase.
          </li>
          <li>
            Payment Gateway: Quiro partners with trusted payment gateway
            providers to facilitate secure transactions. By using our Services,
            you authorize Quiro and its payment partners to process payments and
            store relevant payment information.
          </li>
          <li>
            Refunds: All refunds are subject to Quiro’s Refund Policy. Clients
            should review the Refund Policy for eligibility criteria and
            procedures for requesting refunds.
          </li>
        </ul>
        <h3 className="text-grey text-xl font-semibold py-2 ">
          For Customers (End-Users)
        </h3>
        <ul className=" list-decimal p-5">
          <li>
            Service Charges: End-users may incur service charges or fees when
            availing themselves of hotel services through Quiro’s platform.
            These charges are determined by the partner hotels and are payable
            directly to the hotel.
          </li>
          <li>
            Payment Methods: Customers can choose from various payment methods,
            including credit/debit cards, net banking, and other available
            options. Quiro ensures secure payment processing through reputable
            payment gateways.
          </li>
          <li>
            Billing Information: Customers must provide accurate billing
            information during transactions. Quiro is not responsible for issues
            arising from incorrect billing details.
          </li>
        </ul>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Intellectual Property
        </h3>
        <h3 className="text-grey text-xl font-semibold py-2 ">Ownership</h3>
        <p>
          Quiro Content: Quiro retains ownership of all intellectual property
          rights in and to its platform, including but not limited to software,
          logos, trademarks, and proprietary content. Users may not reproduce,
          distribute, or create derivative works based on Quiro’s intellectual
          property without explicit permission.
        </p>
        <p>
          User Content: Users retain ownership of the content they submit or
          create using our Services. By sharing content on our platform, users
          grant Quiro a limited license to use and display such content for the
          purpose of providing our Services.
        </p>
        <h3 className="text-grey text-xl font-semibold py-2 ">License</h3>
        <p>
          Limited License: Subject to these Terms, Quiro grants you a limited,
          non-exclusive, non-transferable license to access and use the Services
          for their intended purpose. You may not sublicense, distribute,
          modify, or create derivative works from our Services without prior
          written consent.
        </p>
        <p>
          Prohibited Use: You agree not to reverse engineer, decompile, or
          disassemble any part of the Quiro platform. Unauthorized use of
          Quiro’s intellectual property may result in legal action.
        </p>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Termination
        </h3>
        <h3 className="text-grey text-xl font-semibold py-2 ">
          For Clients (Hotels and Hospitality Businesses)
        </h3>
        <ul className=" list-decimal p-5">
          <li>
            Termination by Client: Clients may terminate their subscription by
            providing written notice to Quiro. Upon termination, access to the
            platform and associated services will cease.
          </li>
          <li>
            Termination by Quiro: Quiro reserves the right to suspend or
            terminate a Client’s access to the Services if there is a breach of
            these Terms, non-payment of subscription fees, or for any reason
            deemed necessary for the security and integrity of the platform.
          </li>
        </ul>
        <h3 className="text-grey text-xl font-semibold py-2 ">
          For Customers (End-Users)
        </h3>
        <ul className=" list-decimal p-5">
          <li>
            Account Termination: Customers may terminate their accounts by
            following the instructions provided on the platform. Quiro will
            delete or anonymize the user’s data in accordance with our Privacy
            Policy.
          </li>
          <li>
            Service Discontinuation: Quiro may suspend or terminate access to
            Services for customers engaging in activities that violate these
            Terms, applicable laws, or pose a risk to the security of the
            platform.
          </li>
        </ul>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Disclaimer of Warranties
        </h3>
        <p>
          As-Is Basis: Quiro’s Services are provided on an “as-is” and
          “as-available” basis. Quiro disclaims all warranties, express or
          implied, including but not limited to merchantability, fitness for a
          particular purpose, and non-infringement.
        </p>
        <p>
          No Guarantee of Availability: While Quiro strives to provide reliable
          and uninterrupted services, we do not guarantee the continuous
          availability or error-free operation of our platform. Users
          acknowledge that service interruptions may occur due to maintenance,
          technical issues, or external factors.
        </p>
        <p>
          Third-Party Services: Quiro may integrate third-party services or
          provide links to external websites. We do not endorse or assume
          responsibility for the accuracy, legality, or content of such
          third-party services.
        </p>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Limitation of Liability
        </h3>
        <p>
          No Liability for Indirect Damages: Quiro shall not be liable for any
          indirect, incidental, consequential, or punitive damages arising from
          the use of our Services, including but not limited to loss of profits,
          data, or business opportunities.
        </p>
        <p>
          Liability Cap: Quiro’s total liability, whether in contract, tort, or
          otherwise, shall not exceed the amount paid by the user for the
          specific service giving rise to the claim.
        </p>
        <p>
          Force Majeure: Quiro shall not be liable for any failure or delay in
          performance due to circumstances beyond our reasonable control,
          including but not limited to natural disasters, acts of war, or
          disruptions in telecommunications.
        </p>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Indemnification
        </h3>
        <p>
          By using Quiro’s Services, you agree to indemnify, defend, and hold
          harmless Quiro, its affiliates, officers, directors, employees, and
          agents from any claims, liabilities, damages, losses, or expenses
          arising out of or related to your use of our platform, violation of
          these Terms, or infringement of any third-party rights.
        </p>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Governing Law and Jurisdiction
        </h3>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of India. Any disputes arising from or related to these Terms
          shall be subject to the exclusive jurisdiction of the courts in
          Gurgaon, Haryana.
        </p>
        <h3 className="text-grey text-3xl my-10 pt-2 border-t-2">
          Modifications to Terms
        </h3>
        <p>
          Quiro reserves the right to modify or update these Terms at any time.
          Any changes will be communicated through our platform or other
          communication channels. Continued use of our Services after
          modifications indicate acceptance of the revised Terms.
        </p>
        <h3 className="text-grey text-3xl my-5 py-5 border-t-2">Contact Us</h3>
        Your questions and concerns about issues matters to us. If you have any
        inquiries or need further clarification regarding this Refund Policy or
        our payment practices, please do not hesitate to contact us at
        info@witsome.in. Our dedicated team is here to address your concerns and
        provide assistance.
        <br />
        <br />
        We value your trust in choosing Quiro and are committed to ensuring your
        privacy is protected while delivering an outstanding full-stack
        point-of-sale solution.
        <br />
        <br />
        <strong className="me-2">Witsome Tech Private Limited</strong>
        <br />
        <br />
        <strong className="me-2">
          {' '}
          1330,4th Floor,Rail Vihar, Sector 57, Gurgaon, Sadar Bazar, Gurgaon-
          122001, Haryana
        </strong>
        <br />
        <br />
        <strong className="me-2">info@witsome.in</strong>
      </div>
    </div>
  );
};

export default TermsOfService;
