import React from 'react'

const EmptyRequest = () => {
  return (
    <svg width="359" height="359" viewBox="0 0 359 359" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M359 274.563H0V274.743H359V274.563Z" fill="#EBEBEB"/>
    <path d="M318.713 281.398H294.933V281.578H318.713V281.398Z" fill="#EBEBEB"/>
    <path d="M130.195 283.833H94.3955V284.013H130.195V283.833Z" fill="#EBEBEB"/>
    <path d="M294.215 288.069H273.012V288.249H294.215V288.069Z" fill="#EBEBEB"/>
    <path d="M53.1751 286.862H37.6663V287.042H53.1751V286.862Z" fill="#EBEBEB"/>
    <path d="M87.1149 286.862H59.5653V287.042H87.1149V286.862Z" fill="#EBEBEB"/>
    <path d="M237.816 279.453H170.554V279.633H237.816V279.453Z" fill="#EBEBEB"/>
    <path d="M170.166 242.541H31.5274C30.4413 242.539 29.4003 242.106 28.633 241.337C27.8657 240.569 27.4348 239.527 27.4348 238.441V43.5541C27.4442 42.4743 27.8794 41.4417 28.6456 40.6808C29.4119 39.9199 30.4475 39.4921 31.5274 39.4902H170.166C171.253 39.4902 172.296 39.9222 173.065 40.691C173.834 41.4599 174.266 42.5027 174.266 43.59V238.441C174.266 239.528 173.834 240.571 173.065 241.34C172.296 242.109 171.253 242.541 170.166 242.541ZM31.5274 39.6338C30.4889 39.6357 29.4936 40.0496 28.76 40.7846C28.0263 41.5196 27.6143 42.5156 27.6143 43.5541V238.441C27.6143 239.479 28.0263 240.475 28.76 241.21C29.4936 241.945 30.4889 242.359 31.5274 242.361H170.166C171.205 242.359 172.201 241.946 172.936 241.211C173.671 240.476 174.084 239.48 174.086 238.441V43.5541C174.084 42.515 173.671 41.5189 172.936 40.7842C172.201 40.0494 171.205 39.6357 170.166 39.6338H31.5274Z" fill="#EBEBEB"/>
    <path d="M325.477 242.541H186.831C185.744 242.539 184.702 242.106 183.934 241.338C183.165 240.569 182.733 239.528 182.731 238.441V43.5541C182.742 42.4736 183.179 41.4411 183.946 40.6804C184.714 39.9197 185.75 39.4921 186.831 39.4902H325.477C326.555 39.494 327.589 39.9226 328.354 40.6834C329.118 41.4441 329.553 42.4755 329.562 43.5541V238.441C329.562 239.526 329.132 240.566 328.366 241.335C327.601 242.103 326.561 242.537 325.477 242.541ZM186.831 39.6338C185.792 39.6357 184.796 40.0494 184.061 40.7842C183.326 41.5189 182.912 42.515 182.91 43.5541V238.441C182.912 239.48 183.326 240.476 184.061 241.211C184.796 241.946 185.792 242.359 186.831 242.361H325.477C326.516 242.359 327.512 241.946 328.247 241.211C328.981 240.476 329.395 239.48 329.397 238.441V43.5541C329.395 42.515 328.981 41.5189 328.247 40.7842C327.512 40.0494 326.516 39.6357 325.477 39.6338H186.831Z" fill="#EBEBEB"/>
    <path d="M95.4581 270.5H104.799L104.799 153.014H95.4581L95.4581 270.5Z" fill="#F0F0F0"/>
    <path d="M50.5759 274.549H102.337V270.492H50.5759V274.549Z" fill="#F0F0F0"/>
    <path d="M95.4581 153.006H48.1132V270.492H95.4581V153.006Z" fill="#FAFAFA"/>
    <path d="M90.4106 214.187H53.1607V235.166H90.4106V214.187Z" fill="#F0F0F0"/>
    <path d="M90.4106 241.644H53.1607V262.624H90.4106V241.644Z" fill="#F0F0F0"/>
    <path d="M66.573 216.521H76.984C77.6105 216.521 78.1184 216.013 78.1184 215.386V214.776C78.1184 214.149 77.6105 213.641 76.984 213.641H66.573C65.9464 213.641 65.4385 214.149 65.4385 214.776V215.386C65.4385 216.013 65.9464 216.521 66.573 216.521Z" fill="#FAFAFA"/>
    <path d="M66.573 243.983H76.984C77.6105 243.983 78.1184 243.476 78.1184 242.849V242.239C78.1184 241.612 77.6105 241.104 76.984 241.104H66.573C65.9464 241.104 65.4385 241.612 65.4385 242.239V242.849C65.4385 243.476 65.9464 243.983 66.573 243.983Z" fill="#FAFAFA"/>
    <path d="M90.4106 159.489H53.1607V180.469H90.4106V159.489Z" fill="#F0F0F0"/>
    <path d="M90.4106 186.73H53.1607V207.71H90.4106V186.73Z" fill="#F0F0F0"/>
    <path d="M66.573 161.815H76.984C77.6105 161.815 78.1184 161.308 78.1184 160.681V160.071C78.1184 159.444 77.6105 158.936 76.984 158.936H66.573C65.9464 158.936 65.4385 159.444 65.4385 160.071V160.681C65.4385 161.308 65.9464 161.815 66.573 161.815Z" fill="#FAFAFA"/>
    <path d="M66.573 189.064H76.984C77.6105 189.064 78.1184 188.557 78.1184 187.93V187.32C78.1184 186.693 77.6105 186.185 76.984 186.185H66.573C65.9464 186.185 65.4385 186.693 65.4385 187.32V187.93C65.4385 188.557 65.9464 189.064 66.573 189.064Z" fill="#FAFAFA"/>
    <path d="M147.269 270.5H162.728L162.728 153.014H147.269V270.5Z" fill="#F0F0F0"/>
    <path d="M102.387 274.549H159.856V270.492H102.387V274.549Z" fill="#F0F0F0"/>
    <path d="M147.269 153.006H99.9241V270.492H147.269V153.006Z" fill="#FAFAFA"/>
    <path d="M142.221 214.187H104.972V235.166H142.221V214.187Z" fill="#F0F0F0"/>
    <path d="M142.221 241.644H104.972V262.624H142.221V241.644Z" fill="#F0F0F0"/>
    <path d="M118.391 216.521H128.802C129.429 216.521 129.936 216.013 129.936 215.386V214.776C129.936 214.149 129.429 213.641 128.802 213.641H118.391C117.764 213.641 117.257 214.149 117.257 214.776V215.386C117.257 216.013 117.764 216.521 118.391 216.521Z" fill="#FAFAFA"/>
    <path d="M118.391 243.983H128.802C129.429 243.983 129.936 243.476 129.936 242.849V242.239C129.936 241.612 129.429 241.104 128.802 241.104H118.391C117.764 241.104 117.257 241.612 117.257 242.239V242.849C117.257 243.476 117.764 243.983 118.391 243.983Z" fill="#FAFAFA"/>
    <path d="M142.221 159.489H104.972V180.469H142.221V159.489Z" fill="#F0F0F0"/>
    <path d="M142.221 186.73H104.972V207.71H142.221V186.73Z" fill="#F0F0F0"/>
    <path d="M118.391 161.815H128.802C129.429 161.815 129.936 161.308 129.936 160.681V160.071C129.936 159.444 129.429 158.936 128.802 158.936H118.391C117.764 158.936 117.257 159.444 117.257 160.071V160.681C117.257 161.308 117.764 161.815 118.391 161.815Z" fill="#FAFAFA"/>
    <path d="M118.391 189.064H128.802C129.429 189.064 129.936 188.557 129.936 187.93V187.32C129.936 186.693 129.429 186.185 128.802 186.185H118.391C117.764 186.185 117.257 186.693 117.257 187.32V187.93C117.257 188.557 117.764 189.064 118.391 189.064Z" fill="#FAFAFA"/>
    <path d="M46.6844 115.44L168.148 115.44V64.7065L46.6844 64.7065V115.44Z" fill="#E6E6E6"/>
    <path d="M41.4573 115.44L166.755 115.44V64.7065L41.4573 64.7065V115.44Z" fill="#F0F0F0"/>
    <path d="M160.136 108.813V71.3193H48.0773V108.813H160.136Z" fill="#FAFAFA"/>
    <path d="M105.252 108.82L94.1226 71.3193H79.1092L90.2382 108.82H105.252Z" fill="white"/>
    <path d="M146.371 108.82L135.242 71.3193H120.229L131.358 108.82H146.371Z" fill="white"/>
    <path d="M87.4021 108.82L76.2731 71.3193H61.2598L72.3888 108.82H87.4021Z" fill="white"/>
    <path d="M274.779 50.8916H207.847V156.272H274.779V50.8916Z" fill="#EBEBEB"/>
    <path d="M270.04 151.541V55.6309H212.585V151.541H270.04Z" fill="#FAFAFA"/>
    <path d="M239.532 151.541H237.601L243.093 55.5371H245.025L239.532 151.541Z" fill="#E0E0E0"/>
    <path d="M243.129 77.0557V79.8272L270.04 72.7333V71.7568L243.129 77.0557Z" fill="#E0E0E0"/>
    <path d="M241.313 108.519V111.29L270.04 99.9169V98.9404L241.313 108.519Z" fill="#E0E0E0"/>
    <path d="M238.828 141.001V144.821L270.04 127.553V126.576L238.828 141.001Z" fill="#E0E0E0"/>
    <path d="M242.031 91.2363V94.0078L213.303 82.6347V81.6582L242.031 91.2363Z" fill="#E0E0E0"/>
    <path d="M239.704 124.666V128.486L213.303 114.392V113.415L239.704 124.666Z" fill="#E0E0E0"/>
    <path d="M213.303 151.541V55.6309H212.593V151.541H213.303Z" fill="#E0E0E0"/>
    <path d="M274.779 156.28H276.983V50.8994H274.779V156.28Z" fill="#E0E0E0"/>
    <path d="M301.093 148.54C301.093 144.167 302.788 137.619 302.788 137.619H289.354C289.354 137.619 291.056 144.167 291.056 148.54C291.056 152.913 285.742 160.746 285.742 167.007C285.742 173.268 291.099 181.058 291.099 181.058H301.036C301.036 181.058 306.392 173.261 306.392 167.007C306.392 160.753 301.093 152.934 301.093 148.54Z" fill="#E0E0E0"/>
    <path d="M309.702 154.672C309.702 151.125 310.348 145.805 310.348 145.805H305.258C305.258 145.805 305.904 151.125 305.904 154.672C305.904 158.219 303.894 164.58 303.894 169.657C303.894 174.733 305.918 181.058 305.918 181.058H309.688C309.688 181.058 311.713 174.733 311.713 169.657C311.713 164.58 309.702 158.212 309.702 154.672Z" fill="#F0F0F0"/>
    <path d="M185.502 274.557H193.135V181.051H185.502V274.557Z" fill="#E6E6E6"/>
    <path d="M183.456 274.557H187.951V181.051H183.456V274.557Z" fill="#F0F0F0"/>
    <path d="M290.453 181.059H182.063V185.848H290.453V181.059Z" fill="#F0F0F0"/>
    <path d="M327.25 274.557H334.882V181.051H327.25V274.557Z" fill="#E6E6E6"/>
    <path d="M325.814 274.557H330.309V181.051H325.814V274.557Z" fill="#F0F0F0"/>
    <path d="M336.247 181.059H293.719V185.848H336.247V181.059Z" fill="#E6E6E6"/>
    <path d="M291.099 274.557H298.731V181.051H291.099V274.557Z" fill="#E6E6E6"/>
    <path d="M289.06 274.557H293.554V181.051H289.06V274.557Z" fill="#F0F0F0"/>
    <path d="M184.784 181.059L236.258 211.968L287.731 181.059H184.784Z" fill="#FAFAFA"/>
    <path d="M189.588 181.059L236.258 209.082L282.928 181.059H189.588Z" fill="#EBEBEB"/>
    <path d="M297.388 181.059L315.891 209.082L334.401 181.059H297.388Z" fill="#FAFAFA"/>
    <path d="M299.593 181.059L316.372 206.203L333.152 181.059H299.593Z" fill="#EBEBEB"/>
    <path d="M179.5 306.988C256.385 306.988 318.713 303.349 318.713 298.86C318.713 294.371 256.385 290.732 179.5 290.732C102.615 290.732 40.287 294.371 40.287 298.86C40.287 303.349 102.615 306.988 179.5 306.988Z" fill="#F5F5F5"/>
    <path d="M144.878 292.24L139.105 290.344L141.956 276.473L147.729 278.368L144.878 292.24Z" fill="#FFB573"/>
    <path d="M195.088 292.312H189.006L187.499 278.246H193.573L195.088 292.312Z" fill="#FFB573"/>
    <path d="M188.511 291.608H195.913C196.035 291.608 196.153 291.65 196.248 291.725C196.343 291.801 196.41 291.906 196.438 292.025L197.637 297.424C197.665 297.556 197.663 297.692 197.631 297.823C197.599 297.954 197.538 298.076 197.452 298.181C197.367 298.285 197.259 298.368 197.137 298.425C197.015 298.482 196.881 298.51 196.746 298.508C194.363 298.465 192.625 298.329 189.624 298.329C187.771 298.329 182.207 298.516 179.658 298.516C177.109 298.516 176.786 295.995 177.813 295.766C182.494 294.746 186.027 293.332 187.52 291.982C187.795 291.742 188.146 291.61 188.511 291.608Z" fill="#263238"/>
    <path d="M139.206 289.433L145.603 291.192C145.719 291.226 145.82 291.297 145.89 291.395C145.96 291.492 145.996 291.611 145.991 291.731L145.711 297.259C145.705 297.393 145.669 297.524 145.606 297.641C145.542 297.759 145.453 297.861 145.344 297.939C145.236 298.017 145.111 298.07 144.979 298.092C144.847 298.115 144.712 298.108 144.584 298.071C142.286 297.396 138.99 296.34 136.09 295.536C132.701 294.603 132.069 294.28 128.091 293.188C125.686 292.521 125.686 289.907 126.756 289.965C131.617 290.223 133.038 290.848 137.669 289.498C138.165 289.333 138.698 289.311 139.206 289.433Z" fill="#263238"/>
    <path d="M169.132 107.7C168.744 108.202 168.45 108.576 168.105 108.999C167.761 109.423 167.43 109.796 167.086 110.191C166.368 110.974 165.65 111.735 164.932 112.482C163.483 114.004 161.949 115.442 160.337 116.79C157.004 119.673 153.188 121.943 149.064 123.496C147.927 123.897 146.757 124.197 145.567 124.393C145.266 124.436 144.957 124.494 144.648 124.53L143.708 124.616C143.311 124.64 142.913 124.64 142.516 124.616C142.124 124.593 141.733 124.55 141.345 124.487C140.082 124.258 138.852 123.872 137.684 123.338C136.669 122.895 135.686 122.384 134.74 121.809C132.956 120.734 131.25 119.534 129.635 118.219C128.039 116.953 126.526 115.586 125.104 114.126L127.854 110.493C131.229 112.166 134.682 113.932 137.985 115.203C138.811 115.512 139.622 115.792 140.383 116.007C141.026 116.199 141.689 116.312 142.358 116.345C142.451 116.349 142.545 116.349 142.638 116.345C142.688 116.345 142.681 116.345 142.904 116.287L143.406 116.201L143.923 116.079C144.261 116.007 144.641 115.863 144.964 115.77C145.287 115.677 145.682 115.519 146.027 115.354C147.528 114.713 148.956 113.911 150.285 112.963C151.725 111.977 153.1 110.898 154.399 109.732C155.741 108.569 157.027 107.298 158.29 106.005C158.922 105.359 159.54 104.691 160.136 104.016L161.026 103.004L161.844 102.042L169.132 107.7Z" fill="#FFB573"/>
    <path d="M172.471 104.713C172.916 110.5 159.877 122.318 159.877 122.318L148.346 112.324C148.346 112.324 153.2 107.183 158.656 102.006C162.404 98.4518 171.983 98.4446 172.471 104.713Z" fill="white"/>
    <path opacity="0.4" d="M172.471 104.713C172.916 110.5 159.877 122.318 159.877 122.318L148.346 112.324C148.346 112.324 153.2 107.183 158.656 102.006C162.404 98.4518 171.983 98.4446 172.471 104.713Z" fill="#FF725E"/>
    <path opacity="0.2" d="M166.576 107.699C161.22 108.188 157.795 112.496 156.294 117.256C156.129 117.787 155.986 118.333 155.871 118.872L159.863 122.332C159.863 122.332 165.801 116.954 169.52 111.677C169.721 109.171 169.01 107.47 166.576 107.699Z" fill="black"/>
    <path d="M129.24 113.114L127.265 108.088L120.531 110.156C121.996 111.712 123.612 113.119 125.356 114.356L129.24 113.114Z" fill="#FFB573"/>
    <path d="M123.891 104.188L118.334 106.414L120.552 110.169L127.287 108.102L123.891 104.188Z" fill="#FFB573"/>
    <path opacity="0.2" d="M141.956 276.48L140.24 284.809L146.099 286.303L147.728 278.376L141.956 276.48Z" fill="black"/>
    <path opacity="0.2" d="M193.573 278.246H187.499L188.281 285.498H194.355L193.573 278.246Z" fill="black"/>
    <path d="M197.163 101.905C195.38 108.463 194.088 115.145 193.3 121.895C192.726 126.597 192.381 131.092 192.166 135.17C191.663 144.72 191.871 152 191.584 154.463L159.166 152.216C154.758 117.651 163.187 99.8448 163.187 99.8448C165.75 99.3686 168.34 99.0497 170.941 98.8898C171.492 98.8515 172.052 98.83 172.622 98.8252C177.229 98.8871 181.83 99.2132 186.4 99.8017C187.269 99.9094 188.145 100.031 188.999 100.168C193.343 100.85 197.163 101.905 197.163 101.905Z" fill="white"/>
    <path opacity="0.4" d="M197.163 101.905C195.38 108.463 194.088 115.145 193.3 121.895C192.726 126.597 192.381 131.092 192.166 135.17C191.663 144.72 191.871 152 191.584 154.463L159.166 152.216C154.758 117.651 163.187 99.8448 163.187 99.8448C165.75 99.3686 168.34 99.0497 170.941 98.8898C171.492 98.8515 172.052 98.83 172.622 98.8252C177.229 98.8871 181.83 99.2132 186.4 99.8017C187.269 99.9094 188.145 100.031 188.999 100.168C193.343 100.85 197.163 101.905 197.163 101.905Z" fill="#FF725E"/>
    <path opacity="0.2" d="M188.97 110.65C188.917 115.622 190.311 120.502 192.984 124.694C193.077 123.775 193.185 122.835 193.3 121.894C193.731 118.355 194.291 114.714 195.03 110.995L188.97 110.65Z" fill="black"/>
    <path d="M186.12 84.1641C184.935 88.5367 183.506 96.5998 186.4 99.7734C184.935 101.102 183.202 102.101 181.318 102.703C179.434 103.305 177.443 103.496 175.479 103.263C171.674 102.911 171.667 100.233 172.607 98.8113C177.633 97.9784 177.762 94.3022 177.138 90.7984L186.12 84.1641Z" fill="#FFB573"/>
    <path opacity="0.2" d="M182.465 86.8779L177.159 90.7982C177.316 91.6148 177.401 92.4438 177.411 93.2753C179.321 93.1317 182.056 91.2434 182.401 89.276C182.566 88.487 182.588 87.6747 182.465 86.8779Z" fill="black"/>
    <path d="M176.484 77.6233C175.048 79.6552 171.279 75.9145 171.423 72.5973C176.879 71.8793 174.295 67.88 179.249 68.9068C183.37 69.7181 178.064 75.3903 176.484 77.6233Z" fill="#263238"/>
    <path d="M190.083 78.9367C188.26 84.6807 187.484 88.1415 183.779 90.4247C178.208 93.8137 171.681 89.3262 171.753 83.1514C171.817 77.594 174.797 69.1288 181.087 68.2457C182.471 68.0444 183.884 68.1998 185.192 68.6972C186.5 69.1946 187.659 70.0175 188.56 71.088C189.461 72.1585 190.073 73.4412 190.34 74.8146C190.607 76.1881 190.518 77.6069 190.083 78.9367Z" fill="#FFB573"/>
    <path d="M186.637 84.1638C186.357 82.7459 186.277 81.2958 186.4 79.8558C186.606 78.7296 186.961 77.6359 187.455 76.6033C187.455 76.6033 184.016 74.5713 183.937 72.1229C183.937 72.1229 177.791 73.9323 174.51 71.168C171.229 68.4037 174.158 65.2086 172.478 63.988C176.499 63.6003 177.719 66.0415 180.261 65.5101C182.803 64.9788 180.555 63.191 180.893 61.2021C188.554 67.8006 191.885 64.706 195.605 68.4827C197.981 70.8951 195.483 75.4186 195.483 75.4186C195.483 75.4186 199.274 81.8518 186.637 84.1638Z" fill="#263238"/>
    <path d="M194.262 75.2826C194.262 75.2826 198.024 76.058 198.125 72.1162C200.064 76.1083 197.637 76.7329 194.262 75.2826Z" fill="#263238"/>
    <path d="M177.289 84.415C177.972 84.6041 178.691 84.6258 179.385 84.4784C180.078 84.3309 180.726 84.0185 181.273 83.5677C181.303 83.5381 181.319 83.4981 181.319 83.4564C181.319 83.4147 181.303 83.3747 181.273 83.3451C181.244 83.3179 181.206 83.3027 181.166 83.3027C181.126 83.3027 181.087 83.3179 181.058 83.3451C180.546 83.7557 179.944 84.0399 179.302 84.1746C178.659 84.3093 177.994 84.2908 177.36 84.1206C177.32 84.1133 177.278 84.1209 177.242 84.1419C177.206 84.163 177.18 84.1962 177.167 84.2355C177.162 84.2751 177.171 84.3151 177.194 84.3481C177.216 84.3811 177.25 84.4048 177.289 84.415Z" fill="#263238"/>
    <path d="M191.103 85.2842C190.561 85.803 189.921 86.2095 189.221 86.4806C188.521 86.7517 187.775 86.8819 187.025 86.8638C185.014 86.8063 184.605 84.8677 185.66 83.2738C186.615 81.8378 188.805 80.1648 190.586 80.9977C192.367 81.8306 192.424 84.0061 191.103 85.2842Z" fill="#FFB573"/>
    <path d="M138.631 281.815L148.547 284.543C148.547 284.543 160.968 245.391 164.02 223.887C164.831 218.178 166.081 210.166 167.983 201.629C170.396 190.859 173.411 179.22 175.881 170.101C178.495 160.494 180.476 153.702 180.476 153.702L159.166 152.216C159.166 152.216 150.443 199.956 148.396 218.387C146.285 237.543 138.631 281.815 138.631 281.815Z" fill="#FF725E"/>
    <path opacity="0.3" d="M138.631 281.815L148.547 284.543C148.547 284.543 160.968 245.391 164.02 223.887C164.831 218.178 166.081 210.166 167.983 201.629C170.396 190.859 173.411 179.22 175.881 170.101C178.495 160.494 180.476 153.702 180.476 153.702L159.166 152.216C159.166 152.216 150.443 199.956 148.396 218.387C146.285 237.543 138.631 281.815 138.631 281.815Z" fill="black"/>
    <path opacity="0.2" d="M168.012 201.621C170.424 190.851 173.44 179.212 175.91 170.094L173.699 165.326C168.385 171.601 166.964 191.052 168.012 201.621Z" fill="black"/>
    <path d="M170.661 153.013C170.661 153.013 172.966 205.025 175.529 222.659C178.337 241.951 185.474 284.112 185.474 284.112H196.596C196.596 284.112 192.919 239.747 191.29 220.806C189.437 199.266 191.584 154.485 191.584 154.485L170.661 153.013Z" fill="#FF725E"/>
    <path opacity="0.3" d="M170.661 153.013C170.661 153.013 172.966 205.025 175.529 222.659C178.337 241.951 185.474 284.112 185.474 284.112H196.596C196.596 284.112 192.919 239.747 191.29 220.806C189.437 199.266 191.584 154.485 191.584 154.485L170.661 153.013Z" fill="black"/>
    <path d="M184.138 284.335H197.242V280.422L182.595 280.171L184.138 284.335Z" fill="#FF725E"/>
    <path d="M137.942 281.872L149.143 284.96L150.751 281.104L137.992 277.564L137.942 281.872Z" fill="#FF725E"/>
    <path d="M180.821 78.341C180.706 78.8148 180.354 79.1379 180.046 79.059C179.737 78.98 179.579 78.5348 179.701 78.0609C179.823 77.5871 180.168 77.264 180.476 77.3429C180.785 77.4219 180.936 77.8671 180.821 78.341Z" fill="#263238"/>
    <path d="M175.45 76.9909C175.328 77.4648 174.984 77.7879 174.675 77.7089C174.366 77.6299 174.208 77.1848 174.323 76.7037C174.438 76.2227 174.797 75.9067 175.106 75.9857C175.415 76.0647 175.573 76.517 175.45 76.9909Z" fill="#263238"/>
    <path d="M177.102 77.6592C176.169 78.9681 175.06 80.1423 173.806 81.1487C174.452 82.1467 176.061 82.0462 176.061 82.0462L177.102 77.6592Z" fill="#ED893E"/>
    <path d="M182.925 77.0271C182.871 77.0229 182.82 77.0039 182.777 76.9721C182.734 76.9404 182.701 76.8973 182.681 76.8476C182.548 76.5123 182.339 76.2127 182.07 75.9733C181.8 75.7338 181.478 75.561 181.13 75.469C181.054 75.455 180.987 75.4118 180.942 75.3487C180.898 75.2856 180.88 75.2076 180.893 75.1316C180.9 75.0942 180.914 75.0586 180.935 75.0268C180.955 74.995 180.982 74.9677 181.014 74.9463C181.045 74.9249 181.08 74.91 181.118 74.9024C181.155 74.8947 181.193 74.8945 181.23 74.9018C181.674 75.0108 182.085 75.2239 182.43 75.5236C182.774 75.8233 183.043 76.2009 183.212 76.625C183.241 76.6956 183.242 76.7748 183.214 76.8458C183.185 76.9168 183.131 76.9741 183.061 77.0055C183.017 77.0197 182.971 77.0269 182.925 77.0271Z" fill="#263238"/>
    <path d="M173.612 74.5641C173.551 74.5571 173.494 74.5321 173.447 74.4923C173.395 74.4386 173.365 74.3664 173.365 74.2912C173.365 74.2161 173.395 74.1439 173.447 74.0902C173.734 73.733 174.102 73.4496 174.52 73.2635C174.939 73.0774 175.395 72.994 175.853 73.0204C175.928 73.0311 175.997 73.0708 176.044 73.1311C176.09 73.1914 176.112 73.2676 176.104 73.3435C176.092 73.4183 176.051 73.4858 175.991 73.5324C175.932 73.5789 175.856 73.6012 175.781 73.5948C175.42 73.5778 175.061 73.6471 174.732 73.797C174.404 73.9468 174.116 74.1729 173.892 74.4564C173.858 74.4956 173.814 74.526 173.766 74.5447C173.717 74.5635 173.664 74.5701 173.612 74.5641Z" fill="#263238"/>
    <path d="M186.501 292.671C186.087 292.702 185.675 292.593 185.33 292.362C185.222 292.269 185.139 292.151 185.087 292.018C185.036 291.886 185.018 291.742 185.036 291.601C185.035 291.517 185.058 291.434 185.101 291.362C185.144 291.29 185.206 291.231 185.28 291.192C185.955 290.847 187.915 292.053 188.152 292.19C188.177 292.205 188.196 292.228 188.208 292.255C188.219 292.282 188.222 292.312 188.217 292.34C188.213 292.368 188.2 292.394 188.181 292.415C188.162 292.435 188.136 292.45 188.109 292.455C187.583 292.588 187.043 292.66 186.501 292.671ZM185.596 291.414C185.532 291.414 185.468 291.426 185.409 291.45C185.373 291.45 185.33 291.507 185.323 291.622C185.31 291.718 185.321 291.815 185.355 291.905C185.389 291.996 185.444 292.076 185.517 292.139C185.847 292.419 186.644 292.462 187.671 292.254C187.041 291.84 186.336 291.554 185.596 291.414Z" fill="#FF725E"/>
    <path d="M188.059 292.456C188.035 292.463 188.01 292.463 187.987 292.456C187.362 292.111 186.141 290.783 186.271 290.108C186.292 290.001 186.349 289.905 186.433 289.836C186.517 289.767 186.622 289.728 186.73 289.727C186.839 289.713 186.95 289.722 187.056 289.754C187.161 289.787 187.258 289.841 187.341 289.914C188.059 290.488 188.195 292.24 188.202 292.319C188.203 292.345 188.198 292.37 188.186 292.392C188.175 292.415 188.158 292.434 188.138 292.449C188.112 292.457 188.085 292.46 188.059 292.456ZM186.831 289.993H186.766C186.579 289.993 186.565 290.101 186.558 290.137C186.479 290.539 187.276 291.573 187.879 292.018C187.835 291.326 187.585 290.663 187.161 290.115C187.069 290.036 186.952 289.993 186.831 289.993Z" fill="#FF725E"/>
    <path d="M138.409 290.072C137.411 289.993 135.666 289.763 135.185 289.016C135.118 288.911 135.077 288.79 135.064 288.666C135.052 288.541 135.068 288.415 135.113 288.298C135.137 288.224 135.181 288.157 135.239 288.104C135.296 288.051 135.367 288.014 135.444 287.997C136.255 287.803 138.316 289.634 138.517 289.849C138.537 289.869 138.551 289.895 138.558 289.923C138.564 289.951 138.562 289.98 138.552 290.007C138.538 290.031 138.517 290.051 138.491 290.062C138.465 290.074 138.437 290.077 138.409 290.072ZM135.537 288.248H135.494C135.463 288.255 135.435 288.27 135.412 288.291C135.389 288.313 135.373 288.34 135.365 288.37C135.332 288.449 135.319 288.535 135.326 288.62C135.334 288.705 135.362 288.786 135.408 288.858C135.68 289.282 136.628 289.576 137.985 289.742C137.138 289.052 136.004 288.205 135.53 288.255L135.537 288.248Z" fill="#FF725E"/>
    <path d="M138.301 290.072C137.755 289.239 136.865 287.71 137.174 286.877C137.216 286.753 137.288 286.641 137.383 286.551C137.478 286.461 137.593 286.395 137.72 286.36C137.794 286.335 137.874 286.328 137.952 286.342C138.03 286.356 138.103 286.389 138.165 286.439C138.811 286.97 138.596 289.699 138.567 290.029C138.563 290.058 138.551 290.085 138.532 290.107C138.513 290.129 138.487 290.144 138.459 290.151C138.428 290.159 138.395 290.155 138.366 290.141C138.337 290.126 138.314 290.102 138.301 290.072ZM137.992 286.669C137.966 286.653 137.937 286.645 137.906 286.645C137.876 286.645 137.846 286.653 137.82 286.669C137.736 286.692 137.659 286.734 137.596 286.793C137.532 286.852 137.483 286.925 137.454 287.006C137.289 287.48 137.612 288.442 138.323 289.584C138.359 288.421 138.323 286.985 137.992 286.64V286.669Z" fill="#FF725E"/>
    <path d="M198.06 105.402C199.46 107.377 200.652 109.287 201.873 111.276C203.093 113.264 204.206 115.275 205.298 117.335C207.557 121.538 209.394 125.953 210.783 130.518C210.948 131.121 211.106 131.724 211.25 132.349C211.322 132.658 211.386 132.974 211.451 133.297L211.544 133.771L211.587 134.015L211.645 134.402C211.828 136.036 211.679 137.689 211.207 139.263C210.496 141.696 209.49 144.032 208.213 146.221C207.059 148.27 205.786 150.251 204.4 152.151C203.048 154.041 201.593 155.856 200.042 157.587L196.308 154.966C198.254 151.067 200.236 147.068 201.758 143.148C202.139 142.171 202.476 141.195 202.77 140.24C203.063 139.349 203.284 138.437 203.431 137.511C203.554 136.914 203.554 136.299 203.431 135.702L203.381 135.537L203.287 135.199C203.23 134.97 203.165 134.74 203.086 134.481C202.95 134.022 202.792 133.548 202.627 133.081C201.134 129.15 199.323 125.346 197.213 121.708C196.165 119.82 195.059 117.967 193.925 116.101C192.79 114.234 191.598 112.381 190.471 110.651L198.06 105.402Z" fill="#FFB573"/>
    <path d="M197.163 101.905C202.857 103.054 207.847 116.983 207.847 116.983L195.964 126.662C195.964 126.662 192.072 119.582 188.956 112.754C185.56 105.316 190.27 100.519 197.163 101.905Z" fill="white"/>
    <path opacity="0.4" d="M197.163 101.905C202.857 103.054 207.847 116.983 207.847 116.983L195.964 126.662C195.964 126.662 192.072 119.582 188.956 112.754C185.56 105.316 190.27 100.519 197.163 101.905Z" fill="#FF725E"/>
    <path d="M117.68 121.635L114.81 131.985L115.495 132.175L118.365 121.825L117.68 121.635Z" fill="#FF725E"/>
    <path d="M113.754 135.8L112.512 140.276L113.197 140.466L114.439 135.99L113.754 135.8Z" fill="#FF725E"/>
    <path d="M193.678 116.997L134.221 100.275C129.702 99.0039 125.009 101.637 123.738 106.155L107.015 165.613C105.744 170.131 108.377 174.825 112.896 176.096L172.353 192.818C176.872 194.089 181.565 191.456 182.836 186.937L199.558 127.48C200.829 122.961 198.196 118.268 193.678 116.997Z" fill="#FF725E"/>
    <path d="M159.073 132.98C158.752 132.983 158.433 132.939 158.125 132.851C157.224 132.591 156.46 131.989 155.997 131.173C155.534 130.357 155.409 129.393 155.648 128.486L158.52 118.139C158.784 117.246 159.389 116.491 160.203 116.039C161.018 115.586 161.978 115.472 162.877 115.721C163.775 115.969 164.54 116.561 165.006 117.368C165.472 118.175 165.603 119.133 165.37 120.035L162.498 130.381C162.284 131.126 161.835 131.782 161.217 132.25C160.6 132.719 159.848 132.975 159.073 132.98Z" fill="white"/>
    <path d="M146.702 176.255C146.507 176.257 146.314 176.23 146.127 176.176C145.586 176.02 145.128 175.658 144.85 175.168C144.572 174.678 144.497 174.099 144.641 173.555L147.513 163.216C147.551 162.908 147.654 162.612 147.816 162.349C147.978 162.085 148.195 161.859 148.453 161.686C148.71 161.514 149.002 161.399 149.308 161.35C149.613 161.3 149.926 161.318 150.225 161.4C150.523 161.482 150.801 161.628 151.038 161.828C151.275 162.027 151.466 162.275 151.598 162.555C151.731 162.835 151.801 163.141 151.805 163.45C151.809 163.76 151.746 164.067 151.62 164.35L148.748 174.689C148.621 175.136 148.353 175.53 147.985 175.812C147.616 176.094 147.166 176.249 146.702 176.255Z" fill="white"/>
    <path d="M169.829 138.89C169.593 138.892 169.363 138.816 169.175 138.673C168.987 138.531 168.852 138.33 168.791 138.102C168.73 137.874 168.747 137.632 168.839 137.415C168.93 137.197 169.092 137.017 169.297 136.901L178.631 131.61C178.876 131.476 179.163 131.444 179.431 131.519C179.699 131.594 179.927 131.771 180.067 132.012C180.204 132.255 180.239 132.544 180.163 132.813C180.088 133.082 179.909 133.31 179.665 133.448L170.331 138.739C170.178 138.829 170.006 138.881 169.829 138.89Z" fill="white"/>
    <path d="M129.477 163.165C128.926 163.169 128.389 162.989 127.952 162.654C127.514 162.319 127.2 161.848 127.059 161.315C126.919 160.782 126.959 160.217 127.175 159.71C127.39 159.203 127.768 158.781 128.249 158.512L137.583 153.221C138.148 152.969 138.786 152.936 139.374 153.129C139.961 153.321 140.456 153.725 140.762 154.263C141.068 154.8 141.164 155.432 141.03 156.035C140.897 156.639 140.543 157.171 140.039 157.529L130.705 162.82C130.332 163.038 129.909 163.157 129.477 163.165Z" fill="white"/>
    <path d="M181.833 155.648C181.705 155.648 181.577 155.631 181.453 155.598L171.114 152.726C170.796 152.588 170.538 152.341 170.388 152.029C170.237 151.716 170.205 151.36 170.297 151.026C170.389 150.692 170.599 150.402 170.887 150.211C171.176 150.019 171.524 149.938 171.868 149.983L182.214 152.855C182.529 152.962 182.798 153.176 182.973 153.459C183.148 153.743 183.219 154.078 183.174 154.408C183.129 154.738 182.971 155.042 182.726 155.268C182.482 155.495 182.166 155.629 181.833 155.648Z" fill="white"/>
    <path d="M137.138 144.691C136.881 144.693 136.625 144.659 136.377 144.591L126.038 141.719C125.406 141.441 124.896 140.945 124.6 140.321C124.304 139.698 124.242 138.989 124.426 138.324C124.61 137.659 125.027 137.083 125.601 136.7C126.176 136.317 126.868 136.154 127.553 136.24L137.892 139.112C138.518 139.33 139.049 139.757 139.396 140.321C139.743 140.886 139.885 141.553 139.796 142.21C139.707 142.867 139.394 143.472 138.91 143.925C138.425 144.377 137.799 144.648 137.138 144.691Z" fill="white"/>
    <path d="M168.385 173.224C168.072 173.224 167.764 173.141 167.493 172.983C167.222 172.825 166.997 172.599 166.842 172.327L161.55 162.986C161.43 162.783 161.352 162.558 161.32 162.324C161.288 162.091 161.303 161.853 161.364 161.625C161.425 161.398 161.531 161.184 161.675 160.998C161.819 160.812 161.999 160.656 162.205 160.54C162.41 160.424 162.636 160.35 162.87 160.323C163.104 160.295 163.342 160.315 163.568 160.38C163.795 160.445 164.006 160.555 164.189 160.703C164.373 160.85 164.525 161.033 164.637 161.241L169.922 170.575C170.038 170.777 170.113 171.001 170.142 171.232C170.172 171.464 170.155 171.699 170.093 171.924C170.031 172.149 169.924 172.359 169.781 172.543C169.637 172.727 169.458 172.88 169.254 172.994C168.99 173.146 168.69 173.225 168.385 173.224Z" fill="white"/>
    <path d="M145.539 134.265C144.972 134.266 144.416 134.116 143.926 133.831C143.436 133.546 143.032 133.136 142.753 132.643L137.468 123.309C137.261 122.943 137.128 122.541 137.077 122.124C137.026 121.707 137.057 121.285 137.169 120.88C137.281 120.475 137.471 120.096 137.73 119.765C137.988 119.434 138.309 119.157 138.675 118.951C139.04 118.744 139.442 118.611 139.859 118.559C140.276 118.508 140.699 118.539 141.103 118.651C141.508 118.763 141.887 118.954 142.218 119.212C142.549 119.47 142.826 119.791 143.033 120.157L148.317 129.491C148.524 129.856 148.658 130.258 148.709 130.674C148.76 131.09 148.729 131.512 148.617 131.917C148.505 132.321 148.314 132.699 148.056 133.029C147.797 133.36 147.476 133.636 147.111 133.842C146.633 134.119 146.091 134.265 145.539 134.265Z" fill="white"/>
    <path d="M198.462 153.573L192.302 153.351L195.282 160.531C195.282 160.531 200.573 159.554 200.372 156.696L198.462 153.573Z" fill="#FFB573"/>
    <path d="M188.173 156.309L189.66 161.787L195.289 160.516L192.302 153.351L188.173 156.309Z" fill="#FFB573"/>
    </svg>
    
  )
}

export default EmptyRequest
