import { Call } from '@/assets/icons/Call';
import Nav from '@/components/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultClientId } from '@/utils/constants';
import { getQueryParam } from '@/utils/routerUtils';
import { useGetClientProducts } from '@/hooks/useGetClientProducts';
import Loader from '@/atomicComponents/Loader';
import BottomSubmit from '@/atomicComponents/BottomSubmit';
import { useGetTransactionStatus } from '@/hooks/useGetTransactionStatus';
import { useEffect, useState } from 'react';

const Pending = require('@/assets/images/Pending.jpeg');

export const PaymentPending = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useNavigate();

  const localClientId =
    window.localStorage.getItem('clientId') || defaultClientId;
  const clientId =
    getQueryParam(location.search, 'clientid') ||
    getQueryParam(location.search, 'clientId') ||
    localClientId;
  const { data = [], isLoading } = useGetClientProducts(clientId);
  const transactionId = queryParams.get('transacation_id');
  const {
    data: transactionData = [],
    isLoading: loading,
    refetch,
  } = useGetTransactionStatus(transactionId);

  const contact = data.client?.contact_number;
  const [timer, setTimer] = useState(300);
  const [status, setStatus] = useState(transactionData.status);

  // Timer countdown effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    if (timer <= 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [timer]);

  // Check status effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
      setStatus(transactionData.status); 
      if (transactionData.status === 'FAILED') {
        history('/paymentFailed');
        clearInterval(intervalId);
      }
      if (transactionData.status === 'SUCCESS') {
        history('/orderConfirmation?transaction_id=' + transactionId);
        clearInterval(intervalId);
      }
    }, 5000);

    if (timer <= 0) {
      clearInterval(intervalId);
      history('/PaymentFailed');
    }

    return () => clearInterval(intervalId);
  }, [transactionData.status, timer, transactionId, history, refetch]);
  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return (
    <>
      <div className="flex absolute text-xl text-grey-dark">Order id : {transactionId}</div>
      <div className="flex flex-col h-full justify-center items-center md:pt-20 gap-3">
        <h3 className="font-semibold text-custom-red text-2xl text-center">
          Payment Confirmation Pending
        </h3>
        <img src={Pending} className=" w-64 h-64" alt="" />
        <h4 className="font-light text-custom-empty-title text-sm mt-6 text-center">
          We are checking the payment status against this transaction. <br />{' '}
          Please don’t close this window or press back.
        </h4>
        <h2>
          {minutes} minutes {seconds > 0 && `${seconds} seconds`}
        </h2>
        <div className="flex flex-col font-light text-custom-empty-title text-sm text-center  justify-center items-center mt-5">
          <p> *For support please contact staff at </p>
          <p className="flex gap-1 mt-1 text-[#003FBE]">
            <Call strokeWidth={'1px'} fill="#003FBE" /> {contact}
          </p>
        </div>
      </div>
    </>
  );
};
