const RefundPolicy = () => {
  return (
    <div className="md:py-52 md:px-32 p-10">
      <h1 className="md:text-6xl text-4xl font-bold ">Refund Policy </h1>
      <p>Last edited: 10 August, 2024</p>
      <div className="text-grey-dark">
        <h3 className="text-grey text-3xl my-10 py-2 ">Introduction</h3>
        <p>
          Welcome to Quiro, a product by Witsome Tech Private Limited. We value
          the trust and satisfaction of our customers and are committed to
          providing top-tier service and support. This Refund Policy outlines
          the guidelines and procedures for handling refund requests through our
          platform. By using our services, you agree to the terms set out in
          this policy.
        </p>
        <h3 className="text-grey text-3xl my-10 py-2">General Refund Policy</h3>
        <p>
          At Quiro, we strive to ensure that our services meet your
          expectations. We understand that there may be occasions where a refund
          is necessary. Below are the guidelines and conditions under which a
          refund may be considered:
        </p>
        <ul className="my-5 list-decimal">
          <li>
            <strong className="me-2">Eligibility for Refund:</strong>
            Refunds are generally processed only if there is a significant issue
            with the service provided that cannot be resolved satisfactorily.
            Services that have been rendered in full or digital goods already
            accessed may not be eligible for refunds unless proven defective.
          </li>
          <li>
            <strong className="me-2">Manual Processing of Refunds:</strong>
            All refunds are handled manually and processed through offline
            payment methods. We do not process refunds online. Refund
            transactions will be executed through offline banking or alternative
            offline financial methods agreed upon with the customer.{' '}
          </li>
          <li>
            <strong className="me-2">Conditions for Refund Approval:</strong>
            Refund requests are subject to evaluation based on the specific
            nature of the complaint and proof of purchase. The final decision on
            refund approval rests with management team of the property after a
            thorough review. In cases where services were not delivered or were
            misrepresented, a full refund may be issued.
          </li>
          <li>
            <strong className="me-2">Refund Process:</strong>
            <br />
            <div className="md:px-16 px-4 my-8 flex flex-col gap-4">
              <p>
                <strong> Step 1: Contact Staff:</strong> Initiate the refund
                request by contacting the staff team at the number specified
                within the client information or visit the reception or call us
                at +91 9897510877. Please provide the original transaction
                receipt or proof of purchase, along with a detailed description
                of the issue..
              </p>
              <p>
                <strong> Step 2: Evaluation: </strong>Once we receive your
                request, our team will conduct a detailed evaluation and verify
                the transaction details. We may request additional information
                or documentation to facilitate this process.
              </p>
              <p>
                <strong> Step 3: Decision:</strong> Our management will review
                your request, and if approved, we will inform you of the
                decision and provide details on the refund process.
              </p>
              <p>
                <strong> Step 4: Processing the Refund:</strong> Refunds will be
                manually processed through an offline payment method, such as
                bank transfer or other agreed-upon offline methods. Please note
                that the refund process may take 0**-14 business days** to
                complete, depending on the complexity of the transaction
              </p>
            </div>
          </li>
          <li>
            <strong className="me-2">Non-Refundable Services:</strong>
            Certain services, such as subscriptions, service add-ons, and
            customized solutions, may not be eligible for refunds. These
            exceptions will be clearly communicated during the purchase process.
          </li>
          <li>
            <strong className="me-2">Disputes:</strong>
          </li>
          In the event of a dispute, Quiro will engage in good faith efforts to
          resolve the issue amicably. Customers are encouraged to provide all
          relevant information to support their claims.
          <li>
            <strong className="me-2">Changes to the Refund Policy:</strong>
            Quiro reserves the right to amend or modify this Refund Policy at
            any time. Changes will be posted on our website at quiro.in, and it
            is the customer’s responsibility to review the policy periodically.
          </li>
        </ul>
        <h3 className="text-grey text-3xl my-5 py-5 border-t-2">
          FAQ: Frequently Asked Questions
        </h3>
        <ul>
          <li>
            <strong className="me-2">
              1. What is the typical processing time for a refund?
            </strong>
            Refunds are typically processed within 0-14 business days, but the
            exact timeline may vary based on the specific circumstances of the
            refund request.
          </li>
          <li>
            <strong className="me-2">
              2. Can I request a refund for partially used services?
            </strong>
            Refunds for partially used services are evaluated on a case-by-case
            basis. If there is a genuine issue, please contact our support team
            for assistance.
          </li>
          <li>
            <strong className="me-2">
              3. What information do I need to provide for a refund request?
            </strong>
            To expedite the refund process, please provide your original
            transaction receipt, a detailed explanation of the issue, and any
            other relevant documentation.
          </li>
          <li>
            <strong className="me-2">
              4. Are there any fees associated with the refund process?
            </strong>
            Quiro does not charge any additional fees for processing refunds.
            However, certain financial institutions may impose transaction fees,
            which are beyond our control.
          </li>
          <li>
            <strong className="me-2">
              5. How will I be informed of the refund decision?
            </strong>
            Our Customer Support team will keep you updated via email throughout
            the refund process, including the final decision and details of the
            refund transaction.
          </li>
          <li>
            <strong className="me-2">
              6. Can I change the refund method after initiating the request?
            </strong>
            Once the refund process has begun, changing the refund method may
            not be possible. Please ensure that the method chosen during the
            request aligns with your preferences.
          </li>
          <li>
            <strong className="me-2">
              7. Can I request a refund if I change my mind after purchasing a
              service?
            </strong>
            Refunds for change of mind are not typically granted. We encourage
            you to carefully review our services and consult with our team
            before purchasing.
          </li>
          <li>
            <strong className="me-2">
              8. What if I am not satisfied with the service, but it was
              delivered as described?
            </strong>
            If a service was delivered as described but did not meet your
            expectations, please reach out to our support team. We value your
            feedback and will work towards resolving your concerns.
          </li>
        </ul>
        <h3 className="text-grey text-3xl my-5 py-5 border-t-2">
          Legal Disclaimer
        </h3>
        This Refund Policy is governed by the laws of India. By using Quiro’s
        services, you agree to this policy and the terms and conditions stated
        herein. This policy does not affect your statutory rights. For a
        detailed understanding of your rights and obligations, please refer to
        our Terms of Service and Privacy Policy
        <h3 className="text-grey text-3xl my-5 py-5 border-t-2">Contact Us</h3>
        Your questions and concerns about issues matters to us. If you have any
        inquiries or need further clarification regarding this Refund Policy or
        our payment practices, please do not hesitate to contact us at
        info@witsome.in. Our dedicated team is here to address your concerns and
              provide assistance.
              <br />
              <br />
              We value your trust in choosing Quiro and are
        committed to ensuring your privacy is protected while delivering an
              outstanding full-stack point-of-sale solution.
              <br /> 
              <br />
              <strong className="me-2">Witsome Tech Private Limited</strong>
              <br /> 
              <br />
        <strong className="me-2">
          {' '}
          1330,4th Floor,Rail Vihar, Sector 57, Gurgaon, Sadar Bazar, Gurgaon-
          122001, Haryana
              </strong>
              <br /> 
              <br />
        <strong className="me-2">info@witsome.in</strong>
      </div>
    </div>
  );
};

export default RefundPolicy;
