console.log("Firebase API Key:", process.env.REACT_APP_FIREBASE_API_KEY);
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "cubik-56853.firebaseapp.com",
  projectId: "cubik-56853",
  storageBucket: "cubik-56853.appspot.com",
  messagingSenderId: "1050711121206",
  appId: "1:1050711121206:web:9b58f6730070f6f9f46797",
  measurementId: "G-K2WVJ5HGCT"
};


