import { Link } from 'react-router-dom';

const BottomFooter = () => {
  return (
    <>
      <footer className="rounded-lg flex justify-center items-center flex-col shadow mx-4  pb-4 h-18 bg-green">
        <div className="w-full mx-auto max-w-screen-xl h-full p-4 md:flex md:items-end md:justify-between">
          <div className="flex flex-col justify-center items-center">
            <img src="/logo192.png" className="w-16" />
          </div>

          <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-white sm:mt-0">
            <li>
              <Link
                to={'/terms-of-service'}
                className="hover:underline me-4 md:me-6">
                Terms of Service
              </Link>
            </li>
            <li>
              <Link
                to="/refund-policy"
                className="hover:underline me-4 md:me-6">
                Refund Policy
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className="hover:underline me-4 md:me-6">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <span className="text-sm border-t-2 w-[95%] pt-2 border-[#A5A5A5C7] text-white mb-10 sm:text-center  ">
          ©️ Witsome Tech Private Limited 2024
        </span>
      </footer>
    </>
  );
};

export default BottomFooter;
