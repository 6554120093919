const CookingPana = require('@/assets/images/cookingPana.png');

interface IDataNotFoundProps {
  search: string;
}

export const DataNotFound = ({ search }: IDataNotFoundProps) => {
  return (
    <div className="flex justify-center items-center h-full flex-col gap-5">
      <h1 className="">No Item found</h1>
      <img src={CookingPana} className="h-64 w-64" />

      <h1 className="p-5">We couldn’t find any items named {search || 'this search'}</h1>
    </div>
  );
};
  