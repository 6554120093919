import React from 'react'

const RequestConfirmation = () => {
  return (
    <svg width="327" height="327" viewBox="0 0 327 327" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M301.289 146.201L299.868 147.048L250.13 118.327L251.55 117.479L301.289 146.201Z" fill="#FAFAFA"/>
<path d="M251.55 117.479L299.868 145.377V55.4513L251.55 27.5635V117.479Z" fill="white"/>
<path d="M247.449 119.874L302.548 151.692V53.905L247.448 22.0879L247.449 119.874ZM250.13 26.73L299.868 55.4517L299.868 147.048L250.131 118.327L250.13 26.73Z" fill="#F0F0F0"/>
<path d="M250.13 118.327L251.55 117.48V27.5638L250.13 26.73V118.327Z" fill="#E0E0E0"/>
<path d="M299.868 113.796V115.463L250.13 86.7416L251.55 85.894L299.868 113.796Z" fill="#FAFAFA"/>
<path d="M299.868 82.245V83.9121L250.13 55.1904L251.55 54.3428L299.868 82.245Z" fill="#FAFAFA"/>
<path d="M247.449 22.0871L248.891 21.2617L303.991 53.0788L302.548 53.9042L247.449 22.0871Z" fill="#FAFAFA"/>
<path d="M303.991 53.0786L302.548 53.904V151.691L303.991 150.865V53.0786Z" fill="#E0E0E0"/>
<path d="M277.781 42.6948L276.339 41.8623V133.464L277.781 132.638V42.6948Z" fill="#E0E0E0"/>
<path d="M273.658 40.3213L276.339 41.8621V133.463L273.658 131.911L273.658 40.3213Z" fill="#F0F0F0"/>
<path d="M247.449 53.6431V56.7345L302.548 88.5516V85.4602L247.449 53.6431Z" fill="#F0F0F0"/>
<path d="M247.449 85.187V88.2785L302.548 120.096V117.004L247.449 85.187Z" fill="#F0F0F0"/>
<path d="M276.339 70.3223L277.781 69.4878L299.868 82.2454V83.9124L276.339 70.3223Z" fill="#FAFAFA"/>
<path d="M276.339 101.857L277.781 101.022L299.868 113.779V115.447L276.339 101.857Z" fill="#FAFAFA"/>
<path d="M25.7114 146.201L27.1319 147.048L76.8699 118.327L75.45 117.479L25.7114 146.201Z" fill="#FAFAFA"/>
<path d="M75.45 117.479L27.1325 145.377V55.4513L75.45 27.5635V117.479Z" fill="white"/>
<path d="M79.5513 22.0869L24.4511 53.904V151.691L79.5506 119.873L79.5513 22.0869ZM76.8699 118.327L27.1325 147.049L27.1332 55.452L76.8705 26.7303L76.8699 118.327Z" fill="#F0F0F0"/>
<path d="M76.8699 118.327L75.45 117.48V27.5638L76.8699 26.73V118.327Z" fill="#E0E0E0"/>
<path d="M27.1319 113.796V115.463L76.8699 86.7416L75.45 85.894L27.1319 113.796Z" fill="#FAFAFA"/>
<path d="M27.1319 82.245V83.9121L76.8699 55.1904L75.45 54.3428L27.1319 82.245Z" fill="#FAFAFA"/>
<path d="M79.5513 22.0871L78.1085 21.2617L23.009 53.0788L24.4518 53.9042L79.5513 22.0871Z" fill="#FAFAFA"/>
<path d="M23.009 53.0786L24.4518 53.904V151.691L23.009 150.865V53.0786Z" fill="#E0E0E0"/>
<path d="M49.2187 42.6948L50.6608 41.8623V133.464L49.2187 132.638V42.6948Z" fill="#E0E0E0"/>
<path d="M53.3422 40.3213L50.6608 41.8621V133.463L53.3416 131.911L53.3422 40.3213Z" fill="#F0F0F0"/>
<path d="M79.5513 53.6431V56.7345L24.4518 88.5516V85.4602L79.5513 53.6431Z" fill="#F0F0F0"/>
<path d="M79.5513 85.187V88.2785L24.4518 120.096V117.004L79.5513 85.187Z" fill="#F0F0F0"/>
<path d="M50.6608 70.3223L49.2187 69.4878L27.1319 82.2454V83.9124L50.6608 70.3223Z" fill="#FAFAFA"/>
<path d="M50.6608 101.857L49.2187 101.022L27.1319 113.779V115.447L50.6608 101.857Z" fill="#FAFAFA"/>
<path d="M56.6397 168.418C-2.35047 202.476 -2.34851 257.695 56.6429 291.753C115.634 325.81 211.277 325.81 270.267 291.753C329.256 257.695 329.255 202.476 270.264 168.418C211.272 134.36 115.629 134.36 56.6397 168.418Z" fill="#F5F5F5"/>
<path d="M35.2846 248.247C35.2061 248.191 35.1558 248.105 35.1473 248.01C33.9334 234.817 22.7082 225.271 17.915 221.194C17.14 220.535 16.5279 220.015 16.1355 219.631C14.3788 217.915 13.6169 215.661 14.0983 213.602C14.5312 211.746 15.9537 210.3 17.9994 209.634C23.0051 208.004 30.859 214.21 35.8771 223.759C42.9089 237.143 39.6089 247.015 39.5748 247.113C39.5389 247.216 39.4532 247.295 39.3479 247.322L35.556 248.297C35.5122 248.308 35.4677 248.31 35.4252 248.303C35.3749 248.295 35.3265 248.276 35.2846 248.247ZM35.2977 224.064C30.4234 214.789 22.9142 208.725 18.2028 210.256C16.3526 210.859 15.1218 212.1 14.7359 213.75C14.3082 215.585 15.0021 217.608 16.5939 219.162C16.97 219.53 17.5749 220.044 18.3395 220.695C23.1477 224.784 34.3461 234.308 35.7614 247.567L39.0065 246.733C39.395 245.408 41.6958 236.242 35.2977 224.064Z" fill="#4F7065"/>
<path d="M37.5782 247.749C37.5926 247.75 37.607 247.752 37.6227 247.752C37.8032 247.756 37.9523 247.612 37.9555 247.431C38.2361 232.022 27.57 217.176 20.3937 214.119C20.2269 214.047 20.0346 214.125 19.964 214.291C19.8934 214.458 19.9705 214.65 20.1367 214.721C27.2332 217.743 37.5671 232.805 37.3015 247.419C37.2976 247.585 37.4186 247.724 37.5782 247.749Z" fill="#4F7065"/>
<path d="M39.2165 247.328C39.2629 247.335 39.3119 247.333 39.3603 247.318L41.9312 246.54C42.0352 246.509 42.1163 246.428 42.149 246.326C42.1954 246.179 46.729 231.457 43.3877 211.626C41.686 201.523 35.7973 191.67 29.3855 188.194C26.3019 186.525 23.2726 186.315 20.3793 187.574C18.7436 188.286 17.7195 189.335 17.3356 190.692C16.2741 194.444 20.17 200.03 24.2935 205.944C27.1789 210.081 30.1631 214.359 31.5241 218.137C31.5856 218.308 31.7733 218.394 31.9433 218.334C32.1134 218.272 32.2017 218.085 32.1402 217.915C30.7498 214.055 27.7407 209.741 24.8311 205.568C20.7979 199.786 16.9883 194.323 17.9654 190.87C18.2976 189.694 19.1727 188.813 20.6409 188.174C23.3393 186.999 26.1764 187.198 29.0729 188.77C35.3225 192.156 41.0705 201.814 42.7415 211.734C45.867 230.288 42.0555 244.303 41.5722 245.964L39.17 246.691C38.9974 246.742 38.8986 246.925 38.9516 247.099C38.9902 247.224 39.0955 247.31 39.2165 247.328Z" fill="#4F7065"/>
<path d="M39.8535 237.952C39.8613 237.953 39.8692 237.955 39.877 237.955C40.0575 237.969 40.2151 237.834 40.2302 237.654C42.168 213.817 28.8702 194.731 24.9357 191.455C24.7971 191.34 24.5904 191.358 24.4747 191.497C24.3589 191.636 24.3772 191.843 24.5165 191.958C28.3941 195.186 41.4943 214.022 39.5775 237.601C39.5624 237.775 39.6847 237.926 39.8535 237.952Z" fill="#4F7065"/>
<path d="M291.698 248.303C291.655 248.31 291.611 248.307 291.567 248.297L287.775 247.322C287.669 247.295 287.583 247.216 287.548 247.113C287.513 247.015 284.213 237.143 291.246 223.759C296.264 214.21 304.118 208.003 309.124 209.634C311.17 210.3 312.592 211.747 313.025 213.602C313.506 215.662 312.744 217.916 310.987 219.631C310.595 220.015 309.983 220.535 309.208 221.194C304.415 225.271 293.19 234.817 291.976 248.01C291.967 248.105 291.917 248.192 291.838 248.247C291.796 248.277 291.748 248.296 291.698 248.303ZM288.116 246.733L291.362 247.567C292.777 234.308 303.975 224.784 308.783 220.695C309.548 220.044 310.153 219.53 310.529 219.163C312.121 217.608 312.815 215.585 312.387 213.751C312.001 212.1 310.77 210.859 308.92 210.256C304.209 208.725 296.7 214.789 291.825 224.064C285.427 236.242 287.728 245.408 288.116 246.733Z" fill="#4F7065"/>
<path d="M289.545 247.749C289.53 247.751 289.515 247.753 289.5 247.752C289.32 247.756 289.171 247.612 289.167 247.431C288.887 232.022 299.553 217.176 306.729 214.119C306.896 214.047 307.088 214.126 307.159 214.292C307.23 214.458 307.152 214.651 306.986 214.721C299.89 217.744 289.556 232.806 289.821 247.419C289.825 247.586 289.704 247.725 289.545 247.749Z" fill="#4F7065"/>
<path d="M287.906 247.328C287.86 247.335 287.811 247.333 287.763 247.318L285.192 246.54C285.088 246.509 285.007 246.428 284.974 246.326C284.927 246.179 280.394 231.457 283.735 211.626C285.437 201.523 291.326 191.67 297.737 188.194C300.821 186.525 303.85 186.315 306.744 187.574C308.379 188.286 309.403 189.335 309.787 190.692C310.849 194.444 306.953 200.03 302.829 205.944C299.944 210.081 296.96 214.359 295.599 218.137C295.537 218.308 295.35 218.394 295.18 218.334C295.01 218.272 294.921 218.085 294.983 217.915C296.373 214.055 299.382 209.741 302.292 205.568C306.325 199.786 310.135 194.323 309.158 190.87C308.825 189.694 307.95 188.813 306.482 188.174C303.784 186.999 300.947 187.198 298.05 188.77C291.8 192.156 286.052 201.814 284.381 211.734C281.256 230.288 285.067 244.303 285.551 245.964L287.953 246.691C288.126 246.742 288.224 246.925 288.171 247.099C288.133 247.224 288.027 247.31 287.906 247.328Z" fill="#4F7065"/>
<path d="M287.269 237.952C287.262 237.953 287.254 237.955 287.246 237.955C287.065 237.969 286.908 237.834 286.893 237.654C284.955 213.817 298.253 194.731 302.187 191.455C302.326 191.34 302.533 191.358 302.648 191.497C302.764 191.636 302.746 191.843 302.606 191.958C298.729 195.186 285.629 214.022 287.545 237.601C287.561 237.775 287.438 237.926 287.269 237.952Z" fill="#4F7065"/>
<path d="M174.351 233.616L222.48 205.829C222.968 205.547 223.212 205.178 223.212 204.807C223.212 204.438 222.968 204.068 222.48 203.786L148.871 161.289C147.894 160.725 146.31 160.725 145.333 161.289L97.2047 189.076C96.7161 189.358 96.4722 189.728 96.4722 190.098C96.4722 190.467 96.7168 190.837 97.2047 191.119L170.813 233.616C171.79 234.181 173.374 234.181 174.351 233.616Z" fill="#E0E0E0"/>
<path d="M192.735 288.692C213.78 288.692 230.84 278.843 230.84 266.692C230.84 254.542 213.78 244.692 192.735 244.692C171.69 244.692 154.63 254.542 154.63 266.692C154.63 278.843 171.69 288.692 192.735 288.692Z" fill="#E0E0E0"/>
<path d="M127.814 288.692C148.859 288.692 165.919 278.843 165.919 266.692C165.919 254.542 148.859 244.692 127.814 244.692C106.769 244.692 89.7085 254.542 89.7085 266.692C89.7085 278.843 106.769 288.692 127.814 288.692Z" fill="#E0E0E0"/>
<path d="M70.014 277.85C91.059 277.85 108.119 268 108.119 255.85C108.119 243.7 91.059 233.85 70.014 233.85C48.969 233.85 31.9087 243.7 31.9087 255.85C31.9087 268 48.969 277.85 70.014 277.85Z" fill="#E0E0E0"/>
<path d="M257.737 272.434C278.782 272.434 295.843 262.585 295.843 250.434C295.843 238.284 278.782 228.435 257.737 228.435C236.692 228.435 219.632 238.284 219.632 250.434C219.632 262.585 236.692 272.434 257.737 272.434Z" fill="#E0E0E0"/>
<path d="M177.736 219.466C177.756 219.478 177.776 219.486 177.8 219.494C175.621 222.323 172.065 223.133 171.267 222.761C170.625 222.464 170.243 221.697 170.733 221.24C170.958 221.031 171.347 220.815 171.712 220.51C172.78 219.623 173.133 218.078 173.233 216.874L177.736 219.466Z" fill="#E0E0E0"/>
<path d="M175.494 223.837C175.508 221.558 173.926 218.778 171.96 217.626C170.928 217.022 170 216.992 169.345 217.424L166.926 218.785L171.995 227.524L174.392 226.165L174.388 226.159C175.061 225.8 175.486 225 175.494 223.837Z" fill="#37474F"/>
<path d="M169.449 219.051C171.415 220.203 172.997 222.984 172.982 225.262C172.968 227.54 171.362 228.454 169.396 227.302C167.431 226.151 165.849 223.37 165.864 221.091C165.879 218.813 167.484 217.9 169.449 219.051Z" fill="#455A64"/>
<path d="M168.219 222.471C168.225 221.58 168.768 221.174 169.434 221.564C170.1 221.954 170.634 222.993 170.629 223.883C170.623 224.773 170.079 225.18 169.413 224.789C168.748 224.399 168.213 223.361 168.219 222.471Z" fill="#263238"/>
<path d="M171.55 215.906C171.55 215.906 171.784 220.112 169.858 221.718C169.495 222.021 169.103 222.239 168.877 222.45C168.387 222.905 168.772 223.671 169.414 223.97C170.348 224.404 175.134 223.173 176.999 219.043L171.55 215.906Z" fill="#F5F5F5"/>
<path d="M169.508 222.723C169.71 222.825 169.885 223.091 169.898 223.317C169.911 223.544 169.757 223.644 169.555 223.542C169.353 223.44 169.178 223.174 169.165 222.948C169.153 222.722 169.306 222.621 169.508 222.723Z" fill="#263238"/>
<path d="M123.223 188.02C123.243 188.032 123.263 188.041 123.287 188.048C121.108 190.878 117.553 191.688 116.754 191.315C116.112 191.018 115.731 190.251 116.22 189.793C116.445 189.585 116.834 189.368 117.199 189.063C118.267 188.176 118.62 186.631 118.721 185.427L123.223 188.02Z" fill="#E0E0E0"/>
<path d="M120.981 192.39C120.995 190.112 119.413 187.331 117.447 186.179C116.415 185.575 115.487 185.545 114.832 185.977L112.413 187.337L117.482 196.077L119.879 194.718L119.876 194.712C120.549 194.354 120.973 193.553 120.981 192.39Z" fill="#37474F"/>
<path d="M114.937 187.605C116.903 188.757 118.485 191.537 118.47 193.816C118.456 196.095 116.85 197.008 114.884 195.856C112.918 194.704 111.336 191.923 111.351 189.645C111.366 187.367 112.971 186.453 114.937 187.605Z" fill="#455A64"/>
<path d="M113.706 191.024C113.712 190.133 114.256 189.728 114.921 190.117C115.587 190.507 116.121 191.546 116.116 192.436C116.11 193.327 115.566 193.732 114.901 193.342C114.235 192.953 113.7 191.915 113.706 191.024Z" fill="#263238"/>
<path d="M117.037 184.46C117.037 184.46 117.271 188.666 115.345 190.273C114.982 190.575 114.59 190.793 114.364 191.004C113.875 191.46 114.259 192.225 114.901 192.524C115.835 192.959 120.621 191.728 122.486 187.598L117.037 184.46Z" fill="#F5F5F5"/>
<path d="M114.996 191.277C115.199 191.379 115.373 191.645 115.386 191.872C115.399 192.098 115.245 192.199 115.043 192.097C114.84 191.995 114.666 191.728 114.653 191.502C114.64 191.276 114.793 191.175 114.996 191.277Z" fill="#263238"/>
<path d="M219.263 195.907C219.283 195.919 219.303 195.927 219.327 195.935C217.148 198.764 213.592 199.574 212.794 199.202C212.152 198.905 211.77 198.138 212.26 197.681C212.485 197.472 212.874 197.256 213.239 196.951C214.307 196.064 214.66 194.519 214.76 193.315L219.263 195.907Z" fill="#E0E0E0"/>
<path d="M217.021 200.277C217.035 197.998 215.453 195.217 213.487 194.066C212.455 193.462 211.527 193.432 210.872 193.864L208.453 195.225L213.522 203.964L215.919 202.605L215.915 202.599C216.588 202.241 217.013 201.44 217.021 200.277Z" fill="#37474F"/>
<path d="M210.976 195.491C212.942 196.643 214.524 199.424 214.509 201.702C214.495 203.98 212.889 204.894 210.924 203.742C208.958 202.591 207.376 199.81 207.391 197.531C207.406 195.253 209.011 194.34 210.976 195.491Z" fill="#455A64"/>
<path d="M209.746 198.911C209.752 198.02 210.296 197.614 210.961 198.004C211.627 198.394 212.161 199.432 212.156 200.323C212.15 201.213 211.606 201.619 210.941 201.229C210.275 200.84 209.74 199.801 209.746 198.911Z" fill="#263238"/>
<path d="M213.077 192.347C213.077 192.347 213.311 196.553 211.385 198.159C211.022 198.462 210.63 198.68 210.404 198.89C209.914 199.346 210.299 200.112 210.941 200.411C211.875 200.845 216.661 199.614 218.526 195.484L213.077 192.347Z" fill="#F5F5F5"/>
<path d="M211.035 199.163C211.237 199.265 211.412 199.531 211.424 199.757C211.438 199.984 211.284 200.084 211.081 199.982C210.879 199.88 210.704 199.614 210.691 199.388C210.68 199.162 210.833 199.062 211.035 199.163Z" fill="#263238"/>
<path d="M164.75 164.461C164.77 164.472 164.79 164.481 164.814 164.489C162.635 167.318 159.08 168.128 158.281 167.755C157.639 167.458 157.258 166.691 157.747 166.234C157.972 166.026 158.362 165.809 158.726 165.504C159.794 164.617 160.147 163.072 160.248 161.868L164.75 164.461Z" fill="#E0E0E0"/>
<path d="M162.508 168.831C162.522 166.553 160.94 163.772 158.974 162.62C157.942 162.016 157.014 161.986 156.359 162.418L153.94 163.778L159.009 172.518L161.406 171.159L161.403 171.153C162.076 170.794 162.5 169.993 162.508 168.831Z" fill="#37474F"/>
<path d="M156.464 164.045C158.43 165.197 160.012 167.978 159.997 170.256C159.983 172.535 158.377 173.448 156.411 172.296C154.445 171.145 152.863 168.364 152.878 166.085C152.893 163.807 154.498 162.894 156.464 164.045Z" fill="#455A64"/>
<path d="M155.233 167.465C155.239 166.574 155.782 166.169 156.448 166.558C157.114 166.948 157.648 167.986 157.643 168.877C157.637 169.768 157.093 170.174 156.428 169.783C155.762 169.393 155.227 168.356 155.233 167.465Z" fill="#263238"/>
<path d="M158.565 160.901C158.565 160.901 158.799 165.107 156.872 166.713C156.509 167.016 156.118 167.234 155.891 167.444C155.402 167.9 155.787 168.666 156.429 168.965C157.363 169.399 162.149 168.168 164.014 164.038L158.565 160.901Z" fill="#F5F5F5"/>
<path d="M156.523 167.718C156.725 167.82 156.9 168.086 156.912 168.312C156.925 168.538 156.772 168.639 156.569 168.537C156.367 168.435 156.192 168.169 156.179 167.943C156.167 167.716 156.32 167.616 156.523 167.718Z" fill="#263238"/>
<path d="M220.12 191.926C220.562 192.181 220.783 192.515 220.783 192.849C220.783 193.184 220.785 196.446 220.783 196.781C220.781 197.114 220.56 197.446 220.12 197.699L176.616 222.816C175.733 223.326 174.301 223.326 173.418 222.816L106.881 184.402C106.44 184.147 106.219 183.813 106.219 183.479C106.219 183.145 106.219 179.886 106.219 179.552C106.219 179.217 106.44 178.883 106.881 178.629L150.385 153.512C151.268 153.002 152.701 153.002 153.583 153.512L220.12 191.926Z" fill="#F0F0F0"/>
<path d="M173.417 218.891L106.883 180.479C106.461 180.236 106.238 179.922 106.219 179.598C106.218 180.202 106.22 183.153 106.217 183.479C106.221 183.816 106.441 184.149 106.884 184.404L173.418 222.816C173.85 223.066 174.41 223.191 174.975 223.197C174.975 221.129 174.978 220.988 174.974 219.272C174.415 219.268 173.848 219.14 173.417 218.891Z" fill="#E0E0E0"/>
<path d="M175.017 218.945C174.477 218.945 173.954 218.821 173.581 218.606L107.045 180.193C106.728 180.009 106.546 179.777 106.546 179.552C106.546 179.329 106.728 179.095 107.045 178.913L150.549 153.796C150.922 153.581 151.445 153.457 151.985 153.457C152.525 153.457 153.048 153.581 153.421 153.796L219.957 192.209C220.274 192.392 220.456 192.626 220.456 192.849C220.456 193.073 220.274 193.307 219.957 193.489L176.452 218.606C176.08 218.821 175.556 218.945 175.017 218.945Z" fill="#FAFAFA"/>
<path d="M151.985 153.783C152.469 153.783 152.933 153.891 153.257 154.078L219.794 192.492C220.025 192.626 220.129 192.768 220.129 192.849C220.129 192.931 220.025 193.072 219.794 193.207L176.29 218.323C175.965 218.51 175.502 218.618 175.018 218.618C174.534 218.618 174.07 218.511 173.746 218.323L107.209 179.91C106.977 179.777 106.873 179.634 106.873 179.553C106.873 179.472 106.977 179.33 107.209 179.196L150.713 154.079C151.037 153.891 151.501 153.783 151.985 153.783ZM151.985 153.129C151.406 153.129 150.827 153.257 150.386 153.512L106.882 178.629C106.44 178.884 106.219 179.218 106.219 179.552C106.219 179.886 106.44 180.22 106.882 180.475L173.419 218.889C173.86 219.144 174.439 219.272 175.018 219.272C175.596 219.272 176.175 219.144 176.617 218.889L220.121 193.772C220.562 193.516 220.783 193.182 220.783 192.848C220.783 192.514 220.563 192.18 220.121 191.925L153.584 153.511C153.142 153.257 152.563 153.129 151.985 153.129Z" fill="#F0F0F0"/>
<path d="M150.151 42.9721C149.709 44.9524 149.482 47.2866 149.482 49.9379V155.212C149.482 155.99 151.757 155.99 151.757 155.212V49.9379C151.757 47.1544 152.03 44.7412 152.538 42.8047L150.151 42.9721Z" fill="#F0F0F0"/>
<path d="M109.164 138.404L108.025 136.432L149.482 112.494C150.661 111.926 151.433 113.996 150.487 114.542L109.164 138.404Z" fill="#F0F0F0"/>
<path opacity="0.2" d="M108.885 137.921L109.164 138.404L111.161 137.251V134.622L108.885 135.936V137.921Z" fill="black"/>
<path d="M131.009 166.865C131.009 167.777 128.733 167.777 128.733 166.865V125.141H131.009V166.865Z" fill="#F0F0F0"/>
<path d="M145.843 47.8809C145.489 47.8822 145.15 47.9358 144.83 48.0378C144.582 48.1242 144.33 48.2393 144.071 48.3845C143.092 48.9875 142.441 50.0711 142.446 51.3026C142.452 53.1868 143.986 54.706 145.867 54.6995C147.75 54.693 149.27 53.1619 149.264 51.2784C149.257 49.3962 147.727 47.8737 145.843 47.8809Z" fill="#F0F0F0"/>
<path opacity="0.07" d="M147.266 53.4476C147.882 53.0186 147.513 52.2907 146.429 52.1108C145.344 51.931 144.231 52.3025 144.677 53.2658C143.17 52.6543 142.528 50.0004 143.737 48.623C142.949 49.2502 142.443 50.2168 142.447 51.3018C142.454 53.1847 143.985 54.7052 145.868 54.6987C147.608 54.6928 149.037 53.3822 149.238 51.6968C149.115 52.256 148.505 53.2736 147.266 53.4476Z" fill="black"/>
<path opacity="0.3" d="M148.693 50.9309C148.624 51.1794 148.412 51.4011 148.156 51.4194C147.769 51.4482 147.502 51.0656 147.258 50.7654C146.628 49.995 145.658 49.5673 144.679 49.3848C144.559 49.3626 144.433 49.3404 144.325 49.2782C144.22 49.2161 144.134 49.0984 144.149 48.9754C144.162 48.8682 144.25 48.7119 144.337 48.6517C144.569 48.4915 144.834 48.381 145.111 48.328C145.815 48.1926 146.583 48.3319 147.224 48.6478C147.864 48.963 148.389 49.4921 148.629 50.1716C148.714 50.4142 148.763 50.6824 148.693 50.9309Z" fill="white"/>
<path opacity="0.2" d="M147.572 50.6125C147.63 50.7459 147.717 50.8649 147.831 50.9552C147.901 51.0094 147.981 51.0539 148.068 51.0657C148.969 51.1906 148.278 49.1442 147.667 49.7099C147.563 49.8061 147.514 49.9486 147.497 50.0893C147.476 50.2658 147.502 50.4496 147.572 50.6125Z" fill="white"/>
<path d="M180.223 72.4464L147.21 53.3928C146.177 52.7963 147.315 50.8251 148.348 51.4209L181.362 70.4746L180.223 72.4464Z" fill="#F0F0F0"/>
<g opacity="0.3">
<path d="M180.223 72.4464L147.21 53.3928C146.177 52.7963 147.315 50.8251 148.348 51.4209L181.362 70.4746L180.223 72.4464Z" fill="white"/>
</g>
<path d="M165.728 61.4404C165.728 62.4561 163.452 62.4561 163.452 61.4404V52.1294H165.728V61.4404Z" fill="#F0F0F0"/>
<path opacity="0.07" d="M165.728 57.4843V52.1294H163.452V56.1698L165.728 57.4843Z" fill="black"/>
<path d="M180.78 68.0508C180.426 68.0521 180.088 68.1057 179.767 68.2078C179.52 68.2941 179.267 68.4092 179.008 68.5544C178.03 69.1574 177.379 70.241 177.383 71.4725C177.39 73.3567 178.923 74.8759 180.804 74.8694C182.688 74.8629 184.208 73.3319 184.201 71.4483C184.195 69.5668 182.664 68.0443 180.78 68.0508Z" fill="#F0F0F0"/>
<path opacity="0.07" d="M182.204 73.6175C182.82 73.1885 182.45 72.4606 181.366 72.2808C180.282 72.1009 179.169 72.4724 179.615 73.4357C178.107 72.8242 177.465 70.1703 178.674 68.793C177.886 69.4202 177.381 70.3868 177.384 71.4718C177.391 73.3546 178.923 74.8752 180.805 74.8686C182.546 74.8627 183.975 73.5521 184.176 71.8668C184.053 72.4259 183.442 73.4442 182.204 73.6175Z" fill="black"/>
<path opacity="0.3" d="M183.631 71.1015C183.561 71.35 183.349 71.5718 183.093 71.5901C182.707 71.6188 182.44 71.2363 182.195 70.9361C181.565 70.1657 180.596 69.7379 179.617 69.5555C179.496 69.5332 179.371 69.511 179.263 69.4489C179.157 69.3867 179.072 69.269 179.086 69.1461C179.099 69.0388 179.188 68.8825 179.274 68.8223C179.506 68.6621 179.772 68.5516 180.048 68.4986C180.752 68.3639 181.52 68.5025 182.161 68.8184C182.8 69.1336 183.326 69.6627 183.565 70.3422C183.652 70.5842 183.7 70.853 183.631 71.1015Z" fill="white"/>
<path opacity="0.2" d="M182.51 70.7834C182.567 70.9168 182.654 71.0358 182.769 71.1261C182.838 71.1803 182.919 71.2242 183.006 71.2366C183.907 71.3615 183.215 69.3151 182.604 69.8808C182.501 69.977 182.452 70.1196 182.435 70.2602C182.413 70.4361 182.439 70.6205 182.51 70.7834Z" fill="white"/>
<path d="M166.125 56.5151V47.1413L155.777 41.167V50.5414L166.125 56.5151Z" fill="#F0F0F0"/>
<path d="M166.125 56.5148V47.141L173.638 42.8037V52.1775L166.125 56.5148Z" fill="#F0F0F0"/>
<path opacity="0.5" d="M166.125 56.5148V47.141L173.638 42.8037V52.1775L166.125 56.5148Z" fill="white"/>
<path d="M155.777 41.1669L163.29 36.8296L173.638 42.8039L166.125 47.1412L155.777 41.1669Z" fill="#F0F0F0"/>
<path opacity="0.3" d="M155.777 41.1669L163.29 36.8296L173.638 42.8039L166.125 47.1412L155.777 41.1669Z" fill="white"/>
<path d="M164.707 42.5165C161.565 42.5165 157.301 42.5165 152.538 42.8049L150.151 42.9723C144.002 43.4621 137.232 44.4791 131.055 46.5896C116.906 51.4233 109.733 60.714 109.733 74.2041V179.478C109.733 180.251 107.457 180.251 107.457 179.478V74.2047C107.457 47.9479 132.314 41.9893 150.83 40.6375L153.372 40.4766C156.587 40.3046 159.549 40.2575 162.076 40.2451C163.232 40.3563 164.794 42.5165 164.794 42.5165" fill="#F0F0F0"/>
<path opacity="0.07" d="M149.891 44.3431C149.977 44.3359 150.065 44.3267 150.151 44.3195L152.236 44.173C152.326 43.6949 152.425 43.2358 152.538 42.8042L150.151 42.9716C150.053 43.4111 149.967 43.8702 149.891 44.3431Z" fill="black"/>
<path d="M166.494 42.0193C165.578 40.6459 164.519 39.3673 163.287 38.2693C160.395 35.6925 157.566 34.7677 155.139 35.6559C153.242 36.3498 151.789 38.0541 150.829 40.6367L153.372 40.4759C154.04 39.0907 154.9 38.1666 155.921 37.7931C156.263 37.6682 156.626 37.6068 157.008 37.6068C158.482 37.6068 160.234 38.5204 162.076 40.2443L166.803 42.4836L166.494 42.0193Z" fill="#F0F0F0"/>
<path d="M166.289 30.958H162.892V40.9367H166.289V30.958Z" fill="#F0F0F0"/>
<path d="M164.573 20.1011C163.899 20.1037 163.254 20.2064 162.643 20.3993C162.172 20.5641 161.691 20.7826 161.197 21.0592C159.332 22.2083 158.091 24.273 158.1 26.6202C158.113 30.21 161.034 33.1066 164.62 33.0941C168.209 33.0817 171.106 30.1649 171.093 26.5744C171.08 22.9892 168.163 20.0887 164.573 20.1011Z" fill="#F0F0F0"/>
<path opacity="0.07" d="M167.285 30.7097C168.459 29.8916 167.756 28.5051 165.69 28.1624C163.623 27.8197 161.503 28.5273 162.352 30.3637C159.479 29.1983 158.255 24.1409 160.559 21.5171C159.057 22.7119 158.094 24.5536 158.101 26.6222C158.114 30.2101 161.032 33.1079 164.62 33.0949C167.937 33.0831 170.659 30.5855 171.042 27.3743C170.809 28.439 169.646 30.3788 167.285 30.7097Z" fill="black"/>
<path opacity="0.3" d="M170.005 25.9147C169.873 26.3882 169.468 26.8106 168.981 26.846C168.245 26.9009 167.736 26.1717 167.27 25.5994C166.07 24.1312 164.222 23.3157 162.356 22.9684C162.127 22.9265 161.887 22.884 161.681 22.765C161.48 22.646 161.317 22.4223 161.344 22.1875C161.369 21.9828 161.537 21.6852 161.703 21.5701C162.145 21.2654 162.65 21.0541 163.178 20.9528C164.518 20.6951 165.983 20.96 167.204 21.5623C168.423 22.1633 169.425 23.1718 169.881 24.4661C170.045 24.9297 170.137 25.4405 170.005 25.9147Z" fill="white"/>
<path opacity="0.2" d="M167.869 25.3074C167.979 25.5618 168.145 25.7888 168.362 25.9601C168.495 26.0641 168.647 26.1478 168.814 26.1707C170.531 26.4088 169.213 22.509 168.049 23.5874C167.852 23.7705 167.759 24.0426 167.726 24.3094C167.684 24.6462 167.735 24.9968 167.869 25.3074Z" fill="white"/>
<path d="M176.193 175.91L175.054 173.939L216.51 150C217.479 149.455 218.325 151.561 217.087 152.296L176.193 175.91Z" fill="#F0F0F0"/>
<path opacity="0.2" d="M176.193 175.911L178.189 174.758V172.128L175.914 173.443V175.427L176.193 175.911Z" fill="black"/>
<path d="M176.761 216.984C176.761 217.719 174.485 217.719 174.485 216.984V109.511C174.485 74.1767 172.882 55.0661 163.775 42.0306L162.572 40.3093L164.671 40.2413C180.488 39.7239 195.488 45.1757 205.83 55.1884C214.307 63.3942 218.787 73.7869 218.787 85.245V192.719C218.787 193.547 216.511 193.547 216.511 192.719V85.2443C216.511 74.418 212.27 64.5897 204.247 56.8234C194.794 47.672 181.242 42.5041 166.805 42.4845C175.237 55.9425 176.762 75.2133 176.762 109.511V216.984H176.761Z" fill="#F0F0F0"/>
<path d="M197.774 204.851C197.774 205.564 195.498 205.564 195.498 204.851V163.126H197.774V204.851Z" fill="#F0F0F0"/>
<path d="M265.134 243.298L268.918 242.276L267.909 239.775L265.482 241.613L265.134 243.298Z" fill="black"/>
<path d="M261.848 236.584C261.848 236.584 261.516 238.879 261.425 239.603C261.333 240.327 264.366 242.858 265.134 243.297L265.482 241.612C266.115 242.336 266.461 243.215 267.102 244.521C267.637 245.61 268.268 247.637 269.862 249.116C272.227 251.313 276.083 252.652 280.087 253.132C284.09 253.611 286.466 252.855 287.675 252.112C288.884 251.37 289.325 250.419 289.119 249.411C288.914 248.405 261.848 236.584 261.848 236.584Z" fill="#263238"/>
<path d="M273.223 229.619L273.379 228.285C274.121 228.326 274.054 230.271 274.997 232.063C276.265 234.473 278.17 237.518 280.087 239.505C283.87 243.424 287.322 245.976 289.009 247.74C291.267 250.099 286.719 253.161 280.195 252.254C276.956 251.804 271.305 249.74 269.831 247.75C268.356 245.76 267.591 242.79 266.4 241.334C265.208 239.878 262.526 238.286 261.847 236.584C261.475 235.649 261.659 234.394 261.907 231.899C262.07 230.249 262.004 227.567 262.943 227.851V228.697C263.276 229.445 264.998 230.837 267.586 231.485C268.879 231.808 272.776 232.252 273.223 229.619Z" fill="#37474F"/>
<path opacity="0.15" d="M269.687 231.729C270.947 231.731 272.326 231.445 272.938 230.402C272.946 230.389 272.957 230.381 272.964 230.368C272.964 230.368 272.537 231.592 273.602 233.012C274.668 234.433 281.32 244.376 281.32 244.376C281.32 244.376 275.049 236.108 273.844 234.549C272.063 232.244 271.463 231.942 269.68 231.731C269.683 231.73 269.685 231.73 269.687 231.729Z" fill="black"/>
<path opacity="0.15" d="M271.124 234.337C271.083 234.547 270.872 234.683 270.654 234.641C270.436 234.599 270.293 234.395 270.334 234.185C270.375 233.975 270.586 233.839 270.804 233.881C271.022 233.922 271.165 234.127 271.124 234.337Z" fill="black"/>
<path opacity="0.15" d="M272.892 236.561C272.851 236.771 272.64 236.907 272.422 236.865C272.204 236.823 272.061 236.618 272.102 236.408C272.143 236.198 272.354 236.062 272.572 236.104C272.791 236.146 272.934 236.35 272.892 236.561Z" fill="black"/>
<path opacity="0.15" d="M274.66 238.784C274.619 238.994 274.409 239.13 274.191 239.088C273.973 239.047 273.83 238.842 273.871 238.632C273.912 238.422 274.123 238.286 274.341 238.328C274.558 238.37 274.702 238.574 274.66 238.784Z" fill="black"/>
<path opacity="0.15" d="M276.428 241.008C276.386 241.218 276.176 241.354 275.958 241.312C275.74 241.27 275.597 241.066 275.638 240.856C275.679 240.646 275.89 240.51 276.108 240.552C276.327 240.593 276.47 240.798 276.428 241.008Z" fill="black"/>
<path opacity="0.15" d="M278.197 243.232C278.155 243.442 277.945 243.578 277.727 243.536C277.509 243.494 277.366 243.29 277.407 243.079C277.448 242.869 277.659 242.733 277.877 242.775C278.095 242.817 278.238 243.021 278.197 243.232Z" fill="black"/>
<path d="M270.704 234.414C270.666 234.408 270.631 234.389 270.605 234.359C270.547 234.293 270.557 234.195 270.625 234.14C270.712 234.07 272.784 232.441 275.295 232.794C275.295 232.794 275.297 232.794 275.298 232.795C275.385 232.809 275.445 232.887 275.433 232.972C275.42 233.057 275.339 233.115 275.25 233.103C272.88 232.77 270.852 234.365 270.832 234.38C270.795 234.411 270.748 234.422 270.704 234.414Z" fill="#263238"/>
<path d="M272.473 236.632C272.436 236.626 272.401 236.607 272.375 236.577C272.317 236.51 272.326 236.411 272.394 236.357C272.481 236.287 274.553 234.658 277.064 235.012H277.067C277.154 235.026 277.214 235.105 277.202 235.189C277.19 235.274 277.108 235.333 277.019 235.32C274.653 234.987 272.621 236.582 272.601 236.598C272.565 236.628 272.518 236.639 272.473 236.632Z" fill="#263238"/>
<path d="M274.24 238.862C274.203 238.856 274.167 238.837 274.141 238.807C274.084 238.74 274.093 238.642 274.161 238.587C274.248 238.517 276.32 236.888 278.831 237.242C278.831 237.242 278.833 237.242 278.834 237.242C278.921 237.257 278.981 237.334 278.969 237.419C278.957 237.504 278.875 237.563 278.786 237.55C276.42 237.217 274.388 238.812 274.368 238.828C274.331 238.858 274.284 238.869 274.24 238.862Z" fill="#263238"/>
<path d="M276.008 241.085C275.971 241.079 275.936 241.06 275.91 241.03C275.852 240.964 275.861 240.866 275.929 240.811C276.016 240.741 278.088 239.111 280.599 239.465C280.599 239.465 280.601 239.465 280.603 239.466C280.69 239.48 280.75 239.558 280.738 239.642C280.726 239.727 280.644 239.786 280.556 239.774C278.189 239.44 276.157 241.036 276.137 241.052C276.1 241.082 276.052 241.093 276.008 241.085Z" fill="#263238"/>
<path d="M277.777 243.309C277.739 243.303 277.704 243.284 277.678 243.254C277.62 243.187 277.63 243.089 277.698 243.035C277.785 242.965 279.856 241.336 282.368 241.689C282.368 241.689 282.37 241.689 282.371 241.69C282.458 241.704 282.518 241.782 282.506 241.867C282.495 241.952 282.413 242.011 282.324 241.998C279.958 241.665 277.926 243.26 277.906 243.275C277.868 243.305 277.821 243.316 277.777 243.309Z" fill="#263238"/>
<path d="M241.406 253.889C241.058 256.948 240.456 258.96 239.231 260.707C238.006 262.454 235.61 262.807 233.797 262.567C231.249 262.23 228.657 260.715 227.453 257.315C226.729 255.269 226.96 252.855 227.6 250.032L241.406 253.889Z" fill="#263238"/>
<path d="M232.797 231.156C231.904 231.182 231.423 235.29 230.692 239.391C229.908 243.788 228.374 246.195 227.759 249.155C226.907 253.258 227.106 255.132 228.479 257.705C229.851 260.28 236.252 263.1 239.068 259.635C241.365 256.81 241.625 253.571 241.649 249.427C241.674 245.143 241.656 242.127 242.009 238.891C242.347 235.798 242.728 232.802 241.974 232.422C241.89 232.874 241.802 233.45 241.715 233.894C241.648 234.235 241.471 235.91 240.972 235.903C240.947 235.505 240.974 235.136 241.023 234.734C241.066 234.384 241.055 234 240.832 233.723C240.608 233.442 240.225 233.337 239.868 233.252C239.002 233.044 238.13 232.854 237.244 232.736C236.804 232.677 236.36 232.636 235.915 232.62C235.554 232.607 233.928 232.5 233.58 232.605C233.366 232.67 233.165 232.806 233.076 233.004C233.011 233.15 233.013 233.315 233.005 233.475C232.99 233.761 232.937 234.046 232.849 234.32C232.81 234.441 232.714 234.586 232.589 234.55C232.487 234.521 232.453 234.395 232.457 234.293C232.48 233.793 232.565 233.432 232.62 232.852C232.645 232.584 232.71 232.22 232.728 231.95C232.747 231.685 232.764 231.42 232.797 231.156Z" fill="#37474F"/>
<path opacity="0.15" d="M230.964 250.329C230.938 250.494 231.055 250.646 231.225 250.671C231.395 250.695 231.554 250.583 231.579 250.418C231.606 250.254 231.489 250.102 231.318 250.078C231.148 250.053 230.99 250.165 230.964 250.329Z" fill="black"/>
<path opacity="0.15" d="M231.414 247.485C231.388 247.649 231.505 247.802 231.675 247.826C231.845 247.851 232.004 247.738 232.03 247.574C232.056 247.409 231.939 247.257 231.769 247.232C231.598 247.208 231.439 247.321 231.414 247.485Z" fill="black"/>
<path opacity="0.15" d="M231.838 244.801C231.812 244.965 231.929 245.117 232.099 245.141C232.269 245.166 232.428 245.053 232.453 244.889C232.479 244.725 232.362 244.572 232.192 244.548C232.023 244.524 231.864 244.637 231.838 244.801Z" fill="black"/>
<path opacity="0.15" d="M232.256 242.154C232.231 242.318 232.347 242.471 232.517 242.496C232.687 242.52 232.846 242.407 232.872 242.243C232.898 242.08 232.781 241.927 232.611 241.902C232.441 241.877 232.282 241.991 232.256 242.154Z" fill="black"/>
<path opacity="0.15" d="M239.27 242.903C239.244 243.066 239.361 243.219 239.531 243.244C239.701 243.268 239.86 243.156 239.886 242.992C239.912 242.828 239.795 242.675 239.625 242.65C239.455 242.626 239.297 242.739 239.27 242.903Z" fill="black"/>
<path opacity="0.15" d="M238.849 245.568C238.823 245.732 238.94 245.884 239.11 245.909C239.28 245.934 239.439 245.821 239.465 245.657C239.491 245.493 239.374 245.34 239.204 245.315C239.034 245.291 238.875 245.404 238.849 245.568Z" fill="black"/>
<path opacity="0.15" d="M238.425 248.252C238.399 248.416 238.516 248.569 238.686 248.593C238.856 248.618 239.015 248.505 239.04 248.341C239.066 248.176 238.949 248.024 238.779 247.999C238.609 247.976 238.45 248.089 238.425 248.252Z" fill="black"/>
<path opacity="0.15" d="M237.975 251.098C237.949 251.263 238.066 251.415 238.236 251.44C238.406 251.465 238.565 251.352 238.591 251.187C238.617 251.023 238.5 250.871 238.33 250.847C238.159 250.822 238 250.934 237.975 251.098Z" fill="black"/>
<path opacity="0.15" d="M235.287 252.326L238.734 232.49L237.588 232.415L235.287 252.326Z" fill="black"/>
<path d="M231.25 250.525C231.266 250.527 231.282 250.527 231.298 250.524C233.375 250.181 236.87 250.546 238.209 251.256C238.285 251.299 238.381 251.27 238.424 251.196C238.466 251.122 238.438 251.03 238.362 250.989C236.943 250.236 233.419 249.864 231.246 250.224C231.16 250.239 231.101 250.317 231.116 250.399C231.128 250.467 231.184 250.516 231.25 250.525Z" fill="#263238"/>
<path d="M231.7 247.68C231.727 247.684 231.755 247.681 231.782 247.67C233.387 247.027 237.129 247.601 238.66 248.412C238.736 248.454 238.832 248.426 238.874 248.352C238.917 248.278 238.889 248.186 238.812 248.145C237.216 247.298 233.354 246.71 231.661 247.389C231.58 247.422 231.542 247.51 231.576 247.588C231.598 247.639 231.645 247.673 231.7 247.68Z" fill="#263238"/>
<path d="M232.124 244.996C232.152 244.999 232.179 244.997 232.206 244.985C233.812 244.344 237.554 244.916 239.083 245.728C239.16 245.77 239.255 245.741 239.298 245.668C239.341 245.594 239.313 245.501 239.236 245.461C237.643 244.613 233.78 244.027 232.086 244.705C232.005 244.737 231.967 244.826 232.001 244.904C232.022 244.955 232.071 244.989 232.124 244.996Z" fill="#263238"/>
<path d="M232.542 242.349C232.569 242.353 232.597 242.35 232.624 242.339C234.229 241.697 237.972 242.27 239.501 243.081C239.577 243.124 239.673 243.095 239.716 243.021C239.758 242.947 239.731 242.855 239.654 242.814C238.059 241.968 234.197 241.379 232.504 242.058C232.422 242.09 232.385 242.179 232.418 242.257C232.44 242.309 232.488 242.342 232.542 242.349Z" fill="#263238"/>
<path d="M238.939 129.064C234.248 153.807 233.752 191.27 233.752 191.27C233.507 193.177 231.657 196.956 231.104 206.425C230.43 217.98 231.132 234.368 231.132 234.368C231.132 234.368 236.94 237.725 242.852 236.032C242.852 236.032 249.679 213.742 252.315 199.264C254.367 187.989 257.954 166.914 257.954 166.914L258.994 188.337C258.994 188.337 258.365 195.503 258.428 200.532C258.501 206.382 261.772 230.245 261.772 230.245C261.772 230.245 268.52 233.83 274.91 230.822C274.91 230.822 277.694 196.315 278.086 189.293C278.854 175.519 278.605 149.617 277.586 130.172L238.939 129.064Z" fill="#263238"/>
<path d="M275.431 227.425C275.431 227.425 273.723 228.678 269.915 228.882C266.107 229.087 261.045 227.021 261.045 227.021L261.544 230.884C261.544 230.884 265.086 232.929 269.759 232.608C274.433 232.287 275.169 231.319 275.169 231.319L275.431 227.425Z" fill="#455A64"/>
<path d="M230.857 231.188C230.857 231.188 233.596 232.771 237.387 233.189C241.178 233.608 243.99 232.595 243.99 232.595L243.274 236.423C243.274 236.423 241.224 237.727 236.576 237.143C231.928 236.56 230.899 235.09 230.899 235.09L230.857 231.188Z" fill="#455A64"/>
<path d="M248.092 160.667C247.712 166.065 247.266 171.461 246.764 176.853C246.519 179.549 246.258 182.245 245.986 184.94L245.579 188.982L245.366 191.003C245.301 191.676 245.162 192.344 245.064 193.015C244.198 198.377 242.91 208.105 241.95 213.457C240.995 218.81 239.958 224.153 238.843 229.487C239.492 224.103 240.219 218.73 241.028 213.366C241.832 208.002 243.048 198.271 243.945 192.916C244.048 192.246 244.192 191.58 244.261 190.907L244.485 188.888L244.952 184.854C245.263 182.164 245.585 179.475 245.923 176.787C246.587 171.412 247.306 166.038 248.092 160.667Z" fill="black"/>
<path d="M273.032 162.052C273.615 166.848 273.889 171.669 274.067 176.485C274.222 181.295 274.401 186.137 274.107 190.951C273.893 195.769 273.615 203.844 273.321 208.66C273.042 213.476 272.69 218.29 272.264 223.101C272.246 218.279 272.301 213.461 272.43 208.644C272.542 203.827 272.772 195.754 272.965 190.938L273.131 187.327L273.172 186.425L273.173 185.53L273.176 183.724L273.178 176.5L273.144 169.277C273.149 166.87 273.091 164.463 273.032 162.052Z" fill="black"/>
<path d="M257.955 166.915L260.941 153.203C260.941 153.203 268.067 152.435 271.802 149.406C271.802 149.406 269.326 153.49 262.584 154.905L259.664 167.009L258.995 188.338L257.955 166.915Z" fill="black"/>
<path d="M270.173 87.6934C273.452 88.5259 274.749 89.2963 277.13 92.5631C281.824 99.0063 286.994 106.111 286.994 106.111C286.994 106.111 290.079 101.989 291.415 100.163C293.833 96.8553 294.781 95.181 295.589 93.4028C296.397 91.6252 296.634 90.0517 297.175 88.7843C297.645 87.6829 298.372 86.6718 299.028 85.6725C299.72 84.6189 300.442 83.5863 301.128 82.5288C301.301 82.2626 301.609 82.3482 301.841 82.5124C302.271 82.8185 302.493 83.3554 302.523 83.8825C302.553 84.4097 302.412 84.9309 302.236 85.4286C302.079 85.874 301.893 86.3089 301.679 86.73C301.578 86.9302 300.946 87.7908 301.528 87.7601C301.962 87.7372 302.325 87.5574 302.697 87.3533C305.351 85.8936 307.752 81.9572 308.753 80.6125C309.753 79.2673 310.406 79.6819 310.755 80.3758C310.871 80.6073 310.797 81.071 310.625 81.6334C311.146 80.8604 311.701 80.2921 312.295 80.2966C313.044 80.3025 312.812 81.3993 312.672 81.8695C312.499 82.445 312.314 83.1147 312.314 83.1147C312.631 83.0768 312.966 83.5071 313.058 83.6733C313.155 83.8498 313.184 84.1441 313.169 84.3456C313.07 85.6856 312.267 87.0178 311.694 87.9275C312.348 87.3088 312.543 86.9426 312.938 86.7608C313.216 86.6333 313.503 86.6516 313.7 86.885C313.933 87.1617 313.314 88.556 313.15 88.8791C311.815 91.4957 311.741 91.3911 310.493 92.6075C308.897 94.1634 306.501 96.3327 304.494 97.3327C302.819 98.1672 301.592 99.5256 300.983 101.059C296.446 112.475 294.736 119.309 289.845 121.305C288.277 121.922 284.44 122.02 280.921 118.007C277.625 114.249 270.638 105.387 270.638 105.387C270.638 105.387 270.296 92.3041 270.173 87.6934Z" fill="#FFBDA7"/>
<path d="M311.694 87.9277C311.164 88.5641 310.626 89.1631 310.017 89.7001C309.432 90.2625 308.78 90.7537 308.18 91.3285C308.925 90.9603 309.598 90.4744 310.223 89.9231C310.821 89.3508 311.375 88.6995 311.694 87.9277Z" fill="#F0997A"/>
<path d="M305.381 87.9088C306.379 87.0233 307.262 85.9527 308.118 84.9305C308.939 83.9501 309.693 82.9057 310.455 81.8815C310.464 81.8691 310.494 81.892 310.487 81.9044C309.878 83.164 309.085 84.2765 308.177 85.3412C307.341 86.3209 306.381 87.0998 305.403 87.9277C305.393 87.9349 305.372 87.9166 305.381 87.9088Z" fill="#F0997A"/>
<path d="M306.372 89.9465C307.369 89.0603 308.252 87.9904 309.109 86.9682C309.438 86.5751 309.755 86.1729 310.067 85.7661C310.372 85.3672 310.696 84.9715 310.958 84.5432C311.039 84.411 311.12 84.2789 311.201 84.1468C311.305 83.9768 311.387 83.8028 311.52 83.6518C311.657 83.4974 311.821 83.3686 312.004 83.2737C312.021 83.2646 312.052 83.2639 312.051 83.2836C311.842 83.4366 311.674 83.6413 311.537 83.861C311.445 84.0082 311.366 84.1625 311.292 84.3188C311.224 84.4607 311.146 84.5844 311.069 84.7197C310.83 85.1396 310.569 85.547 310.29 85.9421C309.94 86.4385 309.563 86.9159 309.169 87.3782C308.333 88.3579 307.373 89.1369 306.394 89.9648C306.385 89.9727 306.362 89.9544 306.372 89.9465Z" fill="#F0997A"/>
<path d="M299.043 95.2051C299.013 95.0573 298.997 94.9055 299.004 94.7551C299.017 94.4647 299.093 94.1672 299.193 93.8951C299.394 93.3523 299.731 92.8526 300.154 92.4576C301.067 91.6028 302.516 91.2078 303.72 91.5983C303.754 91.6094 303.818 91.7127 303.752 91.7179C302.561 91.8154 301.382 91.971 300.465 92.8206C299.706 93.523 299.41 94.604 299.378 95.6138C299.376 95.7086 299.276 95.7073 299.238 95.6328C299.159 95.4791 299.079 95.3777 299.043 95.2051Z" fill="#F0997A"/>
<path d="M286.994 106.111C284.876 108.384 284.372 111.435 285.06 114.575C283.61 111.82 282.663 108.92 285.901 105.251L286.994 106.111Z" fill="#F0997A"/>
<path d="M270.03 105.252L269.765 87.2476C276.032 88.7655 276.399 89.8596 279.531 94.6057C282.022 98.3806 286.973 105.86 286.973 105.86L293.123 97.4643C293.123 97.4643 293.74 100.152 295.881 101.506C298.023 102.86 300.535 102.829 300.535 102.829C300.535 102.829 298.448 108.614 296.775 112.482C295.1 116.35 292.989 120.756 289.305 122.267C286.474 123.427 283.167 121.006 280.999 118.568C277.629 114.777 270.03 105.252 270.03 105.252Z" fill="#F5F5F5"/>
<path d="M286.973 105.86C284.438 109.217 284.119 112.667 284.491 115.214C282.174 111.201 284.332 106.635 286.163 104.635L286.973 105.86Z" fill="#E0E0E0"/>
<path d="M292.101 98.8116L293.67 96.3813C293.67 96.3813 294.197 98.397 296.15 100.016C298.102 101.636 301.085 101.596 301.085 101.596L300.217 104.01C300.217 104.01 297.478 104.514 295.062 102.658C292.647 100.803 292.101 98.8116 292.101 98.8116Z" fill="#E0E0E0"/>
<path d="M278.134 85.1028H278.141C278.14 82.0186 280.166 78.935 284.242 76.5819C292.394 71.8757 305.609 71.8757 313.76 76.5819C318.034 79.0488 320.058 82.3194 319.851 85.5508V85.5829C320.058 88.8143 318.034 92.0843 313.76 94.5512C305.609 99.2574 292.394 99.2574 284.242 94.5512C280.166 92.1981 278.14 90.1681 278.141 87.0838L278.134 86.0302V85.1028Z" fill="#37474F"/>
<path d="M299.002 97.1535C310.529 97.1535 319.874 91.7582 319.874 85.1029C319.874 78.4475 310.529 73.0522 299.002 73.0522C287.474 73.0522 278.129 78.4475 278.129 85.1029C278.129 91.7582 287.474 97.1535 299.002 97.1535Z" fill="#455A64"/>
<path d="M285.251 92.9927C281.527 90.898 279.476 88.0956 279.476 85.1029C279.476 82.1102 281.527 79.3085 285.251 77.2137C292.833 72.9483 305.17 72.9483 312.752 77.2137C316.476 79.3085 318.527 82.1108 318.527 85.1029C318.527 88.0956 316.476 90.898 312.752 92.9927C308.961 95.1254 303.981 96.1915 299.002 96.1915C294.023 96.1915 289.042 95.1254 285.251 92.9927ZM312.498 92.5402C316.051 90.5409 318.008 87.9001 318.008 85.1035C318.008 82.307 316.051 79.6655 312.498 77.6669C305.056 73.4813 292.948 73.4807 285.506 77.6669C281.953 79.6655 279.996 82.3064 279.996 85.1029C279.996 87.8994 281.953 90.5409 285.506 92.5395C292.948 96.7258 305.056 96.7258 312.498 92.5402Z" fill="#37474F"/>
<path d="M297.39 82.926C300.311 84.6127 300.311 87.3464 297.39 89.0331C294.469 90.7191 289.734 90.7191 286.813 89.0331C283.892 87.3471 283.892 84.6127 286.813 82.9267C289.734 81.2394 294.469 81.2394 297.39 82.926Z" fill="#37474F"/>
<path d="M310.028 86.7816C311.967 87.9006 311.967 89.7148 310.028 90.8345C308.09 91.9535 304.947 91.9535 303.009 90.8345C301.07 89.7155 301.07 87.9006 303.009 86.7816C304.947 85.6626 308.09 85.6626 310.028 86.7816Z" fill="#37474F"/>
<path d="M290.296 52.751V47.1724L292.101 47.2463L293.906 47.1724V52.751C293.906 54.9752 294.694 57.1393 296.182 58.792C297.093 59.8024 297.938 61.4374 297.979 64.0331V85.3065C297.968 86.5726 296.736 87.861 294.286 88.4594C292.853 88.8093 291.346 88.8093 289.913 88.4587C287.464 87.8597 286.234 86.5719 286.222 85.3065V64.0331C286.264 61.4374 287.109 59.8024 288.02 58.792C289.508 57.1393 290.296 54.9746 290.296 52.751Z" fill="#4F7065"/>
<path opacity="0.5" d="M290.296 52.751V47.1724L292.101 47.2463L293.906 47.1724V52.751C293.906 54.9752 294.694 57.1393 296.182 58.792C297.093 59.8024 297.938 61.4374 297.979 64.0331V85.3065C297.968 86.5726 296.736 87.861 294.286 88.4594C292.853 88.8093 291.346 88.8093 289.913 88.4587C287.464 87.8597 286.234 86.5719 286.222 85.3065V64.0331C286.264 61.4374 287.109 59.8024 288.02 58.792C289.508 57.1393 290.296 54.9746 290.296 52.751Z" fill="black"/>
<path opacity="0.2" d="M297.315 62.7506C297.202 62.0763 296.699 61.4216 295.806 60.9056C293.759 59.7258 290.444 59.7258 288.398 60.9056C287.47 61.4413 286.963 62.128 286.877 62.8271C287.072 61.3379 287.598 60.1104 288.442 59.1719C290.003 57.4381 290.864 55.157 290.864 52.7516V47.7642L292.102 47.8152L293.34 47.7642V52.7516C293.34 55.157 294.2 57.4381 295.762 59.1719C296.591 60.0927 297.112 61.2935 297.315 62.7506Z" fill="#4F7065"/>
<path d="M297.412 64.0415V85.3056C297.402 86.403 296.122 87.4265 294.152 87.9079C293.484 88.0714 292.793 88.1544 292.102 88.1544C291.409 88.1544 290.719 88.0714 290.049 87.9079C288.079 87.4259 286.8 86.403 286.79 85.301V64.0323C286.797 63.6137 286.826 63.2109 286.877 62.8263C286.963 62.1272 287.471 61.4405 288.398 60.9049C290.443 59.7251 293.759 59.7251 295.806 60.9049C296.699 61.4215 297.201 62.0762 297.315 62.7498C297.315 62.7511 297.315 62.7531 297.316 62.7557C297.323 62.7976 297.329 62.8407 297.333 62.8839C297.333 62.8898 297.333 62.8944 297.335 62.8989C297.38 63.2645 297.406 63.6458 297.412 64.0415Z" fill="#4F7065"/>
<path opacity="0.3" d="M297.412 64.0415V85.3056C297.402 86.403 296.122 87.4265 294.152 87.9079C293.484 88.0714 292.793 88.1544 292.102 88.1544C291.409 88.1544 290.719 88.0714 290.049 87.9079C288.079 87.4259 286.8 86.403 286.79 85.301V64.0323C286.797 63.6137 286.826 63.2109 286.877 62.8263C286.963 62.1272 287.471 61.4405 288.398 60.9049C290.443 59.7251 293.759 59.7251 295.806 60.9049C296.699 61.4215 297.201 62.0762 297.315 62.7498C297.315 62.7511 297.315 62.7531 297.316 62.7557C297.323 62.7976 297.329 62.8407 297.333 62.8839C297.333 62.8898 297.333 62.8944 297.335 62.8989C297.38 63.2645 297.406 63.6458 297.412 64.0415Z" fill="black"/>
<path opacity="0.2" d="M295.805 65.182C293.759 66.3632 290.444 66.3632 288.398 65.182C287.279 64.5365 286.772 63.6713 286.877 62.8263C286.963 62.1272 287.471 61.4405 288.398 60.9049C290.443 59.7251 293.759 59.7251 295.805 60.9049C296.699 61.4215 297.201 62.0762 297.315 62.7498C297.315 62.7511 297.315 62.7531 297.316 62.7557C297.323 62.7976 297.329 62.8407 297.333 62.8839C297.333 62.8898 297.333 62.8944 297.335 62.8989C297.401 63.7204 296.892 64.5555 295.805 65.182Z" fill="#4F7065"/>
<path opacity="0.2" d="M295.727 86.9988C293.724 88.155 290.479 88.155 288.476 86.9988C287.381 86.367 286.884 85.5201 286.988 84.6928C287.072 84.0087 287.568 83.3364 288.476 82.8125C290.479 81.6575 293.724 81.6575 295.727 82.8125C296.601 83.3181 297.093 83.959 297.204 84.6189C297.204 84.6202 297.204 84.6221 297.206 84.6241C297.212 84.6653 297.217 84.7072 297.222 84.7497C297.222 84.7555 297.222 84.7595 297.223 84.764C297.289 85.5678 296.79 86.3853 295.727 86.9988Z" fill="#4F7065"/>
<path opacity="0.2" d="M292.362 88.6741C289.885 88.6741 288.145 86.8697 288.145 85.5473C288.145 84.2256 288.145 75.116 288.145 68.7931C288.145 63.8345 288.061 62.3957 290.016 59.0125C291.734 56.0401 291.691 54.7655 291.656 47.2275L290.298 47.1719V52.7505C290.298 54.9747 289.509 57.1388 288.022 58.7915C287.111 59.8019 286.266 61.4369 286.224 64.0326V85.306C286.236 86.5715 287.466 87.8592 289.915 88.4582C290.717 88.6544 291.541 88.7408 292.364 88.7172V88.6741H292.362Z" fill="black"/>
<path opacity="0.3" d="M292.958 53.3697C292.958 52.4874 292.958 49.5686 292.958 47.2109L292.362 47.2351C292.362 50.9237 292.362 52.6398 292.362 53.8706C292.362 55.3964 292.958 57.5448 293.732 58.8992C294.506 60.253 295.571 61.3452 295.571 65.2162C295.571 69.0872 295.571 82.54 295.571 84.4739C295.571 85.4673 295.571 86.8898 295.571 88.0388C296.021 87.8479 296.408 87.6288 296.732 87.3888C296.732 85.2777 296.732 79.686 296.732 76.6507C296.732 71.3599 296.732 66.175 296.732 64.3654C296.732 62.5551 295.894 60.5898 294.547 58.6553C293.2 56.7221 292.958 54.9229 292.958 53.3697Z" fill="#4F7065"/>
<path d="M290.173 46.4713C290.173 46.4536 290.18 46.4386 290.182 46.4216C290.162 46.1227 290.344 45.8205 290.74 45.5923C291.492 45.1574 292.713 45.1574 293.466 45.5923C293.719 45.7388 293.88 45.916 293.963 46.1031C293.974 46.122 293.984 46.1404 293.992 46.16C293.997 46.1724 293.997 46.1855 294 46.1979C294.014 46.2398 294.029 46.2803 294.029 46.3254V46.3725C294.029 46.3764 294.029 46.381 294.029 46.3849V49.2383C294.032 49.5255 293.845 49.8126 293.465 50.0316C292.713 50.4666 291.492 50.4666 290.739 50.0316C290.415 49.8446 290.239 49.6072 290.194 49.3633C290.187 49.343 290.172 49.3234 290.172 49.3018V46.4713H290.173Z" fill="#37474F"/>
<path d="M293.784 49.7878C293.415 50.0978 292.751 50.2273 292.286 50.1495C291.817 50.0717 291.369 49.8565 291.369 49.2908C291.369 48.8192 291.369 46.7742 291.369 45.3491C291.14 45.4034 290.925 45.4838 290.74 45.5911C290.345 45.8193 290.162 46.1215 290.182 46.4204C290.18 46.4374 290.173 46.4524 290.173 46.4701V49.3012C290.173 49.3228 290.187 49.3424 290.195 49.3627C290.24 49.6067 290.416 49.8441 290.74 50.0311C291.492 50.466 292.713 50.466 293.466 50.0311C293.594 49.9579 293.699 49.8755 293.784 49.7878Z" fill="#263238"/>
<path d="M290.74 47.1665C291.492 47.6014 292.713 47.6014 293.466 47.1665C294.219 46.7316 294.219 46.0272 293.466 45.5923C292.713 45.1574 291.492 45.1574 290.74 45.5923C289.986 46.0272 289.986 46.7316 290.74 47.1665Z" fill="#455A64"/>
<path d="M294.287 70.0289C292.853 70.3788 291.347 70.3788 289.914 70.0283C287.465 69.4292 286.235 68.1415 286.223 66.876V77.6042C286.235 78.8697 287.465 80.1574 289.914 80.7565C291.347 81.107 292.853 81.107 294.287 80.7571C296.737 80.1587 297.968 78.8703 297.98 77.6042V66.876C297.968 68.1415 296.737 69.4298 294.287 70.0289Z" fill="white"/>
<path d="M288.144 80.107C288.144 77.41 288.144 73.3663 288.144 69.3788C286.872 68.7098 286.231 67.787 286.222 66.876V77.6042C286.232 78.5152 286.872 79.438 288.144 80.107Z" fill="#E0E0E0"/>
<path d="M294.756 70.754C294.756 72.3707 293.656 73.6813 292.298 73.6813C290.941 73.6813 289.841 72.3707 289.841 70.754C289.841 69.1373 290.941 67.8267 292.298 67.8267C293.655 67.826 294.756 69.1366 294.756 70.754Z" fill="#4F7065"/>
<path d="M292.121 73.1157C291.835 73.0804 291.56 72.9731 291.324 72.8096C291.088 72.6461 290.888 72.431 290.734 72.187C290.419 71.7018 290.288 71.1079 290.325 70.5291C290.343 70.2394 290.404 69.9503 290.514 69.6796C290.619 69.4069 290.774 69.1531 290.969 68.9314C290.755 69.1355 290.587 69.3872 290.462 69.658C290.34 69.9301 290.266 70.2244 290.237 70.5226C290.181 71.1184 290.316 71.7371 290.639 72.2485C290.803 72.5016 291.015 72.7259 291.267 72.8946C291.517 73.0634 291.81 73.1772 292.112 73.2014C292.413 73.2347 292.809 73.1765 293.066 73.0255C293.325 72.8744 293.473 72.7521 293.666 72.5284C293.456 72.7351 293.301 72.8469 293.04 72.9777C292.782 73.1137 292.407 73.1576 292.121 73.1157ZM294.367 70.5174C294.359 70.3696 294.344 70.2224 294.318 70.0759C294.266 69.7842 294.174 69.4958 294.023 69.2355C293.871 68.9772 293.658 68.7522 293.403 68.5926C293.149 68.4324 292.856 68.3369 292.559 68.3108C292.411 68.297 292.261 68.2957 292.113 68.3134C291.965 68.3317 291.82 68.369 291.682 68.4233C291.407 68.5344 291.163 68.713 290.969 68.9308C291.386 68.5214 291.979 68.3049 292.549 68.3971C292.832 68.4357 293.11 68.5233 293.348 68.679C293.586 68.8327 293.784 69.0459 293.925 69.2924C294.216 69.7855 294.294 70.3826 294.273 70.9542C294.258 71.2413 294.199 71.5271 294.095 71.7966C293.994 72.068 293.855 72.3034 293.665 72.5264C294.081 72.1118 294.312 71.55 294.36 70.9601C294.375 70.813 294.375 70.6652 294.367 70.5174Z" fill="#4F7065"/>
<path opacity="0.3" d="M292.121 73.1157C291.835 73.0804 291.56 72.9731 291.324 72.8096C291.088 72.6461 290.888 72.431 290.734 72.187C290.419 71.7018 290.288 71.1079 290.325 70.5291C290.343 70.2394 290.404 69.9503 290.514 69.6796C290.619 69.4069 290.774 69.1531 290.969 68.9314C290.755 69.1355 290.587 69.3872 290.462 69.658C290.34 69.9301 290.266 70.2244 290.237 70.5226C290.181 71.1184 290.316 71.7371 290.639 72.2485C290.803 72.5016 291.015 72.7259 291.267 72.8946C291.517 73.0634 291.81 73.1772 292.112 73.2014C292.413 73.2347 292.809 73.1765 293.066 73.0255C293.325 72.8744 293.473 72.7521 293.666 72.5284C293.456 72.7351 293.301 72.8469 293.04 72.9777C292.782 73.1137 292.407 73.1576 292.121 73.1157ZM294.367 70.5174C294.359 70.3696 294.344 70.2224 294.318 70.0759C294.266 69.7842 294.174 69.4958 294.023 69.2355C293.871 68.9772 293.658 68.7522 293.403 68.5926C293.149 68.4324 292.856 68.3369 292.559 68.3108C292.411 68.297 292.261 68.2957 292.113 68.3134C291.965 68.3317 291.82 68.369 291.682 68.4233C291.407 68.5344 291.163 68.713 290.969 68.9308C291.386 68.5214 291.979 68.3049 292.549 68.3971C292.832 68.4357 293.11 68.5233 293.348 68.679C293.586 68.8327 293.784 69.0459 293.925 69.2924C294.216 69.7855 294.294 70.3826 294.273 70.9542C294.258 71.2413 294.199 71.5271 294.095 71.7966C293.994 72.068 293.855 72.3034 293.665 72.5264C294.081 72.1118 294.312 71.55 294.36 70.9601C294.375 70.813 294.375 70.6652 294.367 70.5174Z" fill="black"/>
<path d="M309.063 87.0389C307.655 86.226 305.373 86.226 303.965 87.0389C303.26 87.4457 302.909 87.978 302.909 88.511C302.909 88.5706 302.909 88.825 302.909 88.8838C302.909 89.4168 303.26 89.9505 303.964 90.3573C305.372 91.1702 307.655 91.1702 309.063 90.3573C309.766 89.9518 310.117 89.4194 310.118 88.8877C310.118 88.8276 310.118 88.5706 310.118 88.511C310.119 87.978 309.767 87.445 309.063 87.0389Z" fill="#4F7065"/>
<path opacity="0.3" d="M309.063 87.0389C307.655 86.226 305.373 86.226 303.965 87.0389C303.26 87.4457 302.909 87.978 302.909 88.511C302.909 88.5706 302.909 88.825 302.909 88.8838C302.909 89.4168 303.26 89.9505 303.964 90.3573C305.372 91.1702 307.655 91.1702 309.063 90.3573C309.766 89.9518 310.117 89.4194 310.118 88.8877C310.118 88.8276 310.118 88.5706 310.118 88.511C310.119 87.978 309.767 87.445 309.063 87.0389Z" fill="white"/>
<path d="M303.965 89.9825C302.557 89.1696 302.557 87.8518 303.965 87.0389C305.373 86.226 307.655 86.226 309.063 87.0389C310.471 87.8518 310.471 89.1696 309.063 89.9825C307.655 90.7955 305.373 90.7955 303.965 89.9825Z" fill="#4F7065"/>
<path opacity="0.55" d="M303.965 89.9825C302.557 89.1696 302.557 87.8518 303.965 87.0389C305.373 86.226 307.655 86.226 309.063 87.0389C310.471 87.8518 310.471 89.1696 309.063 89.9825C307.655 90.7955 305.373 90.7955 303.965 89.9825Z" fill="white"/>
<path opacity="0.3" d="M305.446 87.4985C304.572 87.6915 304.342 88.3036 304.807 88.7706C305.525 89.4906 307.346 89.3337 307.858 88.6555L305.446 87.4985Z" fill="#4F7065"/>
<path opacity="0.3" d="M308.075 86.938C309.067 87.1891 310.034 88.9602 308.178 89.7561C306.82 90.3388 304.392 90.0347 304.018 89.7652C304.572 90.104 306.294 90.741 308.26 90.0896C310.817 89.242 309.843 87.0851 308.075 86.938Z" fill="white"/>
<path d="M310.396 62.5788L306.513 62.5762L302.631 62.5788C302.631 62.5788 301.848 69.64 301.928 72.9643C301.928 74.6739 302.864 76.1643 304.251 76.9524C305.296 77.5456 305.959 78.6358 305.959 79.8365V86.767C305.959 87.0129 305.832 87.2418 305.623 87.3713C305.398 87.5112 305.207 87.7035 305.069 87.9304C305.06 87.9461 305.051 87.9612 305.043 87.9769C304.888 88.2771 305.016 88.6165 305.428 88.8545C306.028 89.1999 306.999 89.1999 307.598 88.8545C308.009 88.6165 308.138 88.2771 307.984 87.9769C307.969 87.9488 307.953 87.9219 307.935 87.8958C307.801 87.6852 307.62 87.5067 307.408 87.3739C307.198 87.2424 307.068 87.0142 307.068 86.7663V79.8359C307.068 78.6345 307.731 77.5449 308.776 76.9517C310.163 76.1637 311.099 74.6732 311.099 72.9637C311.179 69.64 310.396 62.5788 310.396 62.5788Z" fill="#4F7065"/>
<path opacity="0.5" d="M310.396 62.5788L306.513 62.5762L302.631 62.5788C302.631 62.5788 301.848 69.64 301.928 72.9643C301.928 74.6739 302.864 76.1643 304.251 76.9524C305.296 77.5456 305.959 78.6358 305.959 79.8365V86.767C305.959 87.0129 305.832 87.2418 305.623 87.3713C305.398 87.5112 305.207 87.7035 305.069 87.9304C305.06 87.9461 305.051 87.9612 305.043 87.9769C304.888 88.2771 305.016 88.6165 305.428 88.8545C306.028 89.1999 306.999 89.1999 307.598 88.8545C308.009 88.6165 308.138 88.2771 307.984 87.9769C307.969 87.9488 307.953 87.9219 307.935 87.8958C307.801 87.6852 307.62 87.5067 307.408 87.3739C307.198 87.2424 307.068 87.0142 307.068 86.7663V79.8359C307.068 78.6345 307.731 77.5449 308.776 76.9517C310.163 76.1637 311.099 74.6732 311.099 72.9637C311.179 69.64 310.396 62.5788 310.396 62.5788Z" fill="white"/>
<path opacity="0.2" d="M305.067 77.0798C304.221 76.6527 303.375 76.3938 302.616 75.3774C303.02 76.0295 303.584 76.5723 304.252 76.9523C305.296 77.5455 305.96 78.6357 305.96 79.8364C305.96 78.2191 305.92 77.5101 305.067 77.0798Z" fill="#4F7065"/>
<path d="M306.514 76.8859C304.174 76.8859 302.27 75.0253 302.27 72.7383V72.7304C302.201 69.9215 302.791 64.2762 302.938 62.9211L306.514 62.9185L310.09 62.9211C310.237 64.2762 310.827 69.9215 310.757 72.7304V72.7383C310.758 75.0247 308.854 76.8859 306.514 76.8859Z" fill="#4F7065"/>
<path opacity="0.3" d="M306.514 76.8859C304.174 76.8859 302.27 75.0253 302.27 72.7383V72.7304C302.201 69.9215 302.791 64.2762 302.938 62.9211L306.514 62.9185L310.09 62.9211C310.237 64.2762 310.827 69.9215 310.757 72.7304V72.7383C310.758 75.0247 308.854 76.8859 306.514 76.8859Z" fill="white"/>
<path d="M302.674 66.8737C302.503 68.9057 302.35 71.2333 302.387 72.7375C302.387 74.9604 304.238 76.7687 306.513 76.7687C308.789 76.7687 310.641 74.9604 310.641 72.7263C310.678 71.23 310.524 68.905 310.353 66.873L302.674 66.8737Z" fill="#4F7065"/>
<path opacity="0.85" d="M302.674 66.8737C302.503 68.9057 302.35 71.2333 302.387 72.7375C302.387 74.9604 304.238 76.7687 306.513 76.7687C308.789 76.7687 310.641 74.9604 310.641 72.7263C310.678 71.23 310.524 68.905 310.353 66.873L302.674 66.8737Z" fill="white"/>
<path d="M309.229 65.3062C310.728 66.1721 310.728 67.5755 309.229 68.4408C307.729 69.3067 305.299 69.3067 303.799 68.4408C302.3 67.5749 302.3 66.1714 303.799 65.3062C305.298 64.4409 307.729 64.4409 309.229 65.3062Z" fill="#FAFAFA"/>
<path opacity="0.5" d="M309.928 71.174C309.896 70.3382 309.81 68.9726 309.72 67.6594C309.709 67.6496 309.698 67.6391 309.686 67.6293C309.619 67.5711 309.549 67.5149 309.477 67.4593C309.197 67.2605 308.876 67.0826 308.53 66.9263C308.781 70.2231 308.986 72.6906 308.455 74.2661C308.164 75.1314 307.326 75.8011 306.852 76.7343H306.854C307.046 76.7186 307.234 76.6905 307.418 76.6499C307.428 76.6473 307.438 76.6454 307.449 76.6427C307.575 76.614 307.699 76.5793 307.821 76.5394C307.835 76.5348 307.848 76.5303 307.862 76.5257C307.899 76.5133 307.937 76.4995 307.973 76.4858C307.976 76.4845 307.979 76.4838 307.982 76.4825C308.125 76.3413 308.283 76.1915 308.455 76.0267C309.939 74.6055 310.006 73.1955 309.928 71.174Z" fill="white"/>
<path d="M303.769 64.1622C302.253 63.2865 302.253 61.8673 303.769 60.9922C305.285 60.1172 307.743 60.1172 309.26 60.9922C310.776 61.8679 310.776 63.2871 309.26 64.1622C307.743 65.0379 305.285 65.0379 303.769 64.1622Z" fill="#4F7065"/>
<path opacity="0.6" d="M303.769 64.1622C302.253 63.2865 302.253 61.8673 303.769 60.9922C305.285 60.1172 307.743 60.1172 309.26 60.9922C310.776 61.8679 310.776 63.2871 309.26 64.1622C307.743 65.0379 305.285 65.0379 303.769 64.1622Z" fill="white"/>
<path d="M306.514 64.4772C305.536 64.4772 304.621 64.26 303.939 63.8663C303.316 63.5066 302.973 63.0488 302.973 62.5773C302.973 62.1057 303.316 61.6479 303.939 61.2876C304.621 60.8939 305.536 60.6768 306.514 60.6768C307.492 60.6768 308.407 60.8939 309.089 61.2876C309.712 61.6473 310.056 62.1051 310.056 62.5773C310.056 63.0495 309.712 63.5066 309.089 63.867C308.406 64.2607 307.492 64.4772 306.514 64.4772Z" fill="#4F7065"/>
<path opacity="0.3" d="M306.514 64.4772C305.536 64.4772 304.621 64.26 303.939 63.8663C303.316 63.5066 302.973 63.0488 302.973 62.5773C302.973 62.1057 303.316 61.6479 303.939 61.2876C304.621 60.8939 305.536 60.6768 306.514 60.6768C307.492 60.6768 308.407 60.8939 309.089 61.2876C309.712 61.6473 310.056 62.1051 310.056 62.5773C310.056 63.0495 309.712 63.5066 309.089 63.867C308.406 64.2607 307.492 64.4772 306.514 64.4772Z" fill="white"/>
<path d="M240.331 89.4171C240.331 89.4171 237.7 100.755 238.781 108.825C239.479 114.044 239.437 116.09 239.581 122.23C239.718 128.091 238.747 135.743 238.747 135.743C247.546 142.157 272.401 143.635 277.614 134.232C277.614 134.232 277.783 128.913 277.446 118.98C277.195 111.569 276.326 100.833 275.456 97.339C274.052 91.6963 271.818 88.8363 270.173 87.6938C264.947 86.5271 258.637 85.981 254.894 86.2622C249.201 86.6899 243.244 88.2602 240.331 89.4171Z" fill="#F5F5F5"/>
<path d="M261.873 85.9302L261.798 86.9877C261.798 86.9877 262.956 91.7141 263.337 96.3811C263.948 103.858 263.932 112.376 263.932 112.376C263.932 112.376 258.085 101.016 254.674 95.4616C251.264 89.9072 249.36 87.7208 249.36 87.7208L249.383 86.5704C249.383 86.5704 241.024 88.8954 238.972 89.8594C238.972 89.8594 236.185 95.215 235.797 100.951C235.409 106.687 238.603 116.392 238.836 120.112C239.069 123.833 236.639 140.542 236.639 140.542C241.618 144.085 250.47 145.965 254.859 145.992C258.159 146.011 261.983 144.55 265.782 140.919C270.59 145.217 273.989 145.45 278.537 144.743C278.537 144.743 278.239 132.915 278.072 124.326C277.96 118.571 277.289 108.999 276.61 101.837C275.932 94.6742 272.888 88.5449 269.765 87.2473C269.765 87.2473 267.67 86.6064 261.873 85.9302Z" fill="#4F7065"/>
<path opacity="0.2" d="M261.873 85.9302L261.798 86.9877C261.798 86.9877 262.956 91.7141 263.337 96.3811C263.948 103.858 263.932 112.376 263.932 112.376C263.932 112.376 258.085 101.016 254.674 95.4616C251.264 89.9072 249.36 87.7208 249.36 87.7208L249.383 86.5704C249.383 86.5704 241.024 88.8954 238.972 89.8594C238.972 89.8594 236.185 95.215 235.797 100.951C235.409 106.687 238.603 116.392 238.836 120.112C239.069 123.833 236.639 140.542 236.639 140.542C241.618 144.085 250.47 145.965 254.859 145.992C258.159 146.011 261.983 144.55 265.782 140.919C270.59 145.217 273.989 145.45 278.537 144.743C278.537 144.743 278.239 132.915 278.072 124.326C277.96 118.571 277.289 108.999 276.61 101.837C275.932 94.6742 272.888 88.5449 269.765 87.2473C269.765 87.2473 267.67 86.6064 261.873 85.9302Z" fill="black"/>
<path d="M261.756 85.4902L267.835 86.8113C267.835 86.8113 268.803 94.8941 268.892 99.7945C268.935 102.161 267.521 103.479 265.389 105.401L268.409 108.397L264.962 114.574L263.932 112.376C263.932 112.376 263.624 101.965 263.141 96.1916C262.658 90.4181 261.798 86.9879 261.798 86.9879L261.756 85.4902Z" fill="#263238"/>
<path d="M249.343 86.3262L242.749 88.5262C242.749 88.5262 249.502 99.4775 251.018 101.638C253.001 104.463 254.674 104.906 256.614 105.454L254.014 108.363L266.99 118.904L263.932 112.378C263.932 112.378 256.885 97.0197 249.36 87.7218L249.343 86.3262Z" fill="#37474F"/>
<path d="M249.583 82.342C246.194 77.0355 242.669 71.7538 242.69 65.1052C242.886 60.401 244.871 57.7398 248.692 55.441C253.014 53.2148 260.062 53.1651 264.531 55.1716C267.669 56.8923 269.814 60.3251 269.318 63.9849C269.064 66.2425 267.894 68.0868 266.201 69.5387C264.467 71.0252 262.215 71.5124 260.481 72.9983C256.847 76.1133 253.216 79.2283 249.583 82.342Z" fill="#263238"/>
<path d="M268.916 77.5761C268.406 80.7924 267.412 82.3261 265.285 83.1926C263.831 83.7858 261.726 83.7296 261.726 83.7296L261.797 86.9878C265.154 93.1531 252.725 92.2728 249.36 87.7209L249.285 77.8096C249.285 77.8096 248.012 79.4825 245.621 78.1581C243.983 77.2438 242.668 74.6815 243.635 72.1681C244.594 69.6986 246.933 70.5246 247.684 71.1996C248.428 71.8745 249.303 73.0373 249.91 72.9503C250.847 72.8163 251.027 72.0171 251.213 71.4154C251.545 70.3625 251.742 69.0185 251.621 66.7328C252.229 66.3665 253.073 66.0022 253.296 65.2384C254.101 62.4746 253.123 61.697 252.524 60.5479C254.882 58.7625 258.674 61.5439 261.433 61.7388C264.593 61.9618 265.2 60.1247 266.922 60.2438C267.782 61.7355 268.536 63.4608 268.748 65.6609C269.106 69.3723 269.615 73.1799 268.916 77.5761Z" fill="#FFBDA7"/>
<path d="M261.726 83.7316L261.726 83.7277C259.212 83.7657 255.268 83.3268 253.037 81.4302C251.557 80.1719 250.3 77.5337 250.3 77.5337C250.3 77.5337 250.444 79.979 252.1 82.0312C253.297 83.5145 255.702 84.3968 257.321 84.6786C258.634 84.9075 261.019 85.0782 261.756 85.1037L261.726 83.7316Z" fill="#F0997A"/>
<path d="M258.819 69.4882C258.651 70.1095 258.03 70.4803 257.433 70.3175C256.836 70.154 256.488 69.5183 256.656 68.897C256.824 68.2757 257.444 67.9049 258.042 68.0684C258.639 68.2312 258.987 68.8676 258.819 69.4882Z" fill="#263238"/>
<path d="M267.36 66.7152L264.93 65.5799C265.221 64.8912 266.001 64.5877 266.672 64.9017C267.343 65.2149 267.651 66.0272 267.36 66.7152Z" fill="#263238"/>
<path d="M264.841 69.3819C265.082 69.9783 265.742 70.2719 266.316 70.0385C266.89 69.8043 267.159 69.1314 266.918 68.5349C266.676 67.9385 266.016 67.6442 265.442 67.8783C264.868 68.1124 264.599 68.7861 264.841 69.3819Z" fill="#263238"/>
<path d="M258.492 65.7501L255.819 66.7958C255.5 66.0627 255.84 65.234 256.579 64.9456C257.316 64.6572 258.174 65.0176 258.492 65.7501Z" fill="#263238"/>
<path d="M258.449 75.7686L262.225 76.6914C262.008 77.7521 260.88 78.4009 259.694 78.0647C258.662 77.7724 258.231 76.8293 258.449 75.7686Z" fill="#B16668"/>
<path d="M260.765 78.0987C260.574 77.0785 259.646 76.346 258.591 76.4362C258.527 76.4421 258.467 76.4604 258.405 76.4715C258.464 77.2263 258.898 77.8397 259.694 78.066C260.067 78.1706 260.431 78.17 260.765 78.0987Z" fill="#F28F8F"/>
<path d="M260.822 67.0195L261.815 74.2698L265.389 72.7518L260.822 67.0195Z" fill="#F0997A"/>
<path d="M250.117 133.156L250.47 161.925C243.406 162.588 234.168 159.338 232.236 157.974C231.739 147.116 232.236 127.993 232.236 127.993L250.117 133.156Z" fill="#4F7065"/>
<path opacity="0.4" d="M250.117 133.156L250.47 161.925C243.406 162.588 234.168 159.338 232.236 157.974C231.739 147.116 232.236 127.993 232.236 127.993L250.117 133.156Z" fill="white"/>
<path d="M238.679 90.4819C234.814 91.6251 230.608 92.0528 228.638 100.584C226.493 109.869 224.803 121.054 224.95 123.914C225.21 128.959 226.648 130.358 230.341 131.29C239.546 133.612 249.131 133.696 251.812 134.774C252.396 135.009 252.992 135.214 253.596 135.39C255.963 136.081 258.489 136.377 260.943 136.081C262.149 135.935 263.616 135.762 264.749 135.318C265.405 135.06 265.665 134.768 265.955 134.566C266.78 134.337 267.499 133.81 267.803 133.173C268.05 132.652 268.162 132.278 268.225 132.232C269.508 131.346 269.125 130.454 269.084 130.156C269.376 129.844 270.128 129.424 270.262 129.018C270.422 128.53 269.998 128.008 269.506 127.865C269.012 127.723 268.487 127.848 267.988 127.965C265.431 128.566 262.797 128.949 260.179 128.746C259.728 128.711 259.272 128.657 258.852 128.49C258.657 128.413 258.45 128.285 258.414 128.078C258.353 127.725 258.796 127.523 259.146 127.448C260.214 127.219 261.623 127.15 262.586 126.646C263.109 126.372 264.349 125.362 263.468 124.811C263.313 124.714 263.121 124.698 262.939 124.687C261.765 124.614 260.576 124.725 259.411 124.582C258.248 124.44 257.07 124.204 255.896 124.378C254.929 124.521 254.029 124.939 253.108 125.271C252.145 125.618 251.121 125.793 250.095 125.753C249.048 125.712 247.122 125.122 246.12 124.823C242.656 123.792 240.076 122.385 236.641 120.796L239.358 107.923C239.357 107.923 242.873 97.5353 238.679 90.4819Z" fill="#FFBDA7"/>
<path d="M248.343 125.448L249.842 125.734C249.842 125.734 248.516 127.035 248.144 129.888C247.772 132.742 248.578 134.495 248.578 134.495L244.671 134.293C244.671 134.293 243.891 124.419 248.343 125.448Z" fill="#E0E0E0"/>
<path d="M268.944 130.053C268.264 130.367 267.487 130.546 266.754 130.758C266.067 130.956 265.289 131.066 264.457 131.204C262.937 131.457 261.575 131.484 260.037 131.393C259.98 131.389 259.982 131.515 260.037 131.523C261.626 131.775 263.213 131.801 264.807 131.575C265.551 131.47 266.288 131.3 267.01 131.091C267.719 130.885 268.402 130.674 268.984 130.207C269.023 130.176 269.004 130.025 268.944 130.053ZM267.962 132.263C267.282 132.576 266.505 132.756 265.771 132.968C265.085 133.166 264.306 133.276 263.474 133.414C261.954 133.667 260.593 133.693 259.055 133.603C258.998 133.599 259 133.725 259.055 133.733C260.644 133.985 262.23 134.011 263.825 133.785C264.569 133.68 265.306 133.509 266.028 133.3C266.737 133.095 267.419 132.884 268.002 132.417C268.041 132.386 268.022 132.235 267.962 132.263ZM265.771 134.497C265.146 134.722 264.534 134.875 263.752 135.005C262.365 135.237 260.929 135.24 259.527 135.22C259.401 135.218 259.267 135.217 259.159 135.283C259.159 135.328 259.216 135.346 259.26 135.351C260.18 135.464 261.161 135.487 262.089 135.436C262.754 135.4 263.433 135.334 264.085 135.206C264.727 135.079 265.243 135.003 265.793 134.637C265.831 134.612 265.826 134.477 265.771 134.497Z" fill="#F0997A"/>
<path d="M238.972 89.8589C236.746 90.6443 233.737 91.2604 231.678 93.1315C229.476 95.1327 228.805 96.8783 226.985 106.095C225.511 113.561 224.05 121.626 224.253 124.928C224.457 128.229 224.896 129.89 227.791 131.081C230.687 132.273 242.168 133.516 242.168 133.516C242.168 133.516 241.249 129.286 242.262 126.895C243.275 124.504 245.176 124.534 245.176 124.534L236.942 121.256L239.929 108.467C239.929 108.467 243.306 94.8031 238.972 89.8589Z" fill="#F5F5F5"/>
<path d="M236.942 121.256C234.091 120.805 230.374 122.544 229.455 123.745C230.126 120.531 235.101 119.461 237.091 119.945L236.942 121.256Z" fill="#E0E0E0"/>
<path d="M248.343 125.448C247.009 125.087 245.824 126.575 245.72 130.946C245.616 135.318 245.72 154.77 245.72 154.77C241.246 154.987 232.608 151.449 230.109 149.784C229.901 143.956 230.109 139.792 230.109 133.965C230.109 128.137 229.294 120.726 237.091 120.373C241.696 121.7 248.496 124.923 249.169 125.206C249.842 125.489 249.842 125.735 249.842 125.735C249.842 125.735 248.97 125.69 248.343 125.448Z" fill="#4F7065"/>
<path opacity="0.7" d="M248.343 125.448C247.009 125.087 245.824 126.575 245.72 130.946C245.616 135.318 245.72 154.77 245.72 154.77C241.246 154.987 232.608 151.449 230.109 149.784C229.901 143.956 230.109 139.792 230.109 133.965C230.109 128.137 229.294 120.726 237.091 120.373C241.696 121.7 248.496 124.923 249.169 125.206C249.842 125.489 249.842 125.735 249.842 125.735C249.842 125.735 248.97 125.69 248.343 125.448Z" fill="white"/>
<path d="M249.36 87.7211L249.322 84.6191C249.322 84.6191 250.7 86.3791 253.082 87.481C255.464 88.5837 258.654 89.0559 258.654 89.0559L259.845 91.2187L260.265 88.6661C260.265 88.6661 260.785 88.4928 261.218 88.0167C261.651 87.5406 261.798 86.9879 261.798 86.9879L261.757 85.4903C261.757 85.4903 262.366 87.4967 262.485 88.4058C262.604 89.3155 262.561 91.3076 259.703 91.9132C256.844 92.5201 251.187 91.1932 249.36 87.7211Z" fill="#E0E0E0"/>
<path d="M260.908 92.2429C260.908 92.2429 260.355 91.808 260.576 90.7766C261.619 88.8826 263.943 88.5883 264.565 88.8434C265.682 89.3005 265.663 93.1448 264.51 93.547C263.314 93.9649 260.881 92.4698 260.908 92.2429Z" fill="#4F7065"/>
<path opacity="0.1" d="M261.215 91.2748C261.716 91.0551 263.795 89.9897 263.924 90.4161C264.086 90.9537 261.656 91.4625 261.656 91.4625C261.656 91.4625 264.067 91.6221 263.909 92.2022C263.802 92.5933 261.374 91.7379 261.374 91.7379L261.215 91.2748Z" fill="black"/>
<path d="M261.077 90.8605C261.116 90.8959 261.153 90.9338 261.188 90.9737C261.479 91.3098 261.628 91.8383 261.498 92.2732C261.376 92.6819 260.924 92.9494 260.538 93.0599C260.025 93.2078 259.389 93.1306 258.955 92.8075C258.554 92.5086 258.366 91.9501 258.505 91.4688C258.808 90.4276 260.325 90.1869 261.077 90.8605Z" fill="#4F7065"/>
<path opacity="0.2" d="M261.077 90.8605C261.116 90.8959 261.153 90.9338 261.188 90.9737C261.479 91.3098 261.628 91.8383 261.498 92.2732C261.376 92.6819 260.924 92.9494 260.538 93.0599C260.025 93.2078 259.389 93.1306 258.955 92.8075C258.554 92.5086 258.366 91.9501 258.505 91.4688C258.808 90.4276 260.325 90.1869 261.077 90.8605Z" fill="black"/>
<path d="M259.06 91.3586C258.839 90.6934 256.086 88.7007 254.435 89.8531C253.575 90.4534 254.141 94.0687 255.136 94.4683C256.579 95.0478 258.709 93.6639 259.077 92.573C259.272 92.2565 259.216 91.5947 259.06 91.3586Z" fill="#4F7065"/>
<path opacity="0.1" d="M259.174 91.7016C259.19 91.7951 259.199 91.8939 259.199 91.9952C259.002 92.1973 256.606 93.1273 256.549 92.7212C256.492 92.3098 258.734 91.8095 258.734 91.8095C258.734 91.8095 256.135 91.372 256.281 90.909C256.377 90.6062 258.881 91.459 259.174 91.7016Z" fill="black"/>
<path d="M210.304 218.397C209.188 228.437 208.93 231.66 208.543 235.818C208.305 239.007 208.008 242.133 207.965 243.857C207.885 247.154 208.642 250.789 209.823 253.501C210.796 255.735 214.069 259.062 215.17 260.651C215.17 260.651 218.933 263.377 216.311 265.103C215.564 265.594 208.733 265.494 205.474 264.149C202.215 262.804 201.614 259.417 199.78 254.063C198.045 248.999 196.774 241.871 196.774 241.871C196.774 241.871 197.34 241.012 197.625 240.355C198.333 238.728 195.368 225.509 194.698 219.421C194.027 213.332 193.455 210.444 195.766 202.428L192.314 175.596L192.072 152.76L210.767 147.434C213.108 162.411 212.677 187.282 212.12 194.452C211.564 201.623 211.071 211.842 210.304 218.397Z" fill="#FFA8A7"/>
<path d="M194.838 247.596C194.982 248.828 195.94 250.274 196.285 252.201C196.63 254.128 196.867 256.131 196.841 258.859C196.815 261.587 196.811 261.974 196.811 261.974L197.657 261.982C197.657 261.982 197.694 258.039 197.711 256.309C197.727 254.579 198.112 253.282 198.94 253.104C198.94 253.104 198.929 247.635 194.838 247.596Z" fill="#263238"/>
<path d="M197.488 240.237C196.951 241.682 195.564 243.128 194.893 245.323C194.566 246.395 194.723 247.433 194.969 248.146C195.633 248.849 196.071 249.095 196.571 249.579C197.285 250.27 197.966 250.778 198.615 252.279C199.298 253.863 200.128 256.804 200.681 259.082C201.234 261.359 201.452 262.737 203.42 263.856C204.954 264.728 209.664 265.692 212.84 265.872C217.173 266.117 218.972 263.879 217.341 262.226C215.709 260.573 213.919 258.747 213.42 258.394C213.42 258.394 211.468 258.077 209.396 258.986C206.979 260.046 206.215 261.461 203.659 260.208C201.344 259.074 199.874 254.68 200.026 248.542C200.198 241.573 197.488 240.237 197.488 240.237Z" fill="#37474F"/>
<path d="M181.44 145.845C171.58 162.505 172.945 173.071 174.953 189.277C176.828 204.415 176.507 201.673 176.507 201.673C176.507 201.673 174.706 209.5 174.842 216.988C174.97 224.096 175.715 233.438 175.578 240.682C175.488 245.432 175.874 248.237 174.661 253.452C174.013 256.24 173.895 258.585 173.206 261.111C172.517 263.636 171.825 266.584 171.57 269.002C171.316 271.42 170.931 274.89 172.663 277.212C174.267 279.362 177.596 281.41 180.03 278.976C182.463 276.543 183.156 272.793 183.375 267.75C183.595 262.707 183.963 257.096 184.985 251.29C186.007 245.483 190.574 222.106 192.002 215.14C193.429 208.174 195.176 202.004 196.144 193.713C197.113 185.421 200.51 169.82 200.51 169.82C203.238 169.098 211.093 164.952 207.588 147.122L181.44 145.845Z" fill="#FFA8A7"/>
<path d="M172.327 264.63C171.723 267.811 172.254 271.757 173.307 271.403C176.656 270.274 178.031 270.272 180.56 272.234C183.089 274.196 183.558 264.14 183.558 264.14C183.519 271.122 183.003 273.782 182.182 276.171C181.361 278.56 178.947 281.78 176.294 281.501C172.911 281.146 170.978 274.981 171.049 272.908C171.12 270.835 171.622 267.489 172.327 264.63Z" fill="#37474F"/>
<path d="M211.958 206.109C205.054 211.292 196.148 212.182 187.676 210.887C184.146 210.347 180.738 209.208 177.599 207.499C175.857 206.551 174.378 205.457 173.024 204.242C173.034 204.075 173.044 203.904 173.052 203.75C173.347 198.121 172.785 182.168 172.835 168.554C172.854 163.569 174.099 157.709 177.108 152.722C180.409 147.251 181.919 142.858 182.509 140.823C182.509 140.823 187.236 141.75 190.096 142.446C193.796 143.335 201.881 143.534 209.995 140.189C211.035 143.845 211.435 149.2 212.037 154.659C212.586 159.636 214.093 172.613 212.863 191.894C212.653 195.21 212.312 200.56 211.958 206.109Z" fill="#4F7065"/>
<path opacity="0.3" d="M211.958 206.109C205.054 211.292 196.148 212.182 187.676 210.887C184.146 210.347 180.738 209.208 177.599 207.499C175.857 206.551 174.378 205.457 173.024 204.242C173.034 204.075 173.044 203.904 173.052 203.75C173.347 198.121 172.785 182.168 172.835 168.554C172.854 163.569 174.099 157.709 177.108 152.722C180.409 147.251 181.919 142.858 182.509 140.823C182.509 140.823 187.236 141.75 190.096 142.446C193.796 143.335 201.881 143.534 209.995 140.189C211.035 143.845 211.435 149.2 212.037 154.659C212.586 159.636 214.093 172.613 212.863 191.894C212.653 195.21 212.312 200.56 211.958 206.109Z" fill="black"/>
<path opacity="0.05" d="M188.892 160.06C189.514 161.006 190.23 161.898 190.512 162.427C191.765 164.779 192.808 167.183 193.632 169.618C197.857 182.107 196.5 189.827 196.556 202.562C196.558 203.034 196.238 204.437 196.925 204.756C197.774 205.153 198.121 204.529 198.203 204.005C198.289 203.449 198.375 202.892 198.46 202.336C200.868 186.66 202.479 176.116 204.979 160.456C205.071 159.884 205.153 159.276 204.796 158.746C203.812 157.288 201.997 158.387 200.734 158.872C199.085 159.505 197.422 159.786 195.623 159.061C194.296 158.527 193.256 157.696 192.056 157.024C191.496 156.71 190.88 156.424 190.191 156.313C189.502 156.201 188.724 156.293 188.253 156.648C187.346 157.333 188.029 158.747 188.892 160.06Z" fill="black"/>
<path d="M202.781 105.559C208.776 106.691 210.407 108.982 213.037 116.078C215.078 121.59 217.654 128.878 217.654 128.878L210.445 131.83L202.781 105.559Z" fill="#37474F"/>
<path d="M209.978 139.139C210.292 136.976 210.585 132.546 210.388 128.657C213.978 123.704 211.96 117.993 210.414 115.261C206.753 108.788 204.707 107.224 202.782 105.56C202.782 105.56 202.45 105.444 200.643 105.261C197.623 104.955 186.948 105.299 185.895 105.511C183.074 106.08 181.401 106.477 179.541 107.041C178.824 107.258 176.974 108.223 176.43 108.737C174.929 110.155 175.49 113.659 175.613 115.521C175.856 119.199 176.61 122.86 178.036 126.267C178.086 126.386 178.137 126.505 178.188 126.624C180.701 132.407 182.091 136.387 182.423 140.234C192.732 144.653 201.217 143.913 209.978 139.139Z" fill="#4F7065"/>
<path opacity="0.3" d="M209.978 139.139C210.292 136.976 210.585 132.546 210.388 128.657C213.978 123.704 211.96 117.993 210.414 115.261C206.753 108.788 204.707 107.224 202.782 105.56C202.782 105.56 202.45 105.444 200.643 105.261C197.623 104.955 186.948 105.299 185.895 105.511C183.074 106.08 181.401 106.477 179.541 107.041C178.824 107.258 176.974 108.223 176.43 108.737C174.929 110.155 175.49 113.659 175.613 115.521C175.856 119.199 176.61 122.86 178.036 126.267C178.086 126.386 178.137 126.505 178.188 126.624C180.701 132.407 182.091 136.387 182.423 140.234C192.732 144.653 201.217 143.913 209.978 139.139Z" fill="black"/>
<path d="M181.879 142.206C181.879 142.206 185.955 144.94 196.861 144.574C206.694 144.244 210.47 141.46 210.47 141.46L210.254 137.478C210.254 137.478 205.454 141.509 196.803 141.492C186.123 141.471 182.144 138.148 182.144 138.148C182.282 139.146 182.025 141.427 181.879 142.206Z" fill="#263238"/>
<path d="M226.248 145.592L199.076 160.654L206.534 124.332L233.706 109.27L226.248 145.592Z" fill="#4F7065"/>
<path d="M179.213 107.5C174.755 109.093 174.128 109.741 172.731 116.641C171.847 121.005 170.064 129.946 169.357 136.488C168.756 142.046 168.663 143.488 173.795 145.695C179.199 148.02 190.186 150.862 193.76 151.875C195.974 152.502 198.604 153.893 201.775 153.952L201.994 146.449L202.735 142.841C200.364 143.102 198.157 143.831 196.043 143.864C193.402 143.904 180.9 136.69 180.9 136.69L183.92 121.765C183.919 121.765 185.366 111.641 179.213 107.5Z" fill="#FFA8A7"/>
<path d="M179.622 106.952C176.681 107.703 175.031 107.953 173.088 113.756C171.145 119.56 169.237 130.728 169.237 130.728C169.237 130.728 171.043 132.817 175.968 133.609C180.893 134.402 181.802 133.726 181.802 133.726C181.802 133.726 183.502 125.959 184.353 122.413C185.271 118.592 185.123 110.675 179.622 106.952Z" fill="#37474F"/>
<path d="M228.069 136.724C226.5 137.855 223.843 138.848 222.97 140.7C222.807 141.048 222.829 141.69 223.334 141.854C223.136 143.22 224.262 144.473 225.532 144.079C227.146 143.577 227.876 142.872 228.517 141.867C230.954 138.045 228.52 134.526 228.52 134.526L228.069 136.724Z" fill="#FFA8A7"/>
<path d="M213.4 146.477C213.528 146.225 213.595 145.941 213.591 145.658C213.587 145.422 213.524 145.17 213.346 145.017C213.116 144.819 212.775 144.842 212.475 144.887C210.349 145.207 208.322 146.07 206.161 146.174C205.208 146.22 204.256 146.055 203.359 145.721C202.987 145.582 202.308 145.296 202.335 144.783L202.006 146.382L201.775 153.952C202.291 153.99 203.015 154.032 204.134 153.948C204.576 153.914 205.017 153.869 205.456 153.81C207.276 153.564 209.13 153.084 210.693 152.086C211.237 151.739 211.751 151.328 212.179 150.843C212.838 150.094 212.982 148.856 212.889 147.896C212.865 147.648 212.825 147.385 212.927 147.157C212.993 147.007 213.114 146.891 213.216 146.763C213.286 146.675 213.348 146.578 213.4 146.477Z" fill="#FFA8A7"/>
<path d="M182.41 78.2571C183.126 73.2566 191.664 67.0802 201.554 71.9859C211.445 76.8916 208.647 83.8848 206.787 85.7729C206.435 91.1716 206.12 95.2591 206.894 97.7607C207.667 100.262 208.784 101.293 209.47 101.787C207.542 102.477 205.38 102.493 203.443 101.831C203.65 102.38 203.993 102.877 204.433 103.266C201.845 104.492 198.263 103.756 195.883 102.375C195.661 102.245 195.374 102.262 195.119 102.297C194.806 102.341 194.532 102.521 194.254 102.672C192.123 103.826 189.3 103.717 186.978 103.356C185.684 103.156 184.392 102.806 183.272 102.127C182.151 101.449 181.21 100.411 180.839 99.155C180.628 100.307 180.847 101.534 181.444 102.541C180.594 102.319 179.738 102.031 179.05 101.485C177.79 100.484 176.888 98.7626 176.973 97.1551C177.058 95.5476 177.622 94.0944 178.151 92.5745C178.743 90.8695 179.104 89.2162 178.999 87.3968C178.914 85.9272 178.9 84.5865 179.012 83.3747C179.241 80.8882 180.29 78.6436 182.41 78.2571Z" fill="#263238"/>
<path d="M186.512 103.203C186.48 101.469 186.38 96.1858 186.38 96.1858C186.37 96.6312 184.33 98.0889 182.214 96.3558C179.838 94.4108 179.739 91.8328 180.754 90.1853C181.875 88.3639 184.713 88.2044 186.092 90.893C186.974 91.8269 188.353 90.5287 187.45 87.8656C187.12 86.8911 187.954 86.2005 188.387 85.4903C188.824 84.7741 188.924 83.869 188.969 83.0495C189.101 80.6461 189.777 78.5082 192.557 78.3185C193.71 78.24 194.862 78.5814 195.887 79.1157C196.397 79.3813 196.951 79.7063 197.508 79.5644C198.063 79.4231 198.394 78.8757 198.796 78.467C199.432 77.8182 200.406 77.3702 201.297 77.6789C202.854 78.2171 203.75 80.1674 204.162 81.6284C204.841 84.0338 205.262 86.5216 205.397 89.0179C205.737 95.3219 204.153 98.2681 202.411 99.7416C201.438 100.565 200.114 101.059 197.743 101.074C197.743 101.074 197.692 101.857 197.67 103.02C197.651 104.083 197.775 104.686 198.765 104.922C199.429 105.08 200.383 105.266 200.383 105.266C200.383 105.266 199.802 111.988 198.811 118.677C189.156 108.355 183.401 106.036 183.401 106.036C183.401 106.036 184.495 105.752 184.822 105.647C186.172 105.208 186.545 104.936 186.512 103.203Z" fill="#FFA8A7"/>
<path d="M186.512 103.203C186.512 103.203 188.873 106.029 192.148 109.028C195.424 112.026 199.596 118.498 200.53 122.548C197.316 118.912 192.29 117.025 191.224 115.474C190.156 113.922 190.026 111.767 190.026 111.767L181.848 106.402C181.848 106.402 184.007 104.902 184.975 103.718C185.895 102.591 186.494 102.214 186.494 102.214L186.512 103.203Z" fill="#37474F"/>
<path d="M197.797 103.929C198.662 107.105 198.811 118.677 198.811 118.677L200.53 122.548C200.53 122.548 202.484 116.234 202.736 114.111C202.989 111.989 200.958 110.348 200.958 110.348L201.183 105.318L197.797 103.929Z" fill="#37474F"/>
<path d="M197.743 101.074C193.485 101.026 189.468 99.5113 187.963 96.1562C187.963 96.1562 188.169 98.7722 190.636 100.359C193.806 102.397 197.687 102.344 197.687 102.344L197.743 101.074Z" fill="#F28F8F"/>
<path d="M203.027 83.389L200.42 82.7946C200.558 82.0627 201.254 81.603 201.974 81.7671C202.694 81.9313 203.166 82.6572 203.027 83.389Z" fill="#263238"/>
<path d="M191.633 83.5629L194.16 82.5918C193.916 81.8782 193.152 81.5172 192.455 81.7854C191.757 82.0535 191.389 82.8494 191.633 83.5629Z" fill="#263238"/>
<path d="M200.465 86.8777C200.43 87.4859 200.9 88.0209 201.514 88.0738C202.129 88.1261 202.656 87.6755 202.691 87.0673C202.726 86.4584 202.257 85.9235 201.643 85.8718C201.028 85.8195 200.5 86.2701 200.465 86.8777Z" fill="#263238"/>
<path d="M192.392 87.224C192.357 87.8322 192.827 88.3672 193.441 88.4195C194.056 88.4719 194.583 88.0206 194.618 87.413C194.654 86.8048 194.184 86.2692 193.57 86.2175C192.955 86.1652 192.428 86.6158 192.392 87.224Z" fill="#263238"/>
<path d="M188.773 84.6938L182.416 88.939C183.124 88.784 183.595 88.7643 184.537 89.2078L189.116 86.1948L188.773 84.6938Z" fill="#455A64"/>
<path d="M206.292 83.8097C204.614 83.6357 202.34 83.605 200.957 84.0654C199.409 84.5886 198.857 85.1419 198.002 85.1713C197.086 85.1962 196.507 84.6762 194.862 84.2433C193.547 83.9032 191.205 84.0765 189.531 84.3021C188.954 84.3237 188.429 84.5062 188.423 85.2714C188.491 85.9895 188.958 86.0961 189.081 86.6768C189.366 88.6839 189.909 91.1842 192.538 91.2613C197.959 91.4955 196.175 86.2491 198.168 86.48C198.889 86.6291 198.792 88.0175 199.244 89.0005C199.415 89.3732 199.632 89.7506 199.929 90.0665C201.711 91.9055 205.057 90.816 205.554 88.9521C205.994 88.0254 205.902 86.548 206.364 85.7429C206.78 85.3695 207.124 84.0033 206.292 83.8097ZM196.048 88.0894C195.957 88.5008 195.755 89.0953 195.3 89.5963C194.8 90.1888 193.938 90.4975 193.072 90.5066C191.902 90.5197 190.727 89.9906 190.411 88.8599C190.369 88.7539 190.332 88.6532 190.301 88.5518C190.059 87.714 189.731 85.9299 190.175 85.4087C190.981 84.5336 194.87 84.5422 196.024 85.8364C196.337 86.2838 196.237 87.2569 196.048 88.0894ZM205.294 87.5028C205.168 88.0862 204.894 89.3504 203.781 89.935C202.311 90.7009 200.242 89.9684 199.917 88.5342C198.998 85.531 200.165 84.8142 203.164 84.4728C205.635 84.4349 205.779 85.0712 205.294 87.5028Z" fill="#455A64"/>
<path d="M201.298 91.0373L197.805 92.7816L197.131 87.3632C197.253 87.0342 197.383 86.7726 197.533 86.6699L201.298 91.0373Z" fill="#F28F8F"/>
<path d="M198.436 94.3801L194.798 93.2251C194.439 94.2257 194.962 95.295 195.967 95.6142C196.971 95.9327 198.077 95.38 198.436 94.3801Z" fill="#B16668"/>
<path d="M197.112 95.6209C196.747 95.7288 196.349 95.736 195.967 95.6144C195.125 95.3469 194.629 94.5523 194.699 93.7139C195.808 93.8597 196.723 94.6033 197.112 95.6209Z" fill="#F28F8F"/>
<path d="M121.453 261.159L117.802 259.858L118.991 257.471L121.24 259.472L121.453 261.159Z" fill="black"/>
<path d="M125.214 254.796C125.214 254.796 125.363 257.084 125.397 257.805C125.431 258.525 122.244 260.785 121.453 261.159L121.24 259.472C120.56 260.136 120.151 260.976 119.416 262.213C118.805 263.245 118.025 265.194 116.339 266.529C113.836 268.511 109.931 269.532 105.947 269.693C101.963 269.855 99.6788 268.925 98.5447 268.099C97.41 267.273 97.0497 266.301 97.3309 265.324C97.6128 264.346 125.214 254.796 125.214 254.796Z" fill="#263238"/>
<path d="M114.542 247.046L114.491 245.717C113.757 245.7 113.672 247.624 112.603 249.317C111.166 251.594 109.051 254.448 107.007 256.257C102.972 259.827 99.3707 262.075 97.5703 263.682C95.1609 265.832 99.406 269.205 105.909 268.818C109.136 268.626 114.868 267.03 116.476 265.183C118.084 263.336 119.069 260.466 120.358 259.124C121.645 257.781 124.414 256.421 125.215 254.796C125.655 253.903 125.571 252.65 125.52 250.171C125.487 248.532 125.761 245.894 124.813 246.101L124.747 246.934C124.361 247.645 122.556 248.884 119.953 249.322C118.653 249.541 114.777 249.675 114.542 247.046Z" fill="#37474F"/>
<path opacity="0.15" d="M117.863 249.401C116.621 249.305 115.283 248.916 114.761 247.84C114.754 247.827 114.744 247.818 114.738 247.805C114.738 247.805 115.064 249.044 113.903 250.362C112.743 251.68 105.412 260.966 105.412 260.966C105.412 260.966 112.236 253.301 113.546 251.858C115.481 249.724 116.095 249.473 117.87 249.404C117.868 249.402 117.866 249.402 117.863 249.401Z" fill="black"/>
<path opacity="0.15" d="M116.244 251.86C116.269 252.07 116.466 252.221 116.683 252.196C116.902 252.171 117.059 251.982 117.034 251.771C117.009 251.56 116.812 251.41 116.594 251.435C116.377 251.46 116.22 251.649 116.244 251.86Z" fill="black"/>
<path opacity="0.15" d="M114.328 253.915C114.353 254.125 114.55 254.276 114.768 254.251C114.986 254.227 115.143 254.036 115.118 253.826C115.094 253.615 114.897 253.465 114.679 253.49C114.46 253.514 114.304 253.704 114.328 253.915Z" fill="black"/>
<path opacity="0.15" d="M112.412 255.97C112.437 256.18 112.634 256.33 112.852 256.306C113.07 256.281 113.227 256.091 113.202 255.881C113.178 255.671 112.98 255.52 112.763 255.545C112.545 255.569 112.388 255.759 112.412 255.97Z" fill="black"/>
<path opacity="0.15" d="M110.497 258.024C110.521 258.235 110.718 258.385 110.937 258.36C111.155 258.336 111.312 258.146 111.287 257.935C111.263 257.725 111.066 257.574 110.847 257.599C110.629 257.624 110.472 257.814 110.497 258.024Z" fill="black"/>
<path opacity="0.15" d="M108.58 260.079C108.605 260.288 108.801 260.44 109.02 260.415C109.238 260.39 109.395 260.2 109.37 259.99C109.346 259.78 109.149 259.629 108.931 259.653C108.713 259.678 108.556 259.869 108.58 260.079Z" fill="black"/>
<path d="M116.652 251.969C116.689 251.966 116.726 251.95 116.753 251.922C116.816 251.862 116.814 251.763 116.751 251.705C116.67 251.629 114.754 249.862 112.251 250.015C112.251 250.015 112.249 250.015 112.248 250.015C112.162 250.023 112.096 250.095 112.101 250.179C112.106 250.264 112.182 250.328 112.27 250.323C114.632 250.178 116.508 251.908 116.526 251.926C116.562 251.958 116.608 251.973 116.652 251.969Z" fill="#263238"/>
<path d="M114.735 254.017C114.772 254.013 114.809 253.998 114.837 253.97C114.899 253.909 114.897 253.811 114.834 253.752C114.753 253.677 112.838 251.909 110.334 252.063H110.332C110.245 252.07 110.179 252.143 110.185 252.227C110.19 252.312 110.266 252.376 110.354 252.371C112.712 252.227 114.592 253.957 114.61 253.974C114.646 254.006 114.691 254.021 114.735 254.017Z" fill="#263238"/>
<path d="M112.82 256.078C112.858 256.075 112.893 256.06 112.922 256.032C112.984 255.97 112.982 255.873 112.919 255.814C112.839 255.738 110.922 253.971 108.419 254.125C108.419 254.125 108.418 254.125 108.416 254.125C108.33 254.132 108.264 254.204 108.269 254.288C108.274 254.373 108.35 254.438 108.438 254.432C110.797 254.288 112.676 256.017 112.695 256.035C112.731 256.068 112.776 256.082 112.82 256.078Z" fill="#263238"/>
<path d="M110.905 258.133C110.942 258.13 110.979 258.115 111.006 258.087C111.068 258.026 111.067 257.928 111.003 257.869C110.923 257.793 109.007 256.025 106.504 256.179C106.504 256.179 106.502 256.179 106.501 256.179C106.414 256.186 106.348 256.259 106.353 256.342C106.359 256.427 106.435 256.492 106.523 256.486C108.881 256.342 110.761 258.073 110.779 258.09C110.814 258.123 110.861 258.137 110.905 258.133Z" fill="#263238"/>
<path d="M108.988 260.188C109.026 260.184 109.062 260.169 109.09 260.141C109.152 260.08 109.151 259.982 109.087 259.923C109.007 259.848 107.091 258.08 104.588 258.234C104.588 258.234 104.586 258.234 104.584 258.234C104.498 258.241 104.432 258.314 104.437 258.398C104.442 258.483 104.518 258.547 104.607 258.541C106.965 258.397 108.845 260.127 108.863 260.144C108.898 260.176 108.944 260.191 108.988 260.188Z" fill="#263238"/>
<path d="M136.895 271.377C137.059 274.418 137.537 276.439 138.643 278.236C139.751 280.032 142.095 280.521 143.898 280.39C146.435 280.206 149.081 278.863 150.468 275.577C151.303 273.599 151.217 271.202 150.75 268.378L136.895 271.377Z" fill="#263238"/>
<path d="M146.725 249.439C147.605 249.517 147.839 253.6 148.321 257.692C148.837 262.077 150.211 264.544 150.645 267.502C151.246 271.602 150.94 273.44 149.434 275.9C147.928 278.361 141.445 280.771 138.867 277.185C136.766 274.263 136.698 271.05 136.917 266.957C137.143 262.727 137.337 259.751 137.178 256.535C137.025 253.462 136.825 250.483 137.591 250.151C137.647 250.603 137.701 251.176 137.761 251.619C137.806 251.96 137.884 253.624 138.377 253.646C138.425 253.254 138.418 252.889 138.394 252.489C138.372 252.141 138.405 251.763 138.641 251.502C138.879 251.238 139.263 251.157 139.62 251.093C140.488 250.939 141.36 250.802 142.24 250.738C142.679 250.706 143.119 250.691 143.559 250.701C143.916 250.71 145.528 250.698 145.865 250.823C146.072 250.9 146.263 251.045 146.338 251.246C146.395 251.394 146.383 251.557 146.382 251.715C146.38 251.999 146.415 252.282 146.487 252.558C146.518 252.68 146.604 252.829 146.73 252.801C146.832 252.779 146.873 252.656 146.875 252.555C146.881 252.06 146.818 251.7 146.798 251.123C146.789 250.857 146.746 250.494 146.744 250.227C146.743 249.965 146.741 249.702 146.725 249.439Z" fill="#37474F"/>
<path opacity="0.15" d="M147.412 268.474C147.427 268.638 147.304 268.782 147.134 268.796C146.965 268.81 146.815 268.689 146.799 268.526C146.782 268.362 146.907 268.219 147.076 268.205C147.245 268.19 147.396 268.311 147.412 268.474Z" fill="black"/>
<path opacity="0.15" d="M147.134 265.64C147.15 265.804 147.026 265.948 146.857 265.962C146.688 265.976 146.538 265.855 146.522 265.691C146.506 265.528 146.63 265.384 146.799 265.37C146.968 265.356 147.118 265.477 147.134 265.64Z" fill="black"/>
<path opacity="0.15" d="M146.873 262.966C146.888 263.129 146.765 263.273 146.595 263.287C146.426 263.301 146.276 263.18 146.26 263.017C146.244 262.854 146.368 262.709 146.537 262.695C146.707 262.682 146.856 262.802 146.873 262.966Z" fill="black"/>
<path opacity="0.15" d="M146.614 260.328C146.63 260.492 146.506 260.636 146.337 260.65C146.168 260.665 146.018 260.544 146.002 260.38C145.985 260.217 146.109 260.073 146.279 260.058C146.448 260.044 146.598 260.165 146.614 260.328Z" fill="black"/>
<path opacity="0.15" d="M139.646 260.657C139.662 260.82 139.538 260.964 139.369 260.978C139.199 260.993 139.05 260.872 139.033 260.708C139.018 260.545 139.142 260.401 139.311 260.386C139.48 260.373 139.63 260.494 139.646 260.657Z" fill="black"/>
<path opacity="0.15" d="M139.906 263.312C139.922 263.476 139.798 263.62 139.629 263.634C139.46 263.648 139.31 263.527 139.293 263.364C139.277 263.2 139.401 263.056 139.571 263.042C139.74 263.028 139.89 263.149 139.906 263.312Z" fill="black"/>
<path opacity="0.15" d="M140.168 265.988C140.184 266.151 140.06 266.295 139.891 266.309C139.721 266.322 139.571 266.202 139.555 266.039C139.539 265.875 139.663 265.731 139.832 265.717C140.002 265.703 140.152 265.824 140.168 265.988Z" fill="black"/>
<path opacity="0.15" d="M140.446 268.823C140.462 268.987 140.337 269.131 140.169 269.145C139.999 269.159 139.849 269.039 139.833 268.875C139.817 268.712 139.941 268.568 140.11 268.554C140.28 268.54 140.429 268.66 140.446 268.823Z" fill="black"/>
<path opacity="0.15" d="M143.027 270.191L140.785 250.409L141.921 250.401L143.027 270.191Z" fill="black"/>
<path d="M147.118 268.651C147.102 268.652 147.087 268.651 147.071 268.647C145.04 268.187 141.569 268.342 140.205 268.965C140.128 269.003 140.034 268.968 139.997 268.893C139.959 268.818 139.992 268.728 140.07 268.693C141.514 268.032 145.015 267.871 147.14 268.354C147.224 268.373 147.277 268.454 147.257 268.535C147.242 268.601 147.184 268.646 147.118 268.651Z" fill="#263238"/>
<path d="M146.841 265.816C146.814 265.818 146.786 265.813 146.761 265.801C145.214 265.073 141.486 265.419 139.928 266.131C139.849 266.168 139.757 266.134 139.719 266.059C139.681 265.984 139.715 265.894 139.792 265.859C141.417 265.116 145.265 264.762 146.896 265.531C146.974 265.567 147.006 265.657 146.969 265.732C146.944 265.782 146.894 265.812 146.841 265.816Z" fill="#263238"/>
<path d="M146.578 263.142C146.551 263.144 146.524 263.139 146.498 263.127C144.951 262.4 141.223 262.745 139.666 263.457C139.587 263.495 139.495 263.46 139.457 263.385C139.419 263.31 139.452 263.22 139.53 263.185C141.153 262.441 145 262.088 146.633 262.857C146.711 262.893 146.743 262.984 146.706 263.058C146.682 263.108 146.632 263.138 146.578 263.142Z" fill="#263238"/>
<path d="M146.321 260.504C146.294 260.506 146.266 260.501 146.24 260.489C144.693 259.761 140.965 260.108 139.407 260.819C139.33 260.857 139.237 260.823 139.199 260.747C139.161 260.672 139.195 260.583 139.272 260.547C140.896 259.805 144.743 259.45 146.376 260.219C146.454 260.256 146.486 260.346 146.449 260.42C146.423 260.47 146.374 260.501 146.321 260.504Z" fill="#263238"/>
<path d="M147.819 147.814C149.81 168.482 149.091 205.694 149.091 205.694C149.304 207.583 148.969 213.867 149.369 223.24C149.857 234.675 148.294 250.966 148.294 250.966C148.294 250.966 142.143 253.798 136.324 252.033C136.324 252.033 132.154 228.475 130.714 213.993C129.561 202.397 128.918 192.817 128.918 192.817L127.784 206.602C127.784 206.602 128.525 214.248 128.385 219.22C128.222 225.003 125.718 248.056 125.718 248.056C125.718 248.056 118.984 251.322 112.712 248.249C112.712 248.249 110.511 225.803 109.588 206.829C108.926 193.203 107.546 167.513 108.855 148.302L147.819 147.814Z" fill="#263238"/>
<path d="M112.101 244.92C112.101 244.92 113.755 246.205 117.514 246.514C121.273 246.822 126.217 244.987 126.217 244.987L125.814 248.725C125.814 248.725 122.175 250.648 117.564 250.2C112.954 249.753 112.252 248.777 112.252 248.777L112.101 244.92Z" fill="#455A64"/>
<path d="M148.722 248.988C148.722 248.988 145.989 250.51 142.235 250.865C138.48 251.22 135.715 250.175 135.715 250.175L136.364 253.972C136.364 253.972 138.37 255.292 142.975 254.788C147.58 254.283 148.62 252.846 148.62 252.846L148.722 248.988Z" fill="#455A64"/>
<path d="M136.163 175.854C136.22 181.206 136.345 186.559 136.524 191.911C136.608 194.587 136.707 197.263 136.817 199.939L136.982 203.954L137.074 205.961C137.099 206.63 137.197 207.297 137.254 207.965C137.795 213.309 138.496 222.988 139.129 228.328C139.758 233.667 140.467 239.004 141.256 244.334C140.932 238.982 140.53 233.635 140.046 228.292C139.567 222.95 138.937 213.272 138.365 207.934C138.303 207.266 138.2 206.6 138.171 205.931L138.067 203.926L137.843 199.916C137.694 197.243 137.534 194.57 137.358 191.896C137.018 186.549 136.624 181.203 136.163 175.854Z" fill="black"/>
<path d="M113.341 181.031C112.839 185.783 112.644 190.553 112.542 195.319C112.464 200.077 112.362 204.867 112.727 209.623C113.014 214.383 113.414 222.363 113.78 227.121C114.13 231.878 114.554 236.633 115.05 241.383C114.993 236.615 114.864 231.852 114.661 227.091C114.475 222.33 114.122 214.351 113.856 209.592L113.636 206.024L113.581 205.132L113.566 204.248L113.536 202.462L113.421 195.32L113.342 188.177C113.301 185.796 113.321 183.415 113.341 181.031Z" fill="black"/>
<path d="M128.918 192.816L125.69 171.342C125.69 171.342 118.655 170.472 115.009 167.419C115.009 167.419 117.394 171.496 124.039 173L127.227 192.884L127.783 206.602L128.918 192.816Z" fill="black"/>
<path d="M101.92 137.459C102.772 131.171 103.254 127.346 103.858 123.737C105.301 115.13 107.35 109.072 112.822 107.421L108.113 158.344C108.113 158.344 103.976 150.225 102.87 146.338C101.765 142.45 101.569 140.049 101.92 137.459Z" fill="#4F7065"/>
<path opacity="0.2" d="M101.92 137.459C102.772 131.171 103.254 127.346 103.858 123.737C105.301 115.13 107.35 109.072 112.822 107.421L108.113 158.344C108.113 158.344 103.976 150.225 102.87 146.338C101.765 142.45 101.569 140.049 101.92 137.459Z" fill="black"/>
<path d="M142.459 79.8709C141.383 77.2509 139.15 76.4406 139.15 76.4406C138.458 73.6382 135.113 70.6619 127.427 71.1779C121.73 71.5605 120.084 73.6317 118.337 77.0874C118.158 77.4419 117.758 77.9658 117.63 78.3562C116.509 81.7707 117.199 86.2376 117.199 86.2376C125.04 92.5454 133.304 99.4549 136.587 98.8997C137.658 96.1463 139.72 94.3746 140.431 92.5133C141.857 88.781 142.286 87.4507 142.492 86.6332C143.224 83.7144 143.25 81.7975 142.459 79.8709Z" fill="#37474F"/>
<path d="M139.466 95.3061C137.758 96.62 136.049 95.3212 136.049 95.3212C136.362 96.7253 136.332 98.1451 136.586 98.8998L136.471 104.333C136.471 104.333 135.29 105.733 132.708 107.524C128.396 110.515 124.752 111.616 122.426 113.958C121.98 110.127 123.288 105.615 123.849 103.59L123.836 102.266C123.836 102.266 122.565 102.242 121.191 101.93C118.235 101.26 117.376 99.6343 116.802 97.6657C115.754 94.0707 115.676 87.1782 117.148 81.8998C117.491 80.6689 117.538 79.9391 118.169 79.1719C120.049 76.8882 121.477 80.4937 123.915 80.6376C127.604 80.854 134.303 76.1106 133.266 82.2228C133.621 85.5909 134.288 86.1423 133.744 88.4921C133.525 89.4378 133.691 90.0584 134.419 90.2854C135.437 90.6032 135.483 88.9257 136.325 88.1291C137.075 87.4189 139.615 86.5334 140.855 88.842C141.996 90.9668 140.991 94.1335 139.466 95.3061Z" fill="#B16668"/>
<path d="M118.256 84.7045L120.513 84.0021C120.341 83.3671 119.696 83.0094 119.073 83.203C118.451 83.3972 118.085 84.0695 118.256 84.7045Z" fill="#263238"/>
<path d="M128.225 84.3163L130.349 85.3497C130.659 84.7689 130.434 84.0672 129.848 83.7814C129.261 83.4962 128.535 83.7356 128.225 84.3163Z" fill="#263238"/>
<path d="M126.199 94.0942L122.952 94.9333C123.151 95.8463 124.04 96.3976 124.938 96.1661C125.834 95.9346 126.399 95.0066 126.199 94.0942Z" fill="#9A4A4D"/>
<path d="M126.223 94.6416C126.149 95.3512 125.653 95.981 124.938 96.1661C124.636 96.2439 124.337 96.2269 124.063 96.1438C124.408 95.259 125.255 94.6586 126.223 94.6416Z" fill="#F28F8F"/>
<path d="M129.556 87.3123C129.559 87.9198 129.07 88.4149 128.463 88.4182C127.855 88.4214 127.36 87.9323 127.357 87.3247C127.353 86.7171 127.843 86.222 128.45 86.2188C129.057 86.2155 129.552 86.7054 129.556 87.3123Z" fill="#263238"/>
<path d="M121.033 87.0333C121.036 87.6408 120.547 88.1359 119.94 88.1398C119.332 88.1431 118.837 87.6539 118.834 87.0464C118.83 86.4388 119.32 85.9437 119.927 85.9405C120.534 85.9365 121.03 86.4257 121.033 87.0333Z" fill="#263238"/>
<path d="M117.186 86.4738C117.189 86.4751 117.192 86.4751 117.194 86.4764C117.178 86.4666 117.161 86.4561 117.144 86.4463C117.156 86.4587 117.17 86.4679 117.186 86.4738Z" fill="#9A4A4D"/>
<path d="M117.201 86.4802L117.196 86.4762L117.194 86.4756L117.201 86.4802Z" fill="#9A4A4D"/>
<path d="M135.276 96.5166C134.074 99.8638 130.074 102.321 123.836 102.267L123.849 103.59C123.849 103.59 128.973 103.671 131.759 102.011C134.282 100.508 134.984 98.9155 135.276 96.5166Z" fill="#9A4A4D"/>
<path d="M125.842 85.2554C123.123 85.326 120.856 91.316 120.856 91.316L125.021 92.5298L125.842 85.2554Z" fill="#9A4A4D"/>
<path d="M142.08 87.9748C142.08 87.9748 143.917 81.5983 144.135 78.1092C144.305 75.382 141.933 71.84 135.15 69.946C126.812 67.6178 120.595 69.6524 120.025 71.479C119.454 73.3056 117.131 80.7488 117.131 80.7488C119.752 76.9124 135.34 78.5186 142.08 87.9748Z" fill="#4F7065"/>
<path opacity="0.7" d="M118.394 76.7062C118.394 76.7062 122.228 74.257 131.157 76.7389C132.156 77.0162 133.08 77.319 133.943 77.6362C134.072 77.249 134.335 76.4348 134.702 75.1602C135.167 73.5435 135.594 72.193 134.964 71.314C134.548 70.7339 133.889 69.7928 132.051 69.2578C132.787 69.3795 133.544 69.5345 134.316 69.7261C135.507 70.4075 136.543 71.1989 136.792 71.8823C137.218 73.0523 136.357 75.2413 135.288 78.1633C141.787 80.8918 143.953 84.4097 143.953 84.4097L143.25 86.1389C143.25 86.1389 139.212 80.8552 130.64 78.7094C122.069 76.5643 117.817 78.5538 117.817 78.5538L118.394 76.7062Z" fill="white"/>
<path d="M117.133 80.7464C117.211 80.6378 117.288 80.5365 117.388 80.4344C117.423 80.3913 117.466 80.3475 117.508 80.3108C117.877 79.9544 118.354 79.6686 118.933 79.4606C119.169 79.3645 119.419 79.2893 119.684 79.2213C124.664 77.9963 134.803 80.8268 142.083 87.977C142.083 87.977 142.468 86.6376 142.906 84.8594C141.631 83.3755 138.108 79.9675 131.358 77.9133C121.95 75.0455 118.038 77.8426 118.038 77.8426C118.031 77.8714 118.017 77.9074 118.01 77.9362C117.935 78.1742 117.859 78.4044 117.797 78.6281C117.686 78.974 117.583 79.2984 117.5 79.579L117.348 80.0545C117.307 80.1911 117.272 80.3069 117.237 80.4076L117.133 80.7464Z" fill="#263238"/>
<path opacity="0.2" d="M142.08 87.975C141.299 87.1451 140.55 86.5702 139.598 85.7834C140.51 82.5834 140.951 80.5076 141.205 79.5986C141.858 77.2612 141.736 73.0331 136.978 70.5825C137.037 70.5891 137.092 70.5982 137.15 70.6054C142.397 72.5864 144.287 75.6719 144.135 78.1101C143.917 81.5985 142.08 87.975 142.08 87.975Z" fill="black"/>
<path d="M139.598 85.7832C137.586 82.4478 133.107 78.4453 123.485 77.2733C115.925 76.3519 113.581 78.34 114.271 78.9822C114.963 79.6251 117.132 80.7487 117.132 80.7487C117.132 80.7487 118.609 79.066 124.146 79.7461C130.061 80.4727 136.601 83.2274 139.598 85.7832Z" fill="black"/>
<path d="M136.387 105.085C133.983 109.473 122.645 108.715 123.812 104.43L112.822 107.421C112.822 107.421 108.136 111.815 107.733 122.864C107.554 127.768 108.412 138.328 108.399 146.835C108.383 157.491 107.523 165.804 107.523 165.804C107.523 165.804 110.164 171.029 116.991 172.945C119.689 166.235 122.069 161.23 122.069 161.23C122.069 161.23 124.383 168.293 128.404 173.535C142.612 173.132 149.359 166.476 149.359 166.476C149.359 166.476 148.04 148.08 148.002 144.079C147.946 138.313 149.835 123.969 150.012 119.382C150.188 114.795 147.605 107.512 147.605 107.512L136.387 105.085Z" fill="#4F7065"/>
<path opacity="0.5" d="M129.402 171.27C129.402 171.27 141.675 170.384 149.155 163.576C149.259 165.067 149.359 166.476 149.359 166.476C149.359 166.476 142.613 173.132 128.405 173.535C124.383 168.292 122.07 161.23 122.07 161.23C122.07 161.23 121.581 162.259 120.8 163.997L120.449 163.071L123.225 157.184C124.115 162.507 129.402 171.27 129.402 171.27Z" fill="white"/>
<path d="M134.717 114.686C131.234 113.111 129.826 116.929 134.364 117.994C134.364 117.994 135.078 116.298 134.717 114.686Z" fill="#4F7065"/>
<path opacity="0.7" d="M134.717 114.686C131.234 113.111 129.826 116.929 134.364 117.994C134.364 117.994 135.078 116.298 134.717 114.686Z" fill="white"/>
<path d="M132.445 115.859C132.451 116.254 132.774 116.57 133.169 116.564C133.564 116.56 133.88 116.235 133.874 115.841C133.87 115.446 133.545 115.13 133.151 115.135C132.757 115.14 132.441 115.464 132.445 115.859Z" fill="#4F7065"/>
<path d="M132.944 125.757C129.518 124.061 127.977 127.826 132.474 129.051C132.474 129.051 133.247 127.382 132.944 125.757Z" fill="#4F7065"/>
<path opacity="0.7" d="M132.944 125.757C129.518 124.061 127.977 127.826 132.474 129.051C132.474 129.051 133.247 127.382 132.944 125.757Z" fill="white"/>
<path d="M130.633 126.849C130.623 127.244 130.936 127.571 131.331 127.581C131.725 127.59 132.053 127.277 132.062 126.883C132.071 126.488 131.758 126.161 131.364 126.152C130.969 126.142 130.642 126.455 130.633 126.849Z" fill="#4F7065"/>
<path d="M129.946 136.636C126.814 134.444 124.724 137.935 128.986 139.821C128.986 139.821 130.001 138.288 129.946 136.636Z" fill="#4F7065"/>
<path opacity="0.7" d="M129.946 136.636C126.814 134.444 124.724 137.935 128.986 139.821C128.986 139.821 130.001 138.288 129.946 136.636Z" fill="white"/>
<path d="M127.497 137.368C127.429 137.757 127.688 138.128 128.077 138.196C128.466 138.264 128.836 138.004 128.904 137.616C128.972 137.227 128.712 136.856 128.323 136.788C127.935 136.72 127.565 136.98 127.497 137.368Z" fill="#4F7065"/>
<path d="M126.127 147.34C123.199 144.882 120.811 148.177 124.892 150.43C124.892 150.43 126.038 148.991 126.127 147.34Z" fill="#4F7065"/>
<path opacity="0.7" d="M126.127 147.34C123.199 144.882 120.811 148.177 124.892 150.43C124.892 150.43 126.038 148.991 126.127 147.34Z" fill="white"/>
<path d="M123.624 147.855C123.522 148.236 123.748 148.628 124.129 148.73C124.51 148.832 124.902 148.606 125.004 148.224C125.106 147.843 124.88 147.451 124.499 147.349C124.117 147.247 123.726 147.473 123.624 147.855Z" fill="#4F7065"/>
<path d="M121.494 158.101C118.567 155.644 116.179 158.938 120.259 161.191C120.259 161.191 121.405 159.752 121.494 158.101Z" fill="#4F7065"/>
<path opacity="0.7" d="M121.494 158.101C118.567 155.644 116.179 158.938 120.259 161.191C120.259 161.191 121.405 159.752 121.494 158.101Z" fill="white"/>
<path d="M118.99 158.616C118.888 158.997 119.114 159.389 119.496 159.491C119.877 159.593 120.269 159.367 120.371 158.985C120.473 158.604 120.246 158.212 119.865 158.11C119.485 158.008 119.092 158.235 118.99 158.616Z" fill="#4F7065"/>
<path d="M138.044 105.443C137.384 105.02 137.005 103.371 136.88 102.894C136.754 102.417 136.519 102.178 136.519 102.178L136.47 103.258C136.47 103.258 136.076 105.656 131.9 106.485C127.723 107.315 124.469 105.501 123.851 103.834L123.839 102.881C123.839 102.881 123.543 103 123.369 103.481C123.196 103.961 122.724 104.561 122.428 104.806C122.428 104.806 122.795 108.781 128.125 109.312C133.454 109.845 136.764 107.868 138.044 105.443Z" fill="#37474F"/>
<path d="M129.867 106.688C130.514 106.685 131.192 106.626 131.899 106.485C136.076 105.656 136.47 103.259 136.47 103.259L136.519 102.178C136.519 102.178 136.753 102.418 136.879 102.894C136.912 103.019 136.963 103.225 137.031 103.467C137.031 103.467 137.001 104.913 135.356 106.089C133.712 107.265 131.207 107.527 129.878 107.523L129.867 106.688Z" fill="#4F7065"/>
<path opacity="0.5" d="M129.867 106.688C130.514 106.685 131.192 106.626 131.899 106.485C136.076 105.656 136.47 103.259 136.47 103.259L136.519 102.178C136.519 102.178 136.753 102.418 136.879 102.894C136.912 103.019 136.963 103.225 137.031 103.467C137.031 103.467 137.001 104.913 135.356 106.089C133.712 107.265 131.207 107.527 129.878 107.523L129.867 106.688Z" fill="white"/>
<path d="M107.524 165.805C107.524 165.805 107.58 165.266 107.663 164.29C111.387 169.362 115.853 170.796 115.853 170.796C115.853 170.796 117.318 166.382 119.931 161.054C122.443 155.93 133.236 133.264 134.717 114.685L127.495 109.905L127.578 107.182C127.578 107.182 126.096 106.843 124.93 106.069C123.958 105.424 123.386 104.599 123.295 103.657C123.323 103.597 123.348 103.539 123.369 103.481C123.543 103 123.839 102.881 123.839 102.881L123.851 103.834C124.365 105.219 126.699 106.699 129.868 106.688L129.901 109.375L136.86 113.487C136.86 113.487 135.685 125.536 132.062 136.241C128.44 146.945 125.801 153.269 122.07 161.23C122.07 161.23 119.69 166.235 116.991 172.944C110.164 171.029 107.524 165.805 107.524 165.805Z" fill="#4F7065"/>
<path opacity="0.7" d="M107.524 165.805C107.524 165.805 107.58 165.266 107.663 164.29C111.387 169.362 115.853 170.796 115.853 170.796C115.853 170.796 117.318 166.382 119.931 161.054C122.443 155.93 133.236 133.264 134.717 114.685L127.495 109.905L127.578 107.182C127.578 107.182 126.096 106.843 124.93 106.069C123.958 105.424 123.386 104.599 123.295 103.657C123.323 103.597 123.348 103.539 123.369 103.481C123.543 103 123.839 102.881 123.839 102.881L123.851 103.834C124.365 105.219 126.699 106.699 129.868 106.688L129.901 109.375L136.86 113.487C136.86 113.487 135.685 125.536 132.062 136.241C128.44 146.945 125.801 153.269 122.07 161.23C122.07 161.23 119.69 166.235 116.991 172.944C110.164 171.029 107.524 165.805 107.524 165.805Z" fill="white"/>
<path d="M120.434 117.217L110.715 116.863C110.715 116.863 110.391 118.198 110.338 119.83L120.194 120.079C120.194 120.079 120.228 118.51 120.434 117.217Z" fill="#37474F"/>
<path d="M148.378 137.064L148.992 139.655L148.062 141.669C148.016 142.574 147.99 143.392 147.999 144.081C148.027 146.915 148.705 157.004 149.092 162.625V162.633C149.099 162.669 149.1 162.69 149.107 162.725C152.205 158.539 160.031 148.43 160.812 147.186C162.915 143.794 163.252 142.01 162.486 138.675C162.03 136.679 161.074 131.961 159.829 125.92C158.6 119.951 158.183 115.93 156.27 112.702C154.746 110.131 152.106 108.513 147.608 107.514C143.189 109.952 145.042 122.796 145.042 122.796L148.378 137.05C148.378 137.057 148.378 137.064 148.378 137.064Z" fill="#4F7065"/>
<path opacity="0.2" d="M148.378 137.064L148.992 139.655L148.062 141.669C148.016 142.574 147.99 143.392 147.999 144.081C148.027 146.915 148.705 157.004 149.092 162.625V162.633C149.099 162.669 149.1 162.69 149.107 162.725C152.205 158.539 160.031 148.43 160.812 147.186C162.915 143.794 163.252 142.01 162.486 138.675C162.03 136.679 161.074 131.961 159.829 125.92C158.6 119.951 158.183 115.93 156.27 112.702C154.746 110.131 152.106 108.513 147.608 107.514C143.189 109.952 145.042 122.796 145.042 122.796L148.378 137.05C148.378 137.057 148.378 137.064 148.378 137.064Z" fill="black"/>
<path opacity="0.3" d="M155.663 142.026C151.325 139.711 148.287 141.187 148.287 141.187L148.99 139.658C148.99 139.658 152.55 138.471 155.663 142.026Z" fill="black"/>
<path d="M138.354 105.28C137.263 105.766 135.797 107.934 135.797 107.934L148.264 110.5C148.264 110.5 150.226 107.915 151.025 108.531C151.025 108.531 151.124 108.028 148.846 107.499C145.832 106.8 138.354 105.28 138.354 105.28Z" fill="#4F7065"/>
<path opacity="0.7" d="M138.354 105.28C137.263 105.766 135.797 107.934 135.797 107.934L148.264 110.5C148.264 110.5 150.226 107.915 151.025 108.531C151.025 108.531 151.124 108.028 148.846 107.499C145.832 106.8 138.354 105.28 138.354 105.28Z" fill="white"/>
<path d="M121.798 106.723L110.683 109.422C110.683 109.422 110.742 107.608 113.077 106.565C115.411 105.522 123.034 104.101 123.034 104.101C123.034 104.101 122.046 105.173 121.798 106.723Z" fill="#4F7065"/>
<path opacity="0.7" d="M121.798 106.723L110.683 109.422C110.683 109.422 110.742 107.608 113.077 106.565C115.411 105.522 123.034 104.101 123.034 104.101C123.034 104.101 122.046 105.173 121.798 106.723Z" fill="white"/>
<path d="M82.455 228.967C82.455 228.967 85.3267 229.661 86.8322 231.901C88.3384 234.141 91.5495 240.761 92.2238 241.522C92.2238 241.522 91.4841 242.628 90.4423 243.129L88.6366 240.746C88.1782 240.308 88.0055 239.847 87.9257 241.164C87.8459 242.48 87.9369 243.851 87.755 245.802C87.6112 247.349 87.7125 250.311 84.8277 253.083C82.1313 255.674 79.3596 256.806 77.4866 256.927C76.3702 256.999 74.4435 256.594 74.7326 254.4C74.8863 253.235 75.7057 249.433 76.0563 247.314C76.4212 245.113 82.455 228.967 82.455 228.967Z" fill="#263238"/>
<path d="M82.8638 229.606C82.5276 229.107 81.508 227.037 81.0143 226.071C80.5198 225.106 78.5323 220.071 76.1668 212.374C73.1218 202.465 67.3816 194.893 65.71 191.13C63.9527 187.176 62.1725 182.624 61.1732 178.415L52.9662 183.029L42.6944 182.061C42.4747 183.58 42.9456 185.659 43.9141 188.118L43.9122 188.119C43.92 188.14 43.9305 188.162 43.9383 188.183C44.0266 188.406 44.1241 188.634 44.2202 188.863C46.1698 193.542 49.6687 199.284 53.6162 205.117C53.6476 205.164 53.679 205.211 53.7111 205.258C53.7575 205.326 53.8039 205.395 53.851 205.464C57.6534 211.105 61.8664 216.868 65.5465 221.862L65.5576 221.75C69.5124 227.099 72.7111 231.438 73.8065 233.579C77.0092 239.837 76.1511 245.662 75.9327 248.857C75.796 250.853 77.3237 251.349 79.5428 249.768C84.0632 246.545 84.0933 242.617 84.2816 241.304C85.2548 234.507 84.8094 232.5 82.8638 229.606Z" fill="#FFA8A7"/>
<path d="M60.5414 183.742L42.6938 182.061C42.6657 182.257 42.6493 182.462 42.6441 182.675C42.6421 182.752 42.648 182.835 42.6487 182.915C42.65 183.054 42.65 183.193 42.6598 183.34C42.667 183.446 42.682 183.558 42.6938 183.667C42.7075 183.795 42.7186 183.921 42.7382 184.054C42.7572 184.183 42.7834 184.317 42.8082 184.451C42.8298 184.57 42.852 184.705 42.8762 184.81C42.8854 184.849 43.3504 186.642 43.3909 186.679C45.6499 188.076 49.2554 189.411 55.4415 190.409C55.4415 190.409 54.6947 203.768 56.4965 209.316C56.5017 209.323 56.5063 209.33 56.5115 209.337C56.5141 209.342 56.5167 209.342 56.52 209.35C57.2682 210.422 58.0216 211.489 58.7776 212.549C59.7298 204.563 60.5414 183.742 60.5414 183.742Z" fill="#F28F8F"/>
<path d="M68.7079 242.285C68.7079 242.285 70.7308 244.579 70.7628 247.484C70.7948 250.389 69.8531 258.256 69.9996 259.338C69.9996 259.338 68.774 259.912 67.6282 259.762L67.4235 256.554C67.2803 255.89 67.3882 255.372 66.6001 256.52C65.812 257.668 65.1358 258.964 63.9148 260.628C62.9455 261.948 61.4067 264.693 57.4729 265.534C53.7961 266.32 50.8557 265.74 49.222 264.763C48.2482 264.181 46.8578 262.696 48.3031 260.874C49.0703 259.907 51.8406 256.934 53.2958 255.215C54.8072 253.43 68.7079 242.285 68.7079 242.285Z" fill="#263238"/>
<path d="M58.9424 241.452C59.6932 233.919 55.1976 198.873 58.3381 182.805L78.616 184.095C77.1439 188.492 76.1387 193.652 75.4409 198.258C74.7771 202.64 75.4291 212.837 72.5443 223.589C70.3037 231.941 69.2063 237.661 69.0905 238.823C68.9748 239.985 68.6929 242.453 68.7001 243.101C68.7419 246.853 68.014 248.933 63.4733 254.531C62.5957 255.614 60.4165 259.158 54.8667 259.46C52.1428 259.608 51.1363 258.272 52.3455 256.54C54.28 253.77 58.1916 248.985 58.9424 241.452Z" fill="#FFA8A7"/>
<path d="M75.4409 198.257C75.2689 199.967 75.2427 201.682 75.2054 203.399C75.1596 205.115 75.1067 206.833 75.0249 208.552C74.8464 211.988 74.4873 215.423 73.8765 218.816C73.5698 220.512 73.1604 222.188 72.7222 223.849L71.4103 228.812C70.9786 230.468 70.5666 232.13 70.1749 233.798C69.7766 235.463 69.3816 237.132 69.0905 238.822C69.4993 235.406 70.2632 232.051 71.0211 228.706C71.7719 225.351 72.8013 222.081 73.3638 218.712C73.694 217.036 73.9262 215.34 74.1414 213.643C74.3441 211.943 74.4815 210.235 74.6253 208.527C74.7555 206.818 74.8523 205.104 74.9504 203.389C75.0733 201.678 75.1511 199.952 75.4409 198.257Z" fill="#F28F8F"/>
<path d="M57.363 191.809C57.5252 188.448 57.8339 185.384 58.3381 182.805L78.616 184.095C77.9097 186.205 77.3133 188.492 76.8038 190.808H76.8018C76.8018 190.808 68.721 194.146 57.363 191.809Z" fill="#F28F8F"/>
<path d="M42.2556 150.369C42.2556 150.369 42.9083 143.195 44.4583 136.478C45.2679 132.969 46.024 129.93 46.5583 127.242C52.4685 131.177 69.3659 131.712 76.1269 127.283C77.259 129.052 78.393 133.295 81.0515 139.577C83.3745 145.066 84.3281 148.499 84.2202 155.317C84.1221 161.556 81.3328 179.896 78.7965 187.737C71.9302 191.543 47.8571 190.665 41.0418 183.847C41.0418 183.847 41.4433 156.83 42.2556 150.369Z" fill="#4F7065"/>
<path opacity="0.5" d="M42.2556 150.369C42.2556 150.369 42.9083 143.195 44.4583 136.478C45.2679 132.969 46.024 129.93 46.5583 127.242C52.4685 131.177 69.3659 131.712 76.1269 127.283C77.259 129.052 78.393 133.295 81.0515 139.577C83.3745 145.066 84.3281 148.499 84.2202 155.317C84.1221 161.556 81.3328 179.896 78.7965 187.737C71.9302 191.543 47.8571 190.665 41.0418 183.847C41.0418 183.847 41.4433 156.83 42.2556 150.369Z" fill="white"/>
<path d="M55.2813 94.5684C47.9899 95.0216 44.9265 99.6944 45.2588 107.408C45.5197 113.462 46.1364 121.709 46.1364 121.709L56.5331 128.207L55.2813 94.5684Z" fill="#4F7065"/>
<path opacity="0.3" d="M55.2813 94.5684C47.9899 95.0216 44.9265 99.6944 45.2588 107.408C45.5197 113.462 46.1364 121.709 46.1364 121.709L56.5331 128.207L55.2813 94.5684Z" fill="white"/>
<path d="M28.9984 125.224C29.9035 124.721 48.054 119.845 48.054 119.845L49.944 128.355C46.122 128.981 24.0097 132.402 24.0097 132.402C24.0339 131.508 23.9998 131.135 24.132 130.858C25.1502 128.721 28.2842 125.621 28.9984 125.224Z" fill="#FFA8A7"/>
<path d="M57.5062 83.8016C57.5468 83.4825 57.5265 83.1653 57.554 82.8501C57.5808 82.5473 57.6141 82.2261 57.3957 81.977C57.3087 81.8776 57.1537 81.839 57.0432 81.767C56.6678 81.5231 56.295 81.2746 55.9209 81.028C54.3566 79.998 52.7915 78.9686 51.2272 77.9385C47.3993 75.7221 47.6602 72.1735 48.691 69.5562C50.9917 63.714 57.45 61.7062 61.4086 61.6166C68.9166 61.4473 72.5155 63.4995 73.1643 67.4065C73.163 67.3993 73.6842 67.4183 73.732 67.4242C75.2538 67.5975 76.416 69.4332 77.0209 70.7144C77.6409 72.0283 77.9496 73.473 78.0706 74.921C78.3884 78.7285 77.3479 82.7265 74.8163 85.5871C73.0943 87.532 70.7903 88.8512 68.3777 89.7511C67.2567 90.1696 65.9552 90.7327 64.761 90.6189C63.3772 90.4875 62.06 90.2592 60.8213 89.5876C58.7429 88.4627 57.208 86.1456 57.5062 83.8016Z" fill="#263238"/>
<path d="M77.6723 84.056C79.221 85.0324 80.0679 86.946 79.9293 88.772C79.79 90.598 78.7403 92.2944 77.2747 93.3925C75.8091 94.4906 73.9635 95.019 72.1329 95.0628C71.0198 95.0896 69.8152 94.9046 69.0212 94.1243C68.2789 93.3951 68.0474 92.2971 67.9565 91.2605C67.9061 90.6836 67.8885 90.099 67.9787 89.5267C68.3083 87.4333 70.0166 85.8192 71.8406 84.7407C73.5331 83.7401 75.8496 82.9069 77.6723 84.056Z" fill="#263238"/>
<path d="M57.2204 94.8614C57.2204 94.8614 57.603 94.8065 58.4035 94.6816C59.4152 94.524 59.2923 93.3749 59.3551 92.3775L59.4015 90.8249C57.0216 90.9793 55.7555 90.5686 54.6345 89.7857C52.7523 88.4725 51.0865 86.5347 50.9983 80.0993C50.9629 77.501 51.6889 76.2675 51.6346 75.6842C51.3109 72.2134 51.5627 71.5875 52.32 69.7661C53.6901 66.8604 57.329 65.7087 58.9993 68.7354C63.4262 65.0684 68.5765 69.5654 68.5418 74.9334C68.0598 79.3767 69.4293 79.5572 70.2926 79.5598C71.4848 76.7626 74.3454 76.7201 75.6004 78.4683C76.7364 80.0496 76.7796 82.5355 74.7457 84.8618C72.7647 87.1285 70.4463 85.7754 70.4044 85.3294C70.4044 85.3294 70.2461 91.3063 70.235 92.4979C70.2239 93.6895 70.3501 94.6816 71.8445 95.1263C71.8491 95.1276 71.8543 95.1296 71.8589 95.1309C73.3827 95.5841 74.0263 95.4736 74.0263 95.4736C74.0263 95.4736 73.9144 95.6292 73.6901 95.8895C73.1754 96.5278 72.044 97.9339 70.135 100.33C65.1541 102.367 59.2184 105.176 55.7241 107.403C54.4461 104.48 53.2029 99.6879 53.2029 99.6879C55.0007 96.9137 57.2204 94.8614 57.2204 94.8614Z" fill="#FFA8A7"/>
<path d="M73.5037 83.9155C73.5259 84.4969 73.0727 84.9855 72.4913 85.0077C71.9099 85.0299 71.4214 84.5767 71.3991 83.9953C71.3769 83.4139 71.8308 82.9254 72.4115 82.9031C72.9929 82.8809 73.4815 83.3341 73.5037 83.9155Z" fill="#4F7065"/>
<path d="M61.6552 83.0508L58.1726 84.0919C58.4421 85.088 59.4401 85.6622 60.4015 85.3751C61.3635 85.088 61.9246 84.0475 61.6552 83.0508Z" fill="#B16668"/>
<path d="M61.4204 83.8475C60.4518 83.8063 59.6154 84.4616 59.393 85.3694C59.7108 85.4668 60.0575 85.478 60.4015 85.3759C61.0914 85.1699 61.5695 84.5741 61.6879 83.8868C61.5996 83.8704 61.5126 83.8515 61.4204 83.8475Z" fill="#F28F8F"/>
<path d="M52.7843 73.8104L55.3598 73.0302C55.1695 72.3055 54.439 71.8922 53.7274 72.108C53.0165 72.3238 52.594 73.0858 52.7843 73.8104Z" fill="#263238"/>
<path d="M64.4765 74.3428L61.9508 73.3212C62.2085 72.6077 62.9835 72.2572 63.6813 72.5397C64.3784 72.8222 64.7349 73.6299 64.4765 74.3428Z" fill="#263238"/>
<path d="M55.6404 76.5421C55.6757 77.1418 55.2126 77.6689 54.6064 77.7213C54.0008 77.7729 53.4808 77.3282 53.4462 76.7291C53.4109 76.1294 53.8739 75.6017 54.4795 75.55C55.0858 75.4983 55.6057 75.9424 55.6404 76.5421Z" fill="#263238"/>
<path d="M63.6178 76.6874C63.6538 77.2995 63.181 77.8378 62.5629 77.8908C61.9443 77.9437 61.4139 77.4899 61.3785 76.8784C61.3426 76.2662 61.8154 75.7273 62.4334 75.675C63.0515 75.6227 63.5819 76.0759 63.6178 76.6874Z" fill="#263238"/>
<path d="M59.4015 90.8254C63.6728 90.4729 67.7864 89.3742 68.8747 86.7856C68.8747 86.7856 68.8525 89.1002 66.381 90.3794C62.8121 92.2269 59.3642 92.0772 59.3642 92.0772L59.4015 90.8254Z" fill="#F28F8F"/>
<path d="M54.9864 80.104L58.5009 81.8586L59.3479 75.042L54.9864 80.104Z" fill="#F28F8F"/>
<path d="M58.3976 94.4341C58.3976 94.4341 54.9125 100.971 56.2074 103.361C62.6028 102.635 69.5863 97.0815 71.7673 95.0763L77.136 96.0037C77.136 96.0037 80.6611 102.636 79.5696 108.343C78.478 114.05 76.1256 127.283 76.1256 127.283C70.0441 136.123 47.1089 131.276 46.5583 127.244C46.5583 127.244 45.9481 122.234 45.4334 118.106C45.4334 118.106 42.5362 115.322 42.3923 111.592C42.2419 107.682 43.8546 104.945 47.8002 100.913C51.8491 96.7754 55.2807 94.5675 55.2807 94.5675L58.3976 94.4341Z" fill="#4F7065"/>
<path opacity="0.5" d="M58.3976 94.4341C58.3976 94.4341 54.9125 100.971 56.2074 103.361C62.6028 102.635 69.5863 97.0815 71.7673 95.0763L77.136 96.0037C77.136 96.0037 80.6611 102.636 79.5696 108.343C78.478 114.05 76.1256 127.283 76.1256 127.283C70.0441 136.123 47.1089 131.276 46.5583 127.244C46.5583 127.244 45.9481 122.234 45.4334 118.106C45.4334 118.106 42.5362 115.322 42.3923 111.592C42.2419 107.682 43.8546 104.945 47.8002 100.913C51.8491 96.7754 55.2807 94.5675 55.2807 94.5675L58.3976 94.4341Z" fill="white"/>
<path d="M61.6598 101.773C63.247 101.011 64.2509 98.5008 64.6885 97.128C65.126 95.7553 66.3882 95.219 68.6334 93.7789C69.8786 92.9804 70.2403 92.0804 70.2403 92.0804L70.2782 90.3081C70.2782 90.3081 71.1689 90.367 71.1251 92.0183C71.0813 93.6697 72.3193 95.1726 72.3193 95.1726C72.3193 95.1726 68.9074 98.9383 61.6598 101.773Z" fill="#EBEBEB"/>
<path d="M56.1354 100.258C56.208 98.2387 56.5533 95.573 57.072 94.4343C57.5906 93.2957 59.3943 91.0669 59.3943 91.0669L59.3067 93.4246C59.3067 93.4246 59.3479 94.2041 58.4892 95.3355C57.1426 97.1085 56.6161 98.4754 56.1354 100.258Z" fill="#EBEBEB"/>
<path d="M76.3323 126.124L76.9457 128.939C76.9457 128.939 75.0452 132.01 68.3639 133.002C69.7066 146.757 70.734 161.176 70.1023 176.102C70.1023 176.102 62.6597 177.092 54.7293 176.102C46.7989 175.113 39.9411 171.341 39.9411 171.341C39.9411 171.341 40.7435 152.507 42.3831 143.587C44.0227 134.666 46.3457 125.504 46.3457 125.504C46.3457 125.504 48.5824 128.619 56.9098 129.71C65.2372 130.8 72.5535 130.006 76.3323 126.124Z" fill="#F5F5F5"/>
<path opacity="0.3" d="M68.3639 117.082C68.3639 117.082 66.0788 121.484 60.3282 121.006C55.0681 120.57 53.2035 116.002 53.2035 116.002C53.2035 116.002 53.1054 122.064 60.2524 122.454C67.3895 122.845 68.3639 117.082 68.3639 117.082Z" fill="#4F7065"/>
<path d="M77.2727 96.4883C81.3622 97.3659 83.7427 99.3162 85.3215 103.484C88.7936 112.651 92.783 125.236 93.4729 128.099C94.6907 133.15 93.7431 134.326 90.4469 136.442C87.1507 138.559 72.4959 143.413 70.167 145.26C65.7885 148.732 58.5114 151.29 53.4547 147.63C51.7072 146.365 50.5156 144.246 50.6706 142.094C50.6942 141.77 50.7511 141.436 50.9289 141.164C51.2723 140.639 51.9544 140.479 52.5698 140.361C53.7124 140.143 54.8549 139.925 55.9974 139.707C59.1942 139.097 63.3438 139.548 66.3189 138.164C68.5183 137.14 70.2972 135.713 72.6005 134.213C75.7155 132.184 81.2765 127.743 81.2765 127.743L75.0452 112.236C75.0452 112.236 73.5312 102.265 77.2727 96.4883Z" fill="#FFA8A7"/>
<path d="M78.6769 122.343C78.9365 122.393 79.2001 122.44 79.4708 122.474C81.4505 122.726 83.523 122.318 85.451 121.83C87.7158 121.255 89.4907 119.563 90.5143 117.83C89.8066 115.67 89.7321 114.849 88.9911 112.571C86.7871 105.795 85.9323 103.089 83.952 99.8368C81.9717 96.5845 79.0019 96.5237 77.1151 96.0044C71.6124 103.615 74.781 112.571 74.781 112.571L78.6769 122.343Z" fill="#4F7065"/>
<path opacity="0.3" d="M78.6769 122.343C78.9365 122.393 79.2001 122.44 79.4708 122.474C81.4505 122.726 83.523 122.318 85.451 121.83C87.7158 121.255 89.4907 119.563 90.5143 117.83C89.8066 115.67 89.7321 114.849 88.9911 112.571C86.7871 105.795 85.9323 103.089 83.952 99.8368C81.9717 96.5845 79.0019 96.5237 77.1151 96.0044C71.6124 103.615 74.781 112.571 74.781 112.571L78.6769 122.343Z" fill="white"/>
<path d="M90.9433 118.314C90.9433 118.314 89.9152 120.638 85.6093 122.235C81.304 123.839 78.7534 122.955 78.7534 122.955L77.6494 120.487C77.6494 120.487 80.9606 120.789 84.6969 119.493C85.8486 119.095 86.8015 118.568 87.5693 118.04L88.9813 118.945L88.7825 117.059C89.6529 116.25 90.065 115.613 90.065 115.613L90.9433 118.314Z" fill="#EBEBEB"/>
<path d="M81.2765 127.744C83.0744 126.369 86.43 125.85 88.7622 126.827C87.3567 125.246 84.3876 124.947 80.769 126.482L81.2765 127.744Z" fill="#F28F8F"/>
<path d="M64.3575 141.343V144.318C64.3575 144.786 64.1084 145.218 63.7035 145.451L42.3903 157.756C41.8939 158.042 41.4473 158.073 41.1327 157.891L18.5174 144.834C18.2316 144.666 18.0569 144.321 18.0569 143.83C18.0569 142.8 18.828 141.52 19.7789 140.971L41.7461 128.288L64.3575 141.343Z" fill="#E6E6E6"/>
<path d="M42.3903 154.026L64.3575 141.343V144.318C64.3575 144.786 64.1083 145.218 63.7035 145.451L42.3903 157.756C41.4394 158.305 40.6683 157.915 40.669 156.885C40.669 155.856 41.4394 154.576 42.3903 154.026Z" fill="#E0E0E0"/>
<path opacity="0.1" d="M43.3962 154.689C43.079 154.872 42.822 155.299 42.822 155.642C42.822 155.985 43.079 156.116 43.3962 155.932L64.3582 143.83V142.587L43.3962 154.689Z" fill="black"/>
<path d="M64.3575 137.3V140.275C64.3575 140.742 64.1083 141.174 63.7035 141.407L42.3903 153.712C41.8939 153.999 41.4473 154.03 41.1327 153.847L18.5167 140.789C18.2309 140.621 18.0563 140.276 18.0563 139.785C18.0563 138.755 18.8274 137.476 19.7783 136.926L41.7455 124.243L64.3575 137.3Z" fill="#F0F0F0"/>
<path d="M42.3903 149.983L64.3575 137.3V140.274C64.3575 140.742 64.1083 141.173 63.7035 141.407L42.3903 153.712C41.4394 154.261 40.6683 153.871 40.669 152.841C40.669 151.812 41.4394 150.532 42.3903 149.983Z" fill="#E0E0E0"/>
<path opacity="0.1" d="M43.3962 150.646C43.079 150.829 42.822 151.255 42.822 151.599C42.822 151.942 43.079 152.072 43.3962 151.889L64.3582 139.787V138.543L43.3962 150.646Z" fill="black"/>
<path d="M41.582 149.353C41.4701 149.353 41.3714 149.329 41.289 149.281L18.6724 136.223C18.4762 136.108 18.3669 135.846 18.3669 135.488C18.3669 134.574 19.0844 133.388 19.9339 132.897L41.7461 120.305L64.0482 133.181V135.976C64.0482 136.332 63.8572 136.663 63.5492 136.84L42.2353 149.145C42.0005 149.281 41.7743 149.353 41.582 149.353Z" fill="#FAFAFA"/>
<path d="M41.7455 120.663L63.7369 133.359V135.976C63.7369 136.221 63.6054 136.449 63.3929 136.571L42.0797 148.876C41.8946 148.983 41.718 149.042 41.5813 149.042C41.5244 149.042 41.478 149.032 41.4427 149.011L18.8306 135.956C18.7351 135.899 18.6763 135.72 18.6763 135.487C18.6763 134.684 19.3355 133.599 20.0876 133.165L41.7455 120.663ZM41.7455 119.946L19.7783 132.629C18.8274 133.178 18.0563 134.458 18.0563 135.488C18.0563 135.979 18.2309 136.324 18.5167 136.492L41.1327 149.55C41.2615 149.624 41.4132 149.664 41.5813 149.664C41.8226 149.664 42.098 149.584 42.3903 149.414L63.7035 137.109C64.1083 136.876 64.3575 136.444 64.3575 135.977V133.002L41.7455 119.946Z" fill="#E0E0E0"/>
<path d="M42.3903 145.684L64.3575 133.001V135.976C64.3575 136.443 64.1083 136.875 63.7035 137.109L42.3903 149.414C41.4394 149.963 40.6683 149.573 40.669 148.543C40.669 147.513 41.4394 146.233 42.3903 145.684Z" fill="#E0E0E0"/>
<path opacity="0.1" d="M43.3962 146.347C43.079 146.53 42.822 146.956 42.822 147.3C42.822 147.643 43.079 147.773 43.3962 147.59L64.3582 135.488V134.245L43.3962 146.347Z" fill="black"/>
<path d="M32.874 125.068L55.4834 138.137L57.2492 137.118L34.6404 124.049L32.874 125.068Z" fill="#4F7065"/>
<path d="M69.067 136.601C67.0213 137.56 65.0815 137.22 63.8677 137.636C62.627 138.06 61.5571 138.727 60.6585 139.441C58.8809 140.851 56.7038 141.91 55.8529 142.38C55.0014 142.85 56.1485 143.955 57.7358 143.896C59.323 143.838 62.2445 142.373 63.3026 142.536C64.3608 142.699 64.3575 143.232 64.3575 143.232L69.067 136.601Z" fill="#FFA8A7"/>
<path d="M127.918 37.9799C124.21 32.5818 114.297 28.1045 102.039 27.9888C90.889 27.8835 77.3178 31.162 73.9262 41.8738C71.0336 51.0102 79.9391 59.4985 89.1853 61.3061C93.1957 62.0903 96.932 62.4278 100.344 62.4853C102.057 65.6866 106.077 67.6545 109.104 67.8592C107.539 66.0058 107.129 63.8685 107.095 62.2302C110.504 61.9098 113.401 61.348 115.663 60.7829C131.714 56.772 132.067 44.0203 127.918 37.9799Z" fill="#4F7065"/>
<path opacity="0.4" d="M127.918 37.9799C124.21 32.5818 114.297 28.1045 102.039 27.9888C90.889 27.8835 77.3178 31.162 73.9262 41.8738C71.0336 51.0102 79.9391 59.4985 89.1853 61.3061C93.1957 62.0903 96.932 62.4278 100.344 62.4853C102.057 65.6866 106.077 67.6545 109.104 67.8592C107.539 66.0058 107.129 63.8685 107.095 62.2302C110.504 61.9098 113.401 61.348 115.663 60.7829C131.714 56.772 132.067 44.0203 127.918 37.9799Z" fill="white"/>
<path d="M80.1739 50.0573C80.0836 50.0161 80.03 49.9507 80.0143 49.8605L78.4009 41.9543C78.3924 41.9216 78.3897 41.8863 78.3943 41.8496C78.3983 41.8124 78.4009 41.7862 78.4009 41.7698C78.4009 41.5566 78.6428 41.4494 79.1268 41.4494H79.4839C79.977 41.4494 80.2393 41.5769 80.272 41.8313L80.8514 46.3753L80.9619 47.2615L81.0731 48.2222L81.2948 46.7324C81.3275 46.5682 81.3478 46.4492 81.3563 46.3753L82.1071 41.7077C82.1234 41.6096 82.1751 41.5416 82.2608 41.5043C82.3471 41.467 82.489 41.4487 82.6859 41.4487H83.3013C83.4981 41.4487 83.6401 41.467 83.7257 41.5043C83.8121 41.5416 83.8631 41.609 83.8794 41.7077L84.6184 46.3753L84.8892 48.2595L85.0619 46.9417L85.1358 46.3753L85.7263 41.8313C85.759 41.5769 86.0219 41.4494 86.5144 41.4494H86.7982C87.2822 41.4494 87.5242 41.5566 87.5242 41.7698C87.5242 41.8431 87.5202 41.9052 87.5117 41.9543L85.8735 49.8605C85.8408 50.0331 85.6478 50.1195 85.2947 50.1195H84.4824C84.1534 50.1195 83.969 50.0331 83.9278 49.8605L83.0907 45.5133L82.9553 44.1955L82.8069 45.5133L81.9573 49.8605C81.924 50.0331 81.7395 50.1195 81.4027 50.1195H80.5905C80.4041 50.1195 80.2648 50.0985 80.1739 50.0573Z" fill="#FAFAFA"/>
<path d="M88.5241 49.9774C88.4502 49.8996 88.413 49.774 88.413 49.602V41.9666C88.413 41.7939 88.4502 41.6697 88.5241 41.5912C88.5974 41.5134 88.7125 41.4741 88.8681 41.4741H92.5011C92.6241 41.4741 92.715 41.5232 92.7725 41.6219C92.8294 41.7207 92.8588 41.877 92.8588 42.0902V42.4721C92.8588 42.6853 92.8294 42.841 92.7725 42.9404C92.715 43.0385 92.6241 43.0882 92.5011 43.0882H90.3338V44.8736H92.2552C92.3788 44.8736 92.4684 44.9227 92.526 45.0214C92.5835 45.1195 92.6123 45.2765 92.6123 45.4897V45.871C92.6123 46.0848 92.5835 46.2405 92.526 46.3392C92.4684 46.4373 92.3782 46.4877 92.2552 46.4877H90.3338V48.4824H92.5135C92.6365 48.4824 92.7248 48.5314 92.7777 48.6308C92.8314 48.7289 92.8582 48.8852 92.8582 49.0985V49.4804C92.8582 49.6936 92.8314 49.8492 92.7777 49.9487C92.7248 50.0468 92.6365 50.0965 92.5135 50.0965H88.8681C88.7125 50.0945 88.5974 50.0553 88.5241 49.9774Z" fill="#FAFAFA"/>
<path d="M94.2748 49.9772C94.2009 49.8994 94.1636 49.7738 94.1636 49.6018V41.8434C94.1636 41.5812 94.4016 41.4497 94.8777 41.4497H95.3709C95.8463 41.4497 96.085 41.5812 96.085 41.8434V48.4809H97.9202C98.051 48.4809 98.1458 48.5299 98.2033 48.6293C98.2609 48.7274 98.2897 48.8837 98.2897 49.0969V49.4789C98.2897 49.6921 98.2609 49.8477 98.2033 49.9471C98.1458 50.0452 98.0516 50.0949 97.9202 50.0949H94.6194C94.4631 50.0943 94.348 50.055 94.2748 49.9772Z" fill="#FAFAFA"/>
<path d="M99.6003 49.1272C99.0784 48.4176 98.8181 47.2947 98.8181 45.7597C98.8181 44.2817 99.0915 43.1823 99.6376 42.4597C100.183 41.7376 101.027 41.376 102.168 41.376C102.554 41.376 102.911 41.4211 103.239 41.5114C103.494 41.5853 103.621 41.7618 103.621 42.0411C103.621 42.3537 103.565 42.6317 103.455 42.8782C103.344 43.1248 103.206 43.219 103.042 43.1621C102.779 43.0796 102.516 43.0391 102.254 43.0391C101.762 43.0391 101.394 43.2647 101.152 43.716C100.91 44.1673 100.788 44.8533 100.788 45.7728C100.788 46.6923 100.91 47.3797 101.152 47.8355C101.394 48.2907 101.782 48.519 102.315 48.519C102.471 48.519 102.602 48.5085 102.709 48.4882C102.816 48.468 102.956 48.4326 103.129 48.3836C103.153 48.3751 103.19 48.3712 103.239 48.3712C103.403 48.3712 103.526 48.4732 103.609 48.6792C103.69 48.8839 103.732 49.1553 103.732 49.4915C103.732 49.6144 103.707 49.7197 103.658 49.8054C103.609 49.8917 103.514 49.9591 103.375 50.0081C103.235 50.0572 103.051 50.101 102.821 50.137C102.59 50.1742 102.352 50.1926 102.106 50.1926C100.957 50.1926 100.122 49.8374 99.6003 49.1272Z" fill="#FAFAFA"/>
<path d="M104.311 45.7846C104.311 42.8455 105.386 41.376 107.537 41.376C108.589 41.376 109.387 41.7331 109.933 42.4472C110.478 43.1621 110.752 44.2745 110.752 45.7852C110.752 47.296 110.478 48.4078 109.933 49.122C109.387 49.8361 108.588 50.1932 107.537 50.1932C105.386 50.1926 104.311 48.723 104.311 45.7846ZM108.263 48.3339C108.444 48.1697 108.579 47.8839 108.669 47.4778C108.76 47.071 108.805 46.5073 108.805 45.7846C108.805 45.0698 108.76 44.5099 108.669 44.1038C108.579 43.697 108.444 43.4119 108.263 43.2477C108.083 43.0836 107.844 43.0012 107.549 43.0012C107.253 43.0012 107.013 43.0836 106.828 43.2477C106.644 43.4119 106.504 43.697 106.409 44.1038C106.314 44.5099 106.267 45.0704 106.267 45.7846C106.267 46.4988 106.314 47.0605 106.409 47.4713C106.504 47.882 106.643 48.1691 106.828 48.3332C107.013 48.4974 107.253 48.5791 107.549 48.5791C107.845 48.5791 108.083 48.4974 108.263 48.3339Z" fill="#FAFAFA"/>
<path d="M115.173 47.2803C115.099 47.2358 115.033 47.1593 114.975 47.0527L114.063 45.3529C114.014 45.262 113.965 45.1574 113.916 45.039C113.867 44.92 113.829 44.8324 113.805 44.7742L113.755 44.7872C113.805 45.0822 113.829 45.3085 113.829 45.4641V49.7256C113.829 49.8649 113.774 49.9656 113.663 50.0271C113.552 50.0886 113.37 50.12 113.115 50.12H112.696C112.441 50.12 112.258 50.0886 112.148 50.0271C112.037 49.9656 111.982 49.8649 111.982 49.7256V41.8436C111.982 41.7043 112.037 41.6036 112.148 41.5421C112.258 41.4806 112.441 41.4492 112.696 41.4492H113.078C113.275 41.4492 113.43 41.476 113.546 41.5297C113.661 41.5833 113.751 41.6716 113.817 41.7939L115.343 44.6878C115.426 44.8363 115.504 45.0325 115.577 45.279C115.635 45.0573 115.708 44.8598 115.799 44.6878L117.326 41.7939C117.392 41.6709 117.482 41.5833 117.597 41.5297C117.712 41.476 117.868 41.4492 118.065 41.4492H118.447C118.702 41.4492 118.884 41.4806 118.995 41.5421C119.106 41.6036 119.161 41.7043 119.161 41.8436V49.7256C119.161 49.8649 119.106 49.9656 118.995 50.0271C118.884 50.0886 118.702 50.12 118.447 50.12H118.028C117.773 50.12 117.591 50.0886 117.48 50.0271C117.369 49.9656 117.313 49.8649 117.313 49.7256V45.4641C117.313 45.2673 117.342 45.0416 117.4 44.7872L117.35 44.7742C117.293 44.9305 117.203 45.1234 117.08 45.3536L116.168 47.0533C116.111 47.1599 116.045 47.2358 115.971 47.2809C115.897 47.3261 115.799 47.349 115.676 47.349H115.466C115.345 47.3483 115.246 47.3254 115.173 47.2803Z" fill="#FAFAFA"/>
<path d="M120.764 49.9774C120.69 49.8996 120.653 49.774 120.653 49.602V41.9666C120.653 41.7939 120.69 41.6697 120.764 41.5912C120.837 41.5134 120.952 41.4741 121.108 41.4741H124.741C124.864 41.4741 124.955 41.5232 125.012 41.6219C125.069 41.7207 125.098 41.877 125.098 42.0902V42.4721C125.098 42.6853 125.069 42.841 125.012 42.9404C124.955 43.0385 124.864 43.0882 124.741 43.0882H122.573V44.8736H124.495C124.618 44.8736 124.708 44.9227 124.766 45.0214C124.823 45.1195 124.852 45.2765 124.852 45.4897V45.871C124.852 46.0848 124.823 46.2405 124.766 46.3392C124.708 46.4373 124.618 46.4877 124.495 46.4877H122.573V48.4824H124.753C124.876 48.4824 124.964 48.5314 125.017 48.6308C125.071 48.7289 125.098 48.8852 125.098 49.0985V49.4804C125.098 49.6936 125.071 49.8492 125.017 49.9487C124.964 50.0468 124.876 50.0965 124.753 50.0965H121.108C120.952 50.0945 120.837 50.0553 120.764 49.9774Z" fill="#FAFAFA"/>
</svg>

  )
}

export default RequestConfirmation
