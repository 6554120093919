// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/fonts/Montserrat/Montserrat-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@assets/fonts/Montserrat/Montserrat-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@assets/fonts/Montserrat/Montserrat-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@assets/fonts/Montserrat/Montserrat-Thin.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@assets/fonts/Noto Sans/notosans-extrabold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "MontserratMedium";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");
}
@font-face {
    font-family: "MontserratRegular";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
}
@font-face {
    font-family: "MontserratSemiBold";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
}
@font-face {
    font-family: "MontserratThin";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
}
@font-face {
    font-family: "NotoSans";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2");
}`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,4DAA2E;AAC/E;AACA;IACI,gCAAgC;IAChC,4DAA4E;AAChF;AACA;IACI,iCAAiC;IACjC,4DAA6E;AACjF;AACA;IACI,6BAA6B;IAC7B,4DAAyE;AAC7E;AACA;IACI,uBAAuB;IACvB,4DAA2E;AAC/E","sourcesContent":["@font-face {\n    font-family: \"MontserratMedium\";\n    src: url(\"@assets/fonts/Montserrat/Montserrat-Medium.woff\") format(\"woff2\");\n}\n@font-face {\n    font-family: \"MontserratRegular\";\n    src: url(\"@assets/fonts/Montserrat/Montserrat-Regular.woff\") format(\"woff2\");\n}\n@font-face {\n    font-family: \"MontserratSemiBold\";\n    src: url(\"@assets/fonts/Montserrat/Montserrat-SemiBold.woff\") format(\"woff2\");\n}\n@font-face {\n    font-family: \"MontserratThin\";\n    src: url(\"@assets/fonts/Montserrat/Montserrat-Thin.woff\") format(\"woff2\");\n}\n@font-face {\n    font-family: \"NotoSans\";\n    src: url(\"@assets/fonts/Noto Sans/notosans-extrabold.woff\") format(\"woff2\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
