import React from 'react'

const DownArrow = () => {
  return (
    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5883 0.703243C12.5888 0.79526 12.5712 0.886479 12.5364 0.971666C12.5016 1.05685 12.4503 1.13433 12.3855 1.19967L6.79199 6.79318C6.72699 6.85871 6.64966 6.91073 6.56446 6.94623C6.47925 6.98172 6.38786 7 6.29556 7C6.20326 7 6.11187 6.98172 6.02667 6.94623C5.94147 6.91073 5.86414 6.85871 5.79914 6.79318L0.205625 1.19967C0.0739656 1.06801 -2.67101e-07 0.889439 -2.7524e-07 0.703243C-2.83379e-07 0.517048 0.0739655 0.33848 0.205625 0.20682C0.337285 0.0751595 0.515855 0.0011935 0.70205 0.00119349C0.888245 0.00119348 1.06681 0.0751595 1.19847 0.206819L6.29556 5.3109L11.3927 0.206819C11.4577 0.141285 11.535 0.0892682 11.6202 0.0537715C11.7054 0.0182743 11.7968 -5.15653e-07 11.8891 -5.19688e-07C11.9814 -5.23723e-07 12.0728 0.0182743 12.158 0.0537714C12.2432 0.0892681 12.3205 0.141285 12.3855 0.206819C12.4503 0.272151 12.5016 0.349631 12.5364 0.434819C12.5712 0.520006 12.5888 0.611225 12.5883 0.703243Z" fill="#4F7065"/>
</svg>

  )
}

export default DownArrow
