import BottomFooter from '@/components/BottomFooter/BottomFooter';
import LandingHeader from '@/components/LandingHeader';
import { useGetClientProducts } from '@/hooks/useGetClientProducts';
import { getQueryParam } from '@/utils/routerUtils';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { defaultClientId, defaultSource } from '@/utils/constants';

import ContactCard from '@/components/ContactCard/ContactCard';
import { Email } from '@/assets/icons/Email';
import { Location } from '@/assets/icons/Location';
import { Call } from '@/assets/icons/Call';
export const ContactUs = () => {
  const location = useLocation();
  const localClientId =
    window.localStorage.getItem('clientId') || defaultClientId;
  const localSource = window.localStorage.getItem('source') || defaultSource;
  const clientId =
    getQueryParam(location.search, 'clientid') ||
    getQueryParam(location.search, 'clientId') ||
    localClientId;
  const source = getQueryParam(location.search, 'source') || localSource;
  const [onlyReadMenu, setOnlyReadMenu] = useState(false);
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-3 p-2">
        <LandingHeader
          clientName={'The River Lap'}
          onlyReadMenu={onlyReadMenu}
        />
      </div>
      {/* <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 h-[70vh]"> */}
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col gap-[24px] w-[90%] mt-12 justify-center items-center">
          <h3 className="uppercase text-green-arapawa underline underline-offset-2 tracking-[5px] text-start text-[12px] md:text-[16px] md:text-center">
            GET IN TOUCH
          </h3>
        </div>

        <div className="flex flex-wrap justify-center w-[90%] gap-[16px] md:gap-10 mt-16 mb-[60px]">
          <ContactCard
            imageIcon={<Email />}
            info={'Email Support'}
            data={<a href="mailto:support@witsome.in">support@witsome.in</a>}
          />

          <ContactCard
            imageIcon={<Location />}
            info={'Address'}
            data={'1330, 4th Floor, Sector 57, Gurugram HR-122003'}
          />
          <ContactCard
            imageIcon={<Call />}
            info={'Contact Us'}
            data={'+91 9897510877'}
          />
        </div>
      </div>
      {/* </div> */}
      <div className="flex flex-col overflow-auto max-h-full mb-12">
        <BottomFooter />
      </div>
    </div>
  );
};
