import React from 'react'

interface BillDetailsProps {
  totalPrice: number;
  totalTax: number;
  taxList: { key: string; value: any; }[];
}

export const BillDetailsComp: React.FC<BillDetailsProps> = ({ totalPrice, totalTax, taxList }) => {
  return (
    <div className="bg-white mx-5 mb-4 h-80 px-0 pt-3 items-center rounded-md shadow-lg text-blue-dark">
      <div className="flex flex-col">
        <h4 className="mx-3 font-semibold text-green-mineral text-xs">Bill Details</h4>
        <hr className="bg-silver mx-3 my-3" />
        <table className="w-full">
          <tbody>
            <tr className="flex justify-between">
              <td className="ml-3.5 font-semibold text-xs">Item Total :</td>
              <td className="mx-10 font-semibold text-sm"><span>&#8377;</span>{totalPrice}</td>
            </tr>
            <tr>
              <td><hr className="bg-silver mx-3 my-3" /></td>
            </tr>
            {taxList.map((tax, index) => (
              <tr key={index} className="flex justify-between py-2.5">
                <td className="mx-3.5 flex items-center">
                  <span className="font-normal uppercase text-xs">{tax.key} :</span>
                </td>
                <td className="mx-10 font-semibold text-xs text-gray-800 text-opacity-80">
                  <span>&#8377;</span>{tax.value}
                </td>
              </tr>
            ))}
            <tr>
              <td><hr className="bg-silver mx-3 my-3" /></td>
            </tr>
            <tr className="flex justify-between">
              <td className="mx-3.5 font-semibold text-xs">To Pay :</td>
              <td className="font-semibold text-sm text-grey mx-10"><span>&#8377;</span>{totalTax}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

